<app-header-style-three></app-header-style-three>

<div class="page-title-area" [style.background-image]="'url(' + ImageURL +')'">

    <div>
        <div class="" style="margin-top: 0px;">
            <div class="banner-section">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="banner-content">
                                <span class="sub-title">{{ImageTitle}}</span>
                                <h1>{{ImageCaption}}</h1>
                                <p>{{ImageShortDescription}}.</p>
                                <!-- <div class="btn-box">
                                    <a routerLink="/contact.html" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">Get Started</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <a href="https://www.youtube.com/watch?v=bk7McNUjWgw" target="_blank" class="video-btn"><span><i class='bx bx-play-circle'></i></span> Watch Video</a>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="divider"></div> -->
            </div>
            <!-- <ul >
                <li><a href="index.html">{{ImageTitle}}</a></li>
               
            </ul>
            <ul>
                <li>
                    <a href="index.html">{{ImageCaption}}</a>
                </li>
            </ul>
            <ul>
                <li>
                    <a href="index.html">{{ImageShortDescription}}</a>
                </li>
            </ul> -->
            <!-- <h2>Courses</h2> -->
        </div>
    </div>
</div>

<div class="courses-details-area pt-70 pb-60">
    <div class="container">
        <div class="courses-details-header">
            <div class="row align-items-center">
                <!-- <div class="text-center mb-5">


                    <a class="px-5" (click)="goDown3()" style="font-size: 20px;cursor: pointer;"><b>Course
                            Content</b></a>
                    <a class="px-5" (click)="goDown2()" style="font-size: 20px;cursor: pointer;"><b>Instructor</b></a>
                    <a class="px-5" (click)="goDown5()" style="font-size: 20px;cursor: pointer;"><b>FAQ</b></a>
                    <a class="px-5" (click)="goDown4()" style="font-size: 20px;cursor: pointer;"><b>Reviews</b></a>

                </div> -->
                <div class="col-lg-7">

                    <div class="courses-title" id="about">
                        <h2>{{courseName}}</h2>
                        <p [innerHTML]="description"></p>
                    </div>
                    <div class="courses-meta">
                        <ul>
                            <li>
                                <i class='bx bx-folder-open'></i>
                                <span>Category</span>
                                <a>{{technologyName}}</a>
                            </li>
                            <li>
                                <i class='bx bx-group'></i>
                                <span>Students Enrolled</span>
                                <!-- <a >0</a> -->
                                <a>{{buyCount}}</a>
                            </li>
                            <li>
                                <i class='bx bx-calendar'></i>
                                <span>Last Updated</span>
                                <span *ngIf="updatedDate?updatedDate:0">
                                    <a>{{updatedDate}}</a>
                                </span>

                                <!-- <a >{{createdDate}}</a> -->
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class="courses-price">
                        <div class="courses-review">

                            <div class="review-stars">
                                <ul class="list-inline rating-list" *ngFor="let star of stars"
                                    style="display:inline-block;">
                                    <li *ngFor="let item of ratings" [ngClass]="{'selected': (star <= item)}">
                                        <i class="bx bxs-star"></i>
                                    </li>
                                </ul>
                            </div>

                            <span class="reviews-total d-inline-block">({{NoOfRatings}} reviews)</span>
                            <!-- <button type="submit" *ngIf="!subscribed" class="default-btn"
                                (click)="AddToCart(courseId)"><i class='bx bx-cart icon-arrow before'></i><span
                                    class="label">Add To Cart</span><i class="bx bx-cart icon-arrow after"></i></button>
                            <button type="submit" *ngIf="subscribed" class="default-btn"
                                (click)="GoToCourse(courseId)"><i class='bx bx-cart icon-arrow before'></i><span
                                    class="label">View Course</span><i class="bx bx-cart icon-arrow after"></i></button> -->

                        </div>
                        <div class="price">₹{{price}}</div>
                        <!-- <div class="price"><i class="fas fa-rupee"></i>{{price}}</div> -->
                        <!-- <div class="price"><mat-icon svgIcon="heroicons_outline:currency-rupee"></mat-icon>{{price}}</div> -->
                        <!-- <a routerLink="/cart/{{courseId}}" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Buy Course</span><i class="bx bx-paper-plane icon-arrow after"></i></a> -->
                        <button type="submit" *ngIf="!subscribed" class="default-btn" (click)="BuyNow(courseId)"><i
                                class='bx bx-paper-plane icon-arrow before'></i><span class="label">Buy Course</span><i
                                class="bx bx-paper-plane icon-arrow after"></i></button>

                    </div>

                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <!-- <button class="active">play</button> -->

                <div class="courses-details-image text-center">
                    <!-- <img src="{{ImageURL}}" alt="image"> -->
                    <div class="video" *ngFor="let video of videoSource">
                        <video controls width="730" height="380" controlsList="nodownload" (click)="toggleVideo()"
                            #videoPlayer>
                            <source src="{{video}}" type="video/mp4" />
                            Browser not supported
                        </video>
                    </div>
                </div>
                <div class="courses-details-desc" [style.magin-top]="10" id="targetRed">
                    <h3>What you'll learn</h3>
                    <div class="requirements-list">
                        <!-- <li> -->
                        <!-- <i ng-bind-html="whatLearn"></i> -->
                        <p [innerHTML]="whatLearn" [style.margin-bottom]="0"></p>
                        <!-- <p [innerHTML]="whatLearn"></p> -->
                        <!--<p ng-bind-html="whatLearn"></p> -->
                        <!-- {{whatLearn}} -->
                        <!-- </li> -->
                        <!-- <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Boost your resume with skills
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Gather, organize, data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use data for improved
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Present information KPIs
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Perform quantitative
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Analyze current data
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Discover how to find trends
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Understand the fundamentals
                                </span>
                            </li>
                            <li>
                                <span>
                                    <i class='bx bx-check'></i>
                                    Use SQL to create, design
                                </span>
                            </li> -->
                    </div>
                    <h3 [style.margin-top]="-10">Requirements</h3>
                    <!-- <p  [innerHTML]="requirements"></p> -->
                    <p [innerHTML]="requirements" [style.margin-bottom]="0"></p>
                    <!-- <li [innerHTML]="requirements"></li> -->
                    <!-- <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</li>
                        <li>The standard Lorem Ipsum passage, used since the 1500s.</li> -->
                    <h3 [style.margin-top]="-10">Description</h3>
                    <p [innerHTML]="fullDescription"></p>

                    <!-- <p><strong>Hi! Welcome to Certified Graphic Design with Free Project Course, the only course you need to become a BI Analyst.</strong></p> -->
                    <!-- <p>We are proud to present you this one-of-a-kind opportunity. There are several online courses teaching some of the skills related to the BI Analyst profession. The truth of the matter is that none of them completely prepare you.</p>
                    <p><strong>Our program is different than the rest of the materials available online.</strong></p>
                    <p>It is truly comprehensive. The Business Intelligence Analyst Course comprises of several modules:</p>
                    <ul class="description-features-list">
                        <li>Introduction to Data and Data Science</li>
                        <li>Statistics and Excel</li>
                        <li>Database theory</li>
                        <li>SQL</li>
                        <li>Tableau</li>
                    </ul>
                    <p>These are the precise technical skills recruiters are looking for when hiring BI Analysts. And today, you have the chance of acquiring an invaluable advantage to get ahead of other candidates. This course will be the secret to your success. And your success is our success, so let’s make it happen!</p>
                    <p>Here are some more details of what you get with The Business Intelligence Analyst Course:</p>
                    <ul class="description-features-list">
                        <li><strong>Introduction to Data and Data Science</strong> – Make sense of terms like business intelligence, traditional and big data, traditional statistical methods, machine learning, predictive analytics, supervised learning, unsupervised learning, reinforcement learning, and many more;</li>
                        <li><strong>Statistics and Excel</strong> – Understand statistical testing and build a solid foundation. Modern software packages and programming languages are automating most of these activities, but this part of the course gives you something more valuable – critical thinking abilities;</li>
                        <li><strong>Database theory</strong> – Before you start using SQL, it is highly beneficial to learn about the underlying database theory and acquire an understanding of why databases are created and how they can help us manage data;</li>
                        <li><strong>SQL</strong> – when you can work with SQL, it means you don’t have to rely on others sending you data and executing queries for you. You can do that on your own. This allows you to be independent and dig deeper into the data to obtain the answers to questions that might improve the way your company does its business;</li>
                        <li><strong>Tableau</strong> – one of the most powerful and intuitive data visualization tools available out there. Almost all large companies use such tools to enhance their BI capabilities. Tableau is the #1 best-in-class solution that helps you create powerful charts and dashboards;</li>
                        <li>Learning a programming language is meaningless without putting it to use. That’s why we integrate SQL and Tableau, and perform several real-life Business Intelligence tasks;</li>
                    </ul>
                    <p><strong>Sounds amazing, right?</strong></p>
                    <p>Our courses are unique because our team works hard to:</p>
                    <ul class="description-features-list">
                        <li>Pre-script the entire content</li>
                        <li>Work with real-life examples</li>
                        <li>Provide easy to understand and complete explanation</li>
                        <li>Create beautiful and engaging animations</li>
                        <li>Prepare exercises, course notes, quizzes, and other materials that will enhance your course taking experience</li>
                        <li>Be there for you and provide support whenever necessary</li>
                    </ul>
                    <p>We love teaching and we are really excited about this journey. It will get your foot in the door of an exciting and rising profession. Don’t hesitate and subscribe today. The only regret you will have is that you didn’t find this course sooner!</p>
                    <h3>Who this course is for:</h3>
                    <ul class="audience-list">
                        <li>Beginners to programming and data science</li>
                        <li>Students eager to learn about job opportunities in the field of data science</li>
                        <li>Candidates willing to boost their resume by learning how to combine the knowledge of Statistics, SQL, and Tableau in a real-world working environment</li>
                        <li>SQL Programmers who want to develop business reasoning and apply their knowledge to the solution of various business tasks</li>
                        <li>People interested in a Business Intelligence Analyst career</li>
                    </ul> -->
                    <!-- accordion with custom "clickable zone" -->

                    <div class="col-lg-12 col-md-12">
                        <div class="faq-accordion faq-accordion-style-two">
                            <h3 id="syllabus">Course content</h3>
                            <accordion>
                                <accordion-group *ngFor="let item of OrderedData" heading={{item.moduleName}}>
                                    <accordion-heading>
                                        <p style="padding-left:50px;margin-top: 0px;">
                                        </p>
                                    </accordion-heading>
                                    <p>
                                        {{item.ModuleShortDescription}}
                                    </p>
                                    <!-- <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p> -->
                                    <ul *ngFor="let value of item.model" class="mt-4">{{value.chapter}}.
                                        <p class="ml-4">
                                            {{value.ChapterShortDescription}}</p>
                                    </ul>
                                    <!-- <p *ngFor="let value of item.model">{{value.chapter}}.</p>
                                    <ul>
                                        {{item.ChapterShortDescription}}
                                    </ul> -->

                                    <!-- <p></p> -->
                                </accordion-group>
                                <!-- <accordion-group heading="What are the different types of graduate degrees?">
                                    <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                                </accordion-group> -->

                            </accordion>
                        </div>
                    </div>
                    <ng-template #header>
                        <h1>TextValueToInsert</h1>
                        <p id="text" id="faq">TextValueToInsert2</p>
                    </ng-template>
                    <!-- <div class="accordions">
                        <div class="accordion">
                          <h2>Standard accordion</h2>
                          <ngx-accordion>
                            <ngx-accordion-group>
                              <ngx-accordion-group-element>
                                <span class="plus"> Fruits</span>
                              </ngx-accordion-group-element>
                              <ngx-accordion-group-child> Banana</ngx-accordion-group-child>
                              <ngx-accordion-group-child> Orange</ngx-accordion-group-child>
                            </ngx-accordion-group>
                            <ngx-accordion-group [active]="true">
                              <ngx-accordion-group-element>
                                Vegetables
                              </ngx-accordion-group-element>
                              <ngx-accordion-group-child>Carrot</ngx-accordion-group-child>
                            </ngx-accordion-group>
                            <ngx-accordion-group>
                              <ngx-accordion-group-element>
                                 Cookies
                              </ngx-accordion-group-element>
                              <ngx-accordion-group-child>Butter cookie</ngx-accordion-group-child>
                              <ngx-accordion-group-child>Macaron</ngx-accordion-group-child>
                            </ngx-accordion-group>
                            <ngx-accordion-group>
                              <ngx-accordion-group-element>
                                 Books
                              </ngx-accordion-group-element>
                            </ngx-accordion-group>
                          </ngx-accordion>
                        </div>
                    
                      </div> -->
                    <div class="col-lg-12 col-md-12 mt-4">
                        <div class="faq-accordion faq-accordion-style-two">
                            <h3 id="faq">Frequently Asked Questions</h3>
                            <accordion>
                                <accordion-group *ngFor="let item of finalquestions" heading={{item.question}}>
                                    <!-- <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p> -->
                                    <ul>
                                        <P [innerHTML]='item.answer'></P>
                                    </ul>
                                    <!-- <p *ngFor="let value of item.model">{{value.chapter}}.</p>
                                    <ul>
                                        {{item.ChapterShortDescription}}
                                    </ul> -->

                                    <!-- <p></p> -->
                                </accordion-group>
                                <!-- <accordion-group heading="What are the different types of graduate degrees?">
                                    <p>Associate: a two-year program that either leads to a specific vocation or transitions to a bachelor program. Bachelor: a four or five-year program where students earn credits in a wide variety of courses.</p>
                                </accordion-group> -->

                            </accordion>

                        </div>
                    </div>

                    <h3 id="instructor" class="mt-4">Meet your instructors</h3>
                    <div class="courses-author">
                        <div class="author-profile-header"></div>
                        <div class="author-profile">
                            <div class="author-profile-title">
                                <img src={{InstructorImage}} class="shadow-sm rounded-circle" alt="image">
                                <div class="author-profile-title-details d-flex justify-content-between">
                                    <div class="author-profile-details">
                                        <h4>{{InstrcuctorName}}</h4>
                                        <span class="d-block">{{InstrcuctorShortDesc}}</span>
                                    </div>

                                    <div class="author-profile-raque-profile">
                                        <a routerLink="/single-instructor/{{InstructorId}}" class="d-inline-block">View
                                            Profile</a>
                                    </div>
                                </div>
                            </div>
                            <p>{{InstrcuctorLongDesc}}</p>
                        </div>
                    </div>

                    <div class="courses-review-comments" id="reviews">
                        <h3 *ngIf="reviewsratiing.length==0">0 Reviews</h3>
                        <div class="user-review" *ngFor="let item of reviewsratiing">
                            <img src="assets/img/user1.jpg" alt="image">
                            <div class="review-rating">
                                <div class="review-stars">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars"
                                        style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.ratings)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <span class="d-inline-block">{{item.userName}}</span>
                            </div>
                            <span class="d-block sub-comment">{{item.ratingPerformance}}</span>
                            <p>{{item.comments}}</p>
                        </div>

                    </div>
                </div>
                <!-- <div class="related-courses">
                    <h3>Related Courses</h3>
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses1.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Business</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user1.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Steven Smith</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Raque Professional IT Expert Certificate Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                        </div>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 10 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 6 lessons
                                        </li>
                                        <li class="courses-price">
                                            Free
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="single-courses-box mb-30">
                                <div class="courses-image">
                                    <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                                    <div class="courses-tag">
                                        <a routerLink="/courses-2-columns-style-1" class="d-block">Design</a>
                                    </div>
                                </div>
                                <div class="courses-content">
                                    <div class="course-author d-flex align-items-center">
                                        <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                                        <span>Sarah Taylor</span>
                                    </div>
                                    <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                                    <div class="courses-rating">
                                        <div class="review-stars-rated">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star-half'></i>
                                        </div>
                                        <div class="rating-total">
                                            4.5 (2 rating)
                                        </div>
                                    </div>
                                </div>
                                <div class="courses-box-footer">
                                    <ul>
                                        <li class="students-number">
                                            <i class='bx bx-user'></i> 15 students
                                        </li>
                                        <li class="courses-lesson">
                                            <i class='bx bx-book-open'></i> 10 lessons
                                        </li>
                                        <li class="courses-price">
                                            $250
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="col-lg-4">
                <div>
                    <h3>Certifications</h3>
                    <p [innerHTML]="certifications" [style.margin-bottom]="0"></p>

                </div>
                <div class="related-courses" *ngIf="hiderelatedcourse">
                    <h3>Related Courses</h3>
                    <div class="single-courses-box mb-30" *ngFor="let item of relatedcourses"
                        (click)="GoToCourses(item.courseId,item.courseHeader,item.courseName)">
                        <div class="courses-image">
                            <a class="d-block"><img src={{item.iconUrl}} alt="image"></a>
                            <div class="courses-tag">
                                <a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}"
                                    class="d-block">{{item.technologyName}}</a>
                            </div>
                        </div>

                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src={{InstructorImage}} class="rounded-circle mr-2" alt="image">
                                <!-- <span>{{InstrcuctorName}}</span> -->
                                <span>{{item.facultyName}}</span>
                            </div>
                            <h3><a (click)="GoToCourses(item.courseId,item.courseHeader,item.courseName)"
                                    style="cursor:default;" class="d-inline-block">{{item.courseName}}</a></h3>
                            <div class="courses-rating">

                                <div class="review-stars-rated">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars"
                                        style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.average)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-total">
                                    {{item.average}} ({{item.noOfRatings}} rating)
                                </div>

                            </div>
                        </div>
                        <div class="courses-box-footer">
                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i> {{item.buyCount}} students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> {{item.count}} lessons
                                </li>
                                <li class="courses-price">
                                    ₹{{item.price}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4">
                <div class="courses-sidebar-information">
                    <ul>
                        <li>
                            <span><i class='bx bx-group'></i> Students:</span>
                            10
                        </li>
                        <li>
                            <span><i class='bx bx-time'></i> Length:</span>
                            1 Weeks
                        </li>
                        <li>
                            <span><i class='bx bx-tachometer'></i> Effort:</span>
                            2–5 hours per week
                        </li>
                        <li>
                            <span><i class='bx bxs-institution'></i> Institution:</span>
                            <a href="#" target="_blank" class="d-inline-block">ABC</a>
                        </li>
                        <li>
                            <span><i class='bx bxs-graduation'></i> Subject:</span>
                            Design
                        </li>
                        <li>
                            <span><i class='bx bx-atom'></i> Quizzes:</span>
                            Yes
                        </li>
                        <li>
                            <span><i class='bx bxs-badge-check'></i> Level:</span>
                            Introductory
                        </li>
                        <li>
                            <span><i class='bx bx-support'></i> Language:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-text'></i> Video Subtitle:</span>
                            English
                        </li>
                        <li>
                            <span><i class='bx bx-certification'></i> Certificate:</span>
                            Yes
                        </li>
                    </ul>
                </div>
             
                <div class="courses-purchase-info">
                    <h4>Interested in this course for your Business or Team?</h4>
                    <p>Train your employees in the most in-demand topics, with edX for Business.</p>
                    <a routerLink="/single-courses" class="d-inline-block">Purchase now</a>
                    <a routerLink="/single-courses" class="d-inline-block">Request Information</a>
                </div>
            </div> -->
        </div>
    </div>
</div>