<header class="header-area">
    <div class="top-header-style-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="top-header-logo">
                        <a routerLink="/" class="d-inline-block"><img [src]="companylogo" alt="logo"></a>
                    </div>
                </div>

                <div class="col-lg-8">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <span>Contact support</span>
                            <a href="javascript::">{{phoneNo}}</a>
                        </li>
                        <li>
                            <i class='bx bx-map'></i>
                            <span>Our location</span>
                            <a href="#">New York, USA</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <span>Contact email</span>
                            <a href="javascript::">{{email}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="navbar-area navbar-style-two" [ngClass]="{'sticky': isSticky}">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
                <a class="navbar-brand" (click)="GoToCourses(companyName)"><img [src]="companylogo" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses <i
                                    class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let item of finaltabledata"><a href="javascript:void(0)"
                                        class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">{{item.technologyName}} <i
                                            class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">

                                        <li *ngFor="let value of item.model">
                                            <a style="cursor: pointer;"
                                                (click)="OnClick(value.courseId)">{{value.courseName}}</a>
                                        </li>

                                    </ul>
                                </li>



                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="showPlans"><a href="javascript:void(0)" class="nav-link"> Course
                                Plans <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let item of courseplans">
                                    <a style="cursor: pointer;" (click)="GoToPage(item.planId)">{{item.planName}}</a>
                                </li>

                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Contact</a></li>

                    </ul>
                </div>
                <div class="others-option">
                    <div *ngIf="loginDisplay" class="dropdown language-switcher d-inline-block pr-4"
                        [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/default/profile.jpg" class="shadow" alt="image">
                        </button>
                        <div class="dropdown-menu">
                            <a routerLink="/orders" class="dropdown-item  align-items-center">
                                <span>My Purchases</span>
                            </a>
                            <a routerLink="/mycourses" class="dropdown-item d-flex align-items-center">
                                <span>My Courses</span>
                            </a>
                            <a href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ChangePassword&client_id=38587c69-e970-487f-bf43-f32c71fd7706&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fugetitmain.fadelsoft.com%2F&scope=openid&response_type=id_token"
                                class="dropdown-item  align-items-center">
                                <span>Change Password</span>
                            </a>
                            <a class="dropdown-item  align-items-center" style="cursor: pointer;" (click)="logout()">
                                <span>Sign out</span>
                            </a>
                        </div>
                    </div>
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>{{cartlength}}</span>
                    </a>
                    <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                    <div class="d-inline-block ">
                        <div class="top-header-btn">
                            <a *ngIf="!loginDisplay" (click)="login()" class="default-btn"
                                style="cursor: pointer;background-color:#cccccc !important;border: none;"><i
                                    class='bx bx-log-in icon-arrow before'></i><span class="label">Log In</span><i
                                    class="bx bx-log-in icon-arrow after"></i></a>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div> -->
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>
            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- <app-homethree-main-banner></app-homethree-main-banner> -->




<div class="feedback-form-inner" style="margin-top: 135px !important;">
    <div class="row">
        <div class="col-lg-5 col-md-12">
            <div class="feedback-form-image">
                <img src="assets/img/note-pad.png" alt="image">
            </div>
        </div>
        <div class="col-lg-7 col-md-12">
            <div class="feedback-form">
                <h3>Here is Partner Register Form</h3>
                <form [formGroup]="FacultyForm">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" class="form-control" title="First name is required" appAutofocus
                                    [formControlName]="'firstName'" placeholder="First name*"
                                    [ngClass]="{'is-invalid': instructorsubmitted && r.firstName.errors }">
                                <div *ngIf="instructorsubmitted && r.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="r.firstName.errors.required" class="invalid-tooltip">First name is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" class="form-control" appAutofocus [formControlName]="'lastName'"
                                    placeholder="Last name*" title="Last name is required"
                                    [ngClass]="{'is-invalid': instructorsubmitted && r.lastName.errors }">
                                <div *ngIf="instructorsubmitted && r.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="r.lastName.errors.required" class="invalid-tooltip">Last name is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="text" class="form-control" placeholder="Mobile Number*"
                                    [formControlName]="'mobilenumber'" title="Mobile number is required"
                                    [ngClass]="{'is-invalid': instructorsubmitted && r.mobilenumber.errors }">
                                <div *ngIf="instructorsubmitted && r.mobilenumber.errors" class="invalid-feedback">
                                    <div *ngIf="r.mobilenumber.errors.required" class="invalid-tooltip">Mobile Number is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <input type="email" class="form-control" placeholder="Your email*"
                                    [formControlName]="'email'" title="Email is required"
                                    [ngClass]="{'is-invalid': instructorsubmitted && r.email.errors }">
                                <div *ngIf="instructorsubmitted && r.email.errors" class="invalid-feedback">
                                    <div *ngIf="r.email.errors.required" class="invalid-tooltip">Email is
                                        required
                                    </div>
                                </div>
                            </div>
                        </div>




                        <div class="col-lg-12" style="margin-top: 25px !important;">
                            <button (click)="AddPartner()" class="default-btn"><i
                                    class='bx bx-send icon-arrow before'></i><span class="label">Submit</span><i
                                    class="bx bx-send icon-arrow after"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>