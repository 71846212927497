<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg4">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Shop</li>
            </ul>
            <h2>Cart/Buy</h2>
        </div>
    </div>
</div>
<div *ngIf="hiderelatedcourse">
<div class="cart-area ptb-100" >
    <div class="container">
        <form>
            <div class="cart-table table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Product</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Course Price</th>
                            <th scope="col">Tax</th>
                            <th scope="col">Total</th>
                            <th scope="col">Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of carttable">
                            <td class="product-thumbnail">
                                <!-- <a (click)="GoToPage(item.courseId,item.isPlan)"  routerLink="/single-products/{{item.courseId}}/Course"> -->
                              
                                <!-- <span *ngIf="item.coursename!='Cloud12'"> -->
                                    <a class="d-block" (click)="GoToPage(item.courseId,item.IsPlan)" ><img src={{item.iconUrl}} alt="image "></a>

                                <!-- </span> -->
                          
                                <!-- <span *ngIf="item.coursename=='Cloud12' "> -->
                             <!-- <a  class="d-block"><img src="https://ugetit.blob.core.windows.net/courseimages/1997d693-1f4d-404b-a3fc-19a5fade0397-Image-2.jpg" ></a> -->
                            <!-- </span> -->
                                   <!-- <a class="d-block" (click)="GoToPage(item.courseId,item.IsPlan)" ><img src={{item.imageURL}} alt="image "></a> -->
                                </td>
                            <td class="product-name">
                                <a (click)="GoToPage(item.courseId,item.IsPlan)">{{item.coursename}}</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">₹{{item.price}}</span>
                            </td>
                            <!-- <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" min="1" value="1">
                                </div>
                            </td> -->
                            <td class="product-Tax">
                                <span class="Tax-amount">₹{{item.Tax}}</span>
                                <!-- <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a> -->
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">₹{{item.TotalPrice}}</span>
                                <!-- <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a> -->
                            </td>
                            <td class="product-subtotal">
                                <span>
                                    <a (click)="Delete(item.cartId)" class="remove"><i class='bx bx-trash'></i></a>
                                </span>
                                
                            </td>
                        </tr>
                        <!-- <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop2.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Book Chicks</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$541.50</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" min="1" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$541.50</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr> -->
                        <!-- <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop3.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Book Divas</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$140.50</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" min="1" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$140.50</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr>
                        <tr>
                            <td class="product-thumbnail">
                                <a routerLink="/single-products">
                                    <img src="assets/img/shop/shop4.jpg" alt="item">
                                </a>
                            </td>
                            <td class="product-name">
                                <a routerLink="/single-products">Book Smart</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">$547.00</span>
                            </td>
                            <td class="product-quantity">
                                <div class="input-counter">
                                    <input type="number" min="1" value="1">
                                </div>
                            </td>
                            <td class="product-subtotal">
                                <span class="subtotal-amount">$547.00</span>
                                <a routerLink="/cart" class="remove"><i class='bx bx-trash'></i></a>
                            </td>
                        </tr> -->
                    </tbody>
                </table>
            </div>
            <div class="cart-buttons">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-sm-7 col-md-7">
                        <div class="shopping-coupon-code">
                            <input type="text" class="form-control" placeholder="Coupon code" name="coupon-code" id="coupon-code">
                            <button type="submit">Apply Coupon</button>
                        </div>
                    </div>
                    <div class="col-lg-5 col-sm-5 col-md-5 text-end">
                        <a routerLink="/cart" class="default-btn"><i class='bx bx-cart icon-arrow before'></i><span class="label">Update Cart</span><i class="bx bx-cart icon-arrow after"></i></a>
                    </div>
                </div>
            </div>
            <div class="cart-totals">
                <h3>Cart Totals</h3>
                <ul>
                    <li>Subtotal <span>₹{{TotalPrice}}  </span></li>
                    <li>Tax <span>₹{{Tax}}  </span></li>
                    <!-- <li>Shipping <span>₹30.00</span></li> -->
                    <li>Total <span>{{Total}}</span></li>
                </ul>
                <a (click)="GoToCheckOutPage()" style="cursor:pointer" class="default-btn"><i class='bx bx-shopping-bag icon-arrow before'></i><span class="label">Proceed to Checkout</span><i class="bx bx-shopping-bag icon-arrow after"></i></a>
            </div>
        </form>
    </div>
</div>
</div>
<div *ngIf="!hiderelatedcourse" class="container cart-area ptb-100"  style="text-align: center;">

    <h1 style="font-weight: 600;">Your Cart Is Empty</h1>
    <div>    <a class="btn btn-outline-success" href="/#/mycourses">Go To MyCourses</a></div>


</div>


