import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss']
})
export class ReceiptComponent implements OnInit {
  InvoiceId:any
  date:any
  address: any;
  companyName:any;
  
  receiptdata:any
  email: any;

  constructor(private approute: ActivatedRoute,
    private _authService: AuthService,
    private datepipe: DatePipe,
    private _router: Router,) { }

  ngOnInit(): void {
    var Id=localStorage.getItem('AzureUserId');
    var invoiceId=this.approute.snapshot.params['Invoiceno']
    var val=atob(invoiceId)
    this.GetDetails(Id,val);
    this.GetCompanydetails();
  }

  GetDetails(id:any,invoiceno:any){
  
    this._authService.GetReceiptData(id,invoiceno).subscribe((finalresult: any) => {
      debugger
      //  this.values=[]
      this.receiptdata=finalresult.result;
      this.InvoiceId=this.receiptdata[0].invoiceNo
      this.date=this.receiptdata[0].invoiceDate

      });
   
  }

  GetCompanydetails()
  {
      debugger
      this._authService.GetCompanydetails().subscribe((result: any) => {
        debugger
           var result = JSON.parse(result);
            if (result.status == "200") {
                
                console.log('details',result.result)
                this.address= result.result[0].address;
                this.companyName=result.result[0].companyName;
               // this.companylogo=result.result[0].companylogo;
               this.email= result.result[0].email;
                
                setTimeout(() => {
                }, 1000);
            }
            else {
            }
            (error) => {

   
           }
        });
  }

}
