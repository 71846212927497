<div class="home-slides">
    <owl-carousel-o [options]="homeSlides">
        <ng-template carouselSlide>
            <div class="main-banner item-bg1">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title">Weapon is Education</span>
                                <h1> Learning based on business case scenarios</h1>
                                <p>ugetit supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                <div class="btn-box">
                                    <a routerLink="/viewAllcourses" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <!-- <a routerLink="/contact" class="optional-btn">Get Started Free</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg2">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content">
                                <span class="sub-title">The Future</span>
                                <h1>Learning based on understanding, practice, and implementation</h1>
                                <p>ugetit supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                <div class="btn-box">
                                    <a routerLink="/viewAllcourses" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <!-- <a routerLink="/contact" class="optional-btn">Get Started Free</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="main-banner item-bg3">
                <div class="d-table">
                    <div class="d-table-cell">
                        <div class="container">
                            <div class="main-banner-content text-center">
                                <span class="sub-title">Aim for Excellence</span>
                                <h1>Improve your skills and stand out on the team.</h1>
                                <p>ugetit supports students by introducing collaborators outside R, internships and research experience abroad.</p>
                                <div class="btn-box">
                                    <a routerLink="/viewAllcourses" class="default-btn"><i class='bx bx-move-horizontal icon-arrow before'></i><span class="label">View Courses</span><i class="bx bx-move-horizontal icon-arrow after"></i></a>
                                    <!-- <a routerLink="/contact" class="optional-btn">Get Started Free</a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>