import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { HeaderStyleTwoComponent } from '../../common/header-style-two/header-style-two.component';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-courses-grid-style-one-columns-two-page',
  templateUrl: './courses-grid-style-one-columns-two-page.component.html',
  styleUrls: ['./courses-grid-style-one-columns-two-page.component.scss']
})
export class CoursesGridStyleOneColumnsTwoPageComponent implements OnInit {
  courses: any;
  ellips: any;
  ipaddress: string = '';
  latitude: string = '';
  longitude: string = '';
  currency: string = '';
  currencysymbol: string = '';
  isp: string = '';
  city: string = '';
  country: string = '';
  province: string = '';
  rup: boolean;
  Dol: boolean;
  PlanId: number;
  stars: number[] = [1, 2, 3, 4, 5];
  constructor(private activatedRoute: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,private router: Router,private title:Title

  ) { }

  ngOnInit(): void {
    debugger
    this.PlanId = parseInt(this.activatedRoute.snapshot.params['planid']);

    this.GetCoursesPlanId(this.PlanId);

    this._authService.getIpAddress().subscribe((res) => {
      this.ipaddress = res['ip'];
     this._authService.getGEOLocation(this.ipaddress).subscribe((res) => {
       console.log(res);
       this.latitude = res['latitude'];
       this.longitude = res['longitude'];
       this.city = res['city'];
       this.country = res['country_code2'];
       if( this.country='IN'){

         this.rup=true;
        }
        if( this.country !='IN'){
        
         this.Dol=true;
        }
       this.isp = res['isp'];
       this.province = res['state_prov'];
     });
   })
    this.ellips=true;
  }

  @HostListener('mouseover') clickme(){
    this.ellips=false;
  
    }
   @HostListener('mouseout') clickme1(){
    this.ellips=true;
  
    }
  GoToCourse(courseid:any,courseheader:any,coursename:any){
    this.title.setTitle(coursename)

    this.router.navigate(['/course/'+courseheader+'/'+courseid]);

 }

  GetCoursesPlanId(id: any) {
    this._authService.GetCoursesPlanId(id).subscribe((finalresult: any) => {
      debugger
      // this.courses = finalresult.result
      if (finalresult.status == "200") {
        // debugger
        
        this.courses = finalresult.result;
        for (let i = 0; i < this.courses.length; i++) {
          // this.courses[i].price = this.courses[i].price ;
          // this.offerprice = finalresult.result.offerPrice
          if (this.courses[i].offerPrice != 0) {
            this.courses[i].price = this.courses[i].offerPrice;
          }
          else{
            this.courses[i].price = this.courses[i].price;

          }
          if (this.courses[i].iconUrl != "null") {
            this.courses[i].iconUrl = finalresult.result[i].iconUrl;            
            // this.noimage=true;;
          }
          else {
            this.courses[i].iconUrl = "assets/img/courses/courses8.jpg";          

          }
         
          if (this.courses[i].img != "null") {
           
            this.courses[i].img = finalresult.result[i].img;            
            // this.noimage=true;;
          }
          else {
            this.courses[i].img = "assets/img/instructor/instructor6.jpg"; 


          }
        }
        // if (finalresult.result.imageURL != null) {
        //   this.ImageURL = baseurl + finalresult.result.imageURL;
        //   // this.noimage=true;;

        // }
        // else {
        //   // this.ImageURL = baseurl + "/courseFiles/dummy identityproof.png";

        // }
        setTimeout(() => {
        }, 1000);
      }
      else {
      }
      (error) => {

      }
     
    })
  }

  BuyNow(id: any) {
    debugger
    localStorage.setItem('cartplanid', id);
    var val = localStorage.getItem('AzureUserId')
    if (val != 'null') {
      var data = {
        UserId: val,
        ProductId: parseInt(id),
        CreatedBy: 1,
        Type: 'CoursePlan'

      }
      this._authService.AddCartItem(data).subscribe((finalresult: any) => {
        debugger
        var finalresult = JSON.parse(finalresult)
        if (finalresult.status == "200") {
          this._router.navigate(['/checkout/' + id + '/ProductItem/Plan']);
        }
        else if (finalresult.status == "104") {
          this._router.navigate(['/checkout/' + id + '/ProductItem/Plan']);
        }
        else {

        }
      })
    }
    else if (val == 'null') {

      let myCompOneObj = new HeaderStyleTwoComponent(
        this.activatedRoute, this._authService, this._router,this.title, this.msalGuardConfig, this.authService, this.msalBroadcastService
      );

      myCompOneObj.login();
    }

  }


  AddToCart(id: any) {
    debugger
    // localStorage.setItem('cartplanid',id);
    var val = localStorage.getItem('AzureUserId')
    if (val != 'null') {
      var data = {
        UserId: localStorage.getItem('AzureUserId'),
        ProductId: parseInt(id),
        CreatedBy: 1,
        Type: 'CoursePlan'
      }
      this._authService.AddCartItem(data).subscribe((finalresult: any) => {
        debugger
        var finalresult = JSON.parse(finalresult)
        if (finalresult.status == "200") {
          window.location.reload();
          // this._router.navigate(['/cart']);
        }
        else if (finalresult.status == "104") {
          // this._router.navigate(['/cart']);
        }
        else {

        }
      })
    }
    else if (val == 'null') {

      let myCompOneObj = new HeaderStyleTwoComponent(
        this.activatedRoute, this._authService, this._router,this.title, this.msalGuardConfig, this.authService, this.msalBroadcastService
      );

      myCompOneObj.login();
    }
  }

}
