<footer>
    <!-- <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Contact Us</h3>
                    <ul class="contact-us-link">
                        <li>
                            <i class='bx bx-map'></i>
                            <a href="#" target="_blank">{{address}}</a>
                        </li>
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <a href="tel:+11234567890">{{phoneNo}}</a>
                        </li>
                        <li>
                            <i class='bx bx-envelope'></i>
                            <a href="mailto:hello@ugetit.com">{{email}}</a>
                        </li>
                    </ul>
                    <ul class="social-link">
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href={{linkedLink}} class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Support</h3>
                    <ul class="support-link">
                        <li><a routerLink="/">Privacy</a></li>
                        <li><a routerLink="/">FAQ's</a></li>
                        <li><a routerLink="/">Support</a></li>
                        <li><a routerLink="/">Terms</a></li>
                        <li><a routerLink="/">Condition</a></li>
                        <li><a routerLink="/">Policy</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Useful Link</h3>
                    <ul class="useful-link">
                        <li><a routerLink="/">Web Design</a></li>
                        <li><a routerLink="/">UI/UX Design</a></li>
                        <li><a routerLink="/">WP Development</a></li>
                        <li><a routerLink="/">App</a></li>
                        <li><a routerLink="/">Whitepaper</a></li>
                        <li><a routerLink="/">Web Development</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget mb-30">
                    <h3>Newsletter</h3>
                    <div class="newsletter-box">
                        <p>To get the latest news and latest updates from us.</p>
                        <form class="newsletter-form">
                            <label>Your e-mail address:</label>
                            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                            <button type="submit">Subscribe</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="footer-bottom-area">
        <div class="container">
            <div *ngFor="let item of policies" style="display:inline-block;">
                <p>
                    <!-- <span style="cursor: pointer;"><a routerLink="/faqs/Terms of Use">Terms of Use</a></span> -->
                    <span style="margin-left: 50px;cursor: pointer;" (click)="create(item.tabName)">{{item.tabName}}</span>
                    <!-- <span style="cursor: pointer;" (click)="create()"><a routerLink="/faqs/Terms of Use/">{{item.tabName}}  &nbsp; &nbsp; </a></span> -->
                    <!-- 
                    <span style="margin-left: 50px;cursor: pointer;"><a routerLink="/faqs/Privacy Policy">Privacy Policy</a></span>
                    <span style="margin-left: 50px;cursor: pointer;"><a routerLink="/faqs/Refund Policy">cancellation and refund policy</a></span>
                    <span style="margin-left: 50px;cursor: pointer;"><a routerLink="/faqs/Reschedule Policy">Reschedule Policy</a></span> -->
                </p>

            </div>
            <!-- <div class="logo">
                <a routerLink="/" class="d-inline-block"><img src="assets/img/black-logo.png" alt="image"></a>
            </div> -->
            <p>Address :{{address}}</p>
            <p><i class='bx bx-copyright'></i>{{year}} <a routerLink="/" target="_blank">{{companyName}}</a>
                <a target="_blank"></a> {{description}}</p>
        </div>
    </div>
    <div style="display: none;">

    <p>{{ ipaddress }}</p>

<h4>IP Address Info</h4>
<p>latitude : {{ latitude }}</p>
<p>longitude : {{ longitude }}</p>
<p>city : {{ city }}</p>
<p>country : {{ country }}</p>
<p>currencysymbol :{{currencysymbol}}</p>
<p>province : {{ province }}</p>

</div>
</footer>

<ngx-scrolltop></ngx-scrolltop>