import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { ClientConfigurationErrorMessage, InteractionStatus } from '@azure/msal-browser';
import { ClaimsRequestKeys } from '@azure/msal-common/dist/utils/Constants';
import { AuthService } from '../../auth.service';
import { filter, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {


  ipaddress: string = '';
  latitude: string = '';
  longitude: string = '';
  currency: string = '';
  currencysymbol: string = '';
  isp: string = '';
  city: string = '';
  country: string = '';
  province: string = '';


  year: any;
  copyRight: any;
  description: any;
  address: any;
  companyName:any;
    phoneNo: any;
  email: any;
  linkedLink: any;
  twitterLink: any;
  facebookLink: any;
  whatsappLink: any;
  pinterestLink: any;
  tabName: any;
  policies: any;
  companylogo: any;
  
  dataSource: any =[];
    displayedColumns: string[] = ['claim', 'value'];
  alert: { type: string; message: any; };
  showAlert: boolean;


  constructor( private _authService:AuthService,private msalBroadcastService: MsalBroadcastService,
    private router:Router, private authService: MsalService,
    ) { }

  ngOnInit(): void {
    this.GetCopyRights();
    this.GetCompanydetails();
    this.GetTermdetails();
   
    this.msalBroadcastService.inProgress$
    .pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    )
    .subscribe(() => {
      // debugger
      
      this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims)
    });
    
 


    this._authService.getIpAddress().subscribe((res) => {
      this.ipaddress = res['ip'];
      this._authService.getGEOLocation(this.ipaddress).subscribe((res) => {
        console.log(res);
        this.latitude = res['latitude'];
        this.longitude = res['longitude'];
        this.city = res['city'];
        this.country = res['country_code2'];
       
        this.isp = res['isp'];
        this.province = res['state_prov'];
      });
      //console.log(res);
    });


//     var cc = require('currency-codes');
// console.log(cc.codes());
 

// [
//     'INR',
//     'AFN',
//     ...
//     'ZAR',
//     'ZMW'
// ]
  }
  

  getClaims(claims: any) {
     debugger
    var  data ={

      AzureId:(localStorage.AzureUserId),
      Email:claims.emails[0],
      FirstName:claims.given_name,
      LastName:claims.family_name,
      MobileNumber:claims.name,
  
    }
  
    this._authService.contactsave(data).subscribe((result: any) => {
       //debugger
        var result = JSON.parse(result);
        if (result.status == "200") {
            //debugger
             // Set the alert
             this.alert = {
              type   : 'success',
              message: result.message
          };
          // Show the alert
          this.showAlert = true;

        
            // setTimeout(() => {
              // this._router.navigate(['/masters/companydetails']);
            // }, 2000); 
            
        }
        else {
         // Set the alert
         this.alert = {
          type   : 'error',
          message: result.message
      };
      // Show the alert
      this.showAlert = true;
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
        }
        (error) => {

       }
    });
    

  }
  
  GetCopyRights()
  {
      this._authService.GetCopyRights().subscribe((result: any) => {
           var result = JSON.parse(result);
            if (result.status == "200") {
                console.log(result.result)
                this.year= result.result[0].year;
                this.copyRight= result.result[0].copyRight;
                this.description= result.result[0].description;
                
                setTimeout(() => {
                }, 1000);
            }
            else {
            }
            (error) => {
   
           }
        });
  }
  GetCompanydetails()
  {
      // debugger
      this._authService.GetCompanydetails().subscribe((result: any) => {
        // debugger
           var result = JSON.parse(result);
            if (result.status == "200") {
                
                console.log('details',result.result)
                this.address= result.result[0].address;
                this.companyName=result.result[0].companyName;
                this.companylogo=result.result[0].companylogo;
                this.phoneNo= result.result[0].phoneNo;
                this.email= result.result[0].email;
                this.linkedLink= result.result[0].linkedLink;
                this.twitterLink= result.result[0].twitterLink;
                this.facebookLink= result.result[0].facebookLink;
                this.whatsappLink= result.result[0].whatsappLink;
                this.pinterestLink= result.result[0].pinterestLink;
                
                setTimeout(() => {
                }, 1000);
            }
            else {
            }
            (error) => {

   
           }
        });
  }


  GetTermdetails()
  {
      // debugger
      this._authService.GetTermsPolicies().subscribe((result: any) => {
           var result = JSON.parse(result);
            if (result.status == "200") {
              // debugger
                this.policies=result.result;
                // this.tabName= result.result[0].tabName;
                //  this.description= result.result[0].description;
               
                
                setTimeout(() => {
                }, 1000);
            }
            else {
            }
            (error) => {
   
           }
        });
  }

  create(data) {
    // debugger
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }
    this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/faqs/'+data]);
    
     }
    // this._router.navigate(['/faqs/GetTermsPolicies/description'])
  
}
