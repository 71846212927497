import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-blog-right-sidebar-page',
  templateUrl: './blog-right-sidebar-page.component.html',
  styleUrls: ['./blog-right-sidebar-page.component.scss']
})
export class BlogRightSidebarPageComponent implements OnInit {
  data: any;
  courseids: any;
  stars: number[] = [1, 2, 3, 4, 5];
  ratings: any;
  values: any[];
  hidemycourse: boolean;

  constructor(private approute: ActivatedRoute,
    private _authService: AuthService,
    private datepipe: DatePipe,
    private _router: Router,
    private router: Router) { }

  ngOnInit(): void {
     var id=localStorage.getItem('AzureUserId');
   //var id=1;
    this.GetDetails(id);
  }
  GoToCourse(courseid:any,courseheader){
    debugger

    this.router.navigate(['/course/'+courseheader+'/'+courseid]);

 }
  GetDetails(id:any){
  
    this._authService.GetSubscribedCoursesByCustomerId(id).subscribe((finalresult: any) => {
      debugger
      //  this.values=[]
      this.data=finalresult.result;

       this.values = Object.values(
        this.data.reduce((a, b) => {
          if (!a[b.courseId]) a[b.courseId] = b 
            return a
         }, {})
      )
      if(finalresult.result.length>0){
        this.hidemycourse=true
      }
      else{
        this.hidemycourse=false
      }
      });
      debugger
      this._authService.GetAllRatingsAndComments().subscribe((finalresult: any) => {
         debugger
        this.ratings=finalresult.result
      })
  
  }
  GoToPage(id:any){
    debugger
    var baseurl=this._authService.baseUrl
    // localStorage.setItem('courseidfornavigate',id);
    var userid=localStorage.getItem('AzureUserId')
    var username=localStorage.getItem('UserName')
    
    window.location.href=environment.courdsboardurl+id+'/'+userid+'/'+username
   

    // if(baseurl=="https://localhost:44328/"){ 
    //   window.location.href='http://localhost:4300/#/app/'+id+'/'+userid+'/'+username
    //   }
    // else if(baseurl=="http://testugetitapi.fadelsoft.com/"){
    //   window.location.href='http://ugetitstudent.fadelsoft.com/#/app/'+id+'/'+userid+'/'+username
    // }
    // else if(baseurl=="https://testugetitapi.fadelsoft.com/"){
    //   window.location.href='https://ugetitstudent.fadelsoft.com/#/app/'+id+'/'+userid+'/'+username
    // }
    // else if(baseurl=="https://ugetithome.mbascloud.com/"){
    //   window.location.href='https://ugetitdashboard.mbascloud.com/#/app/'+id+'/'+userid+'/'+username 
       
    // //window.location.href='https://ugetitstudent.mbascloud.com/UgetItCourse/#/app/'+id+'/'+userid+'/'+username
    // }
    
    // this._router.navigateByUrl(this._authService.baseUrl+'app/vien/start',);
  }
  OnClick(id:any){
    debugger
    this._router.navigate(['/course/:dynamic/'+id]);
}

}
