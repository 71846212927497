<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg2">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <!-- <li>FAQ's</li> -->
            </ul>
            <!-- <h2>Frequently Asked Question</h2> -->
        </div>
    </div>
</div>

<div class="faq-area pt-100 pb-70">
    <div class="container">
        <div class="faq-accordion-tab">
            <ngx-tabset #tabSet>
                <ngx-tab *ngFor="let item of policies; let i=index" tabTitle={{item.tabName}} [active]="currentTabId === i">

                    <!-- <h1>Privacy policy</h1> -->
                    <!-- <h1>{{item.description}}</h1> -->
                    <p [innerHTML]="item.description"></p>
                    <!-- <ul class="desp_faqs_cont eu-privacy-policy-text">
                        <li>
                            <h2>Purpose</h2>
                            <p><b>Acutelearn</b> (“Acutelearn,” “we,” or “us”) is committed to protecting your
                                privacy, we take data protection and privacy very seriously. This Privacy Policy
                                (“<b>Policy</b>”) describes how Acutelearn collects, uses, shares and secures the
                                personal information you provide when you visit the Websites and Mobile Apps owned and
                                operated by Acutelearn (listed below), and when you use our Service(s). It also
                                describes your choices regarding use, access, withdrawal of consent (as per the
                                terms hereof) and correction of your personal information. The use of information
                                collected through our Service(s) shall be limited to the purpose of providing the
                                service for which you have engaged Acutelearn. The capitalized terms used in this
                                Policy but not defined herein shall have the same meaning as defined in our Terms of
                                Service at (“<a href="" ng-class="{'selected_category':(tnc.isCurrentCategory(1))}" ng-click="tnc.setCurrentCategory(1, '');scrollTo('terms-and-conditions-active')">Terms</a>”).
                            </p>
                            <p>For the purpose of this Privacy Policy, sensitive personal data or information of a
                                person is as defined under applicable privacy laws. Please note that usage of the
                                terms personal information/ personally identifiable information/ personal data, in this
                                Privacy Policy includes sensitive personal data or information, wherever appropriate
                                and/or mandated under applicable privacy laws.</p>

                            <h2>Acutelearn Website and Mobile Apps</h2>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>Website:
                                    <ul>
                                        <!-- <li><span class="flyout_disc"></span><a href="https://www.Acutelearn.com/" target="_self">Acutelearn.com</a></li>
                                        <li><span class="flyout_disc"></span><a href="https://ugetitmain.fadelsoft.com/" target="_blank">ugetitmain.fadelsoft.com</a></li>
                                    </ul>
                                </li>
                                <li><span class="flyout_disc"></span>Mobile Apps
                                    <ul>
                                        <li><span class="flyout_disc"></span>iOS :<a href="https://itunes.apple.com/in/app/Acutelearn/id963042747?mt=8" target="_blank">
                                                https://itunes.apple.com/in/app/Acutelearn/id963042747?mt=8 </a></li>
                                        <li><span class="flyout_disc"></span>Andorid :<a href="https://play.google.com/store/apps/details?id=com.mobile.Acutelearn" target="_blank">
                                                https://play.google.com/store/apps/details?id=com.mobile.Acutelearn</a>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Please read this Privacy Policy carefully, as it governs how you use Acutelearn or its
                                affiliate products. If you do not agree to this Privacy Policy, please do not use
                                Acutelearn.</p>

                            <h2 id="eu-notice-to-end-users">Notice to End Users</h2>
                            <p>In some cases an administrator may create an account in Acutelearn on the behalf of a
                                “<b>End-User</b>” and may provide his/her information, including Personal Information.
                                In such cases the administrator who may be your Employer or a Acutelearn Authorised
                                Training partner (<b>ATP</b>) is our “<b>Customer</b>.” We collect Information under the
                                direction of our customers and have no direct relationship with the <b>End-User</b>
                                whose personal data we process.</p>
                            <p>A End-user’s use of the Acutelearn platform may be subject to the Customer’s policies,
                                if any.
                            </p>
                            <p>Our <b>Customer</b> must have the authority to act on behalf of the <b>End-User</b> and
                                to consent to the collection and use of their Personal Information as described in this
                                Privacy Policy.
                            </p>
                            <p>If you are an End-user using our platform, please direct your privacy inquiries to your
                                administrator. Acutelearn is not responsible for the privacy or security practices of
                                our customers, which may differ from those set forth in this privacy statement. </p>
                            <p>Acutelearn does not send personalized advertisement or Marketing communication to end
                                users.</p>

                            <h2>International Transfer</h2>

                            <p>Personal data collected by Acutelearn may be stored and processed in your region, and in
                                any other country where Acutelearn or its affiliates, data sub-processors operate
                                facilities. To facilitate Acutelearn global operations, we may transfer and access such
                                information from around the world, including from other countries in which the Company
                                has operations. A list of the Acutelearn’s global offices is available <a href="https://www.Acutelearn.com/contact-us" target="_blank">here</a>. This
                                Privacy Statement shall apply even if Acutelearn transfers Data to other countries.</p>
                            <p>Currently, Acutelearn and its data sub processors have data centers in United States,
                                India and Japan.
                            </p>
                            <p>The data we hold may also be processed by employees operating in countries listed above,
                                such staff may be engaged in, among other things, product development and customer and
                                technical support. The data protection and other laws of these countries might not be as
                                comprehensive as those in your country. By submitting your data and/or using Our
                                Services, You consent to the transfer, storing, and processing of Your data in these
                                countries.</p>
                            <p><b>Note to our customers in Europe:</b> We transfer personal data from the European
                                Economic Area and Switzerland to other countries, some of which have not been determined
                                by the European Commission to have an adequate level of data protection. When we do, we
                                use contracts, to help ensure your rights and protections travel with your data. By
                                submitting your data and/or using Our Services, You consent to the transfer, storing,
                                and processing of your data in these countries.</p>

                            <h2>Use of your Personal Information and Other Data</h2>
                            <p>Acutelearn uses this Privacy Policy to govern the use of personal information you
                                provide to us. We use your personal information solely to provide our services to you.
                                We also use your Personal Identifiable Information (PII) together with other <a ng-click="scrollTo('eu-personally-non-identifiable')" href="">Personally
                                    Non-Identifiable Information (non - PII)</a> to help us better understand our users
                                and to improve the content and functionality of our site.</p>

                            <h2>Personal Identifiable Information</h2>
                            <p>We may collect the following <b>personal information</b> from you in the following
                                situations</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>When you visit our website or mobile apps and
                                    voluntarily provide the following information via one of our contact forms, via a
                                    chat or phone session, or as part of a purchase of one of our courses or register on
                                    our site:
                                    <ul>
                                        <li><span class="flyout_disc"></span>Contact Information,such as <b>name, email
                                                address, mailing address, phone number, IP</b></li>
                                        <li><span class="flyout_disc"></span>Information about your business, such as
                                            <b>company name, company size, business type</b>
                                        </li>
                                        <li><span class="flyout_disc"></span>Your Acutelearn account details which may
                                            include billing information, credit/debit card details, if provided and the
                                            account password.</li>
                                    </ul>
                                </li>
                                <p>Note : All payment transactions are processed through secure payment gateway
                                    providers. We do not store any card information (other than the last 4 digits of
                                    your card) in our servers. </p>
                                <li><span class="flyout_disc"></span>When you use our Learning Management System
                                    (*.lms.Acutelearn.com), mobile applications, our support system or respond to our
                                    surveys:
                                    <ul>
                                        <li><span class="flyout_disc"></span><b>Contact Information</b>, such as
                                            <b>name, email address, mailing address, IP address, geographic
                                                location</b>, or <b>phone number</b>;
                                        </li>
                                        <li><span class="flyout_disc"></span>Unique Identifiers, such as
                                            <b>username</b>,or <b>password</b>;
                                        </li>
                                        <li><span class="flyout_disc"></span><b>Name</b> and <b>e-mail</b> address when
                                            you provide feedback from the Service(s)</li>
                                    </ul>
                                </li>
                                <li><span class="flyout_disc"></span>When you use our community website or blogs
                                    <ul>
                                        <li>Please refer to our section on <a ng-click="scrollTo('eu-public-forums')" href="">Public Forums</a></li>
                                    </ul>
                                </li>
                            </ul>
                            <p>Users may, however, visit our Site anonymously. We will collect personal identification
                                information from Users only if they voluntarily submit such information to us. Users can
                                always refuse to supply personally identification information, except that it may
                                prevent them from engaging in certain Site related activities.</p>

                            <h2 id="eu-personally-non-identifiable">Non-Personal Identifiable Data:</h2>
                            <p>We capture some Non Identifiable data in our logs. The data in the logs includes</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>Device type</li>
                                <li><span class="flyout_disc"></span>browser type</li>
                                <li><span class="flyout_disc"></span>language preference, </li>
                                <li><span class="flyout_disc"></span>time zone,</li>
                                <li><span class="flyout_disc"></span>screen size</li>
                                <li><span class="flyout_disc"></span>referring / exit pages</li>
                            </ul>

                            <h2>Use</h2>
                            <p>We may use the personal information collected from within the Websites, chatbox, mobile,
                                email or when you use the Service(s) to:</p>

                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>Provide you with the Service(s).</li>
                                <li><span class="flyout_disc"></span>Send you <a ng-click="scrollTo('eu-communication')" href="">communication</a> from the Service(s)</li>
                                <li><span class="flyout_disc"></span>Assess the needs of your business to determine or
                                    suggest suitable products.</li>
                                <li><span class="flyout_disc"></span>Send you requested product or service information.
                                </li>
                                <li><span class="flyout_disc"></span>Respond to customer service requests.</li>
                                <li><span class="flyout_disc"></span>Administer your account.</li>
                                <li><span class="flyout_disc"></span>Send you promotional and marketing communications.
                                </li>
                                <li><span class="flyout_disc"></span>Facilitate your transactions with other users when
                                    you use our Service(s).</li>
                                <li><span class="flyout_disc"></span>Perform statistical analyses of user behaviour and
                                    characteristics at an aggregate level in order to measure interest in and use of the
                                    various areas of the site.</li>
                                <li><span class="flyout_disc"></span>We use your personal information to communicate
                                    with you in relation to the Acutelearn services including information regarding
                                    content, courseware, products, inquiries, and services via different channels (e.g.,
                                    by phone, email, chat) and by agreeing to this Privacy Policy you give us explicit
                                    permission to do so. Irrespective of the fact if also you have registered yourself
                                    under DND or DNC or NCPR service, you still authorize us to give you a call for the
                                    above mentioned purposes.</li>

                            </ul>
                            <p>When you provide us with personal information about your contacts we will only use this
                                information for the specific reason for which it is provided.</p>

                            <h2>Share</h2>
                            <p>If your subscription was provided by your employer or a Acutelearn Authorised training
                                partner, we will share information, including personal information with them.</p>
                            <p>We may transfer personal information to our Group Companies and sub-contractors that help
                                us provide our Service(s). Transfers to subsequent third parties are covered by the
                                service agreements with our data-processors. We reserve the right to disclose your
                                personal data as required by applicable law, in response to lawful requests by public
                                authorities, including meeting national security or law enforcement requirements and
                                when we believe that disclosure is necessary to protect our rights and/or to comply with
                                a judicial proceeding, court order, or other legal process served on us. </p>
                            <p>In the event Acutelearn goes through a business transition, such as a merger or
                                acquisition by another company, or sale of all or a portion of its assets, your user
                                account and personal data will likely be among the assets transferred. You will be
                                notified via email and via a prominent notice on our Websites of any such change in
                                ownership or control. We may also disclose your personal information to any other third
                                party with your prior consent.</p>

                            <h2>Data from Third Parties</h2>
                            <p>We also obtain data from third parties. We protect data obtained from third parties
                                according to the practices described in this statement, plus any additional restrictions
                                imposed by the source of the data. These third-party sources vary over time, but have
                                included:</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>Data brokers from which we purchase demographic
                                    data to supplement the data we collect.</li>
                                <li><span class="flyout_disc"></span>Social networks when you grant permission to make
                                    your data public on these networks including, permission to access your data on one 
                                    or more of these networks.</li>
                                <li><span class="flyout_disc"></span>Enterprise customer where you are an employee
                                     availing our services</li>
                                <li><span class="flyout_disc"></span>Existing or past learners who participate in our
                                    referral program</li>
                                <li><span class="flyout_disc"></span>Service providers that help us determine a location
                                    based on your IP address in order to customize certain products to your location.
                                </li>
                                <li><span class="flyout_disc"></span>Partners with which we offer co-branded services or
                                    engage in joint marketing activities, and</li>
                                <li><span class="flyout_disc"></span>Publicly-available sources such as open government
                                    databases or other data in the public domain.</li>

                            </ul>
                            <p>We may receive Personal Information about you from other sources like postal mail,
                                telephone or fax. We may add this information to the information we have already
                                collected from you via the Site in order to improve the products and services we
                                provide.</p>

                            <h2>Correction, Removal or Export of Personal Information</h2>
                            <p>Acutelearn acknowledges that you have the right to access, edit and delete your personal
                                information at any time. Upon request, we will provide you with reasonable access to the
                                personal information we collect about you. You also have the right to
                                withdraw your consent granted for collection of personal information by issuing a
                                written request for data erasure which will be followed by the Company in conformity
                                with the applicable privacy laws. You will have the opportunity to correct, update,
                                modify or delete this information or withdraw your consent as follows:</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span><b>If you have independently signed up with
                                        Acutelearn.</b><br>
                                    You may access, update or export your Personal Information included in your online
                                    Acutelearn account at any time by logging into your account and going to your
                                    profile or by <a ng-click="scrollTo('eu-reaching-out')" href="">reaching out to
                                        us</a></li>
                                <li><span class="flyout_disc"></span><b>If you are using an enterprise-provided account
                                        (Acutelearn Authorised Training Partner or your Employer)</b><br>
                                    Please review the section <a ng-click="scrollTo('eu-notice-to-end-users')" href="">Notice to End Users</a></li>
                            </ul>
                            <p><b>Exceptions in Deletion:</b> If you request access to your personal information and
                                discover that that information is wrong, we strive to give you ways to update it quickly
                                or to delete it – unless we have to keep that information for legitimate business or
                                legal purposes. We aim to maintain our services in a manner that protects information
                                from accidental or malicious destruction. Because of this, after you delete information
                                from our services, we may not immediately delete residual copies from our active servers
                                or backup systems</p>
                            <p><b>Exception in Data Portability:</b> There may be limits to the amount of information we
                                can practically provide. For example, we may limit an individual’s access to personal
                                information where the burden or expense of providing access would be disproportionate to
                                the risks to the individual’s privacy or where doing so would violate others’ rights.
                            </p>
                            <p><b>Exception in Data Correction:</b> Even after updates to your Personal Information, we
                                may maintain and store older versions of such information within our databases, access
                                logs and other records in order to comply with other routine data audits. </p>
                            <h2 id="eu-cookies">Cookies and Other Tracking Technologies</h2>
                            <p> Acutelearn and our third-party partners, such as our advertising and analytics
                                partners, (listed <a href="https://www.Acutelearn.com/terms-and-conditions#list-of-data-subprocessors" target="_blank"> here</a>) use cookies and other tracking technologies (e.g., web
                                beacons, device identifiers and pixels) to provide functionality and to recognize you
                                across different Services and devices.</p>
                            <p>The types of cookies used by Acutelearn includes</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span><b>Essential Cookies:</b> These cookies are
                                    essential for the basic functionalities offered by the Site and Service(s). These
                                    class of cookies helps in keeping a user logged in to the Service(s) and remember
                                    relevant information when they return to the Service(s).</li>
                                <li><span class="flyout_disc"></span><b>Insight Cookies:</b> These are used for tracking
                                    the user activities within the Service(s), which in turn helps us in improving your
                                    user experience.</li>
                                <li><span class="flyout_disc"></span><b>Marketing Cookies:</b> These are used for
                                    providing you with customized and interest-based ads based on your browsing behavior
                                    and other similar activities on our Websites.</li>

                            </ul>
                            <p>You can choose to have your computer warn you each time a cookie is being sent, or you
                                can choose to turn off all cookies. You do this through your browser settings. Since
                                each browser is different, look at your browser's Help Menu to learn the correct way to
                                modify your cookies.</p>
                            <p>If you turn cookies off, some of the features that make your site experience more
                                efficient may not function properly.</p>
                            <!-- OneTrust Cookies Settings button start -->
                    <!-- OneTrust Cookies Settings button end 

                            <h2>Data Subject's Rights To Complain</h2>

                            <h2>Users in the European Economic Area (“EEA”) and United Kingdom (“UK”)</h2>
                            <p>If you are located in the EEA or UK, you have the right to request access to your data in a
                                portable format and to request the rectification, erasure, restriction of processing, 
                                or objection to processing of your personal data. You may use the 
                                <a ng-click="scrollTo('eu-reaching-out')" href="">Contacting Acutelearn</a> 
                                to submit your request. Additionally, if you are located in the EEA, UK, or Switzerland,
                                you also have the right to lodge a complaint with the competent Supervisory Authority in 
                                your jurisdiction, if you have concerns about Acutelearn’s collection and use of your 
                                personal information that Acutelearn cannot solve to your satisfaction.<br><br>
                                Personal data is also processed outside of the UK, Switzerland, and the EEA by Acutelearn, 
                                or our service providers, including to process transactions, 
                                facilitate payments, and provide support services as described in the privacy policy “Use”
                                and “Share” section. We use Standard Contractual Clauses adopted 
                                by the European Commission to facilitate transfers of personal data from the EEA to third 
                                countries and have entered into data processing agreements with our
                                service providers or third parties to restrict and regulate their processing of your data. 
                                By submitting your data or using our Services, you consent to this transfer,
                                storage, and processing by Acutelearn and its sub-processors.</p>

                            <h2>Users in Singapore</h2>
                            <p>If you are a Singapore resident and you have a complaint, you may refer it to the office 
                                of the PDPC, as PDPC serves as Singapore’s main authority in matters relating to personal 
                                data protection and represents the Singapore Government internationally on data protection related issues.
                                You may complain through 
                                <a href="https://www.pdpc.gov.sg/Complaints-and-Reviews/Report-a-Personal-Data-Protection-Concern" target="_blank">PDPC | Report a Personal Data Protection Concern</a>  for breaches related to collection,
                                use, disclosure and protection of personal data.</p>
                            
                            <h2>Users in California</h2>
                            <p>If you believe a business has violated the CCPA, you may file a consumer complaint with 
                                the Office of the Attorney General. If you choose to file a complaint with the Office of 
                                the Attorney General, explain exactly how the business violated the CCPA, and describe 
                                when and how the violation occurred. Only the Attorney General can file an action against 
                                businesses and using consumer complaints and other information, the Attorney General may 
                                identify patterns of misconduct that may lead to investigations and actions on behalf of 
                                the collective legal interests of the people of California. Please note that the Attorney 
                                General does not represent individual California consumers hence cannot represent you or give 
                                you legal advice on how to resolve your individual complaint.</p>

                            <h2>Users Other than US / EU &amp; UK</h2>
                            <p>In order to provide the Services to you, we must transfer your data to the United States and 
                                process it there. If you are using the Services from outside the United States, you consent 
                                to the transfer, storage, and processing of your data in and to the United States or other 
                                countries.</p>
                            
                            <h2 id="eu-personalised-advertisement">Advertisement</h2>
                            <p>We partner with third parties to manage our advertising on other sites. Our third party
                                partners may use technologies such as <a ng-click="scrollTo('eu-cookies')" href="">cookies</a> to gather information about your activities on our Website and
                                other sites in order to provide you advertising based upon your browsing activities and
                                interests. If you wish to not have this information used for the purpose of serving you
                                interest-based ads, you may opt-out by <a href="https://preferences-mgr.truste.com/" target="_blank" rel="noopener noreferrer">clicking here</a> (or if located in the
                                European Union, <a href="https://www.youronlinechoices.eu/" target="_blank" rel="noopener noreferrer">click here</a>). Please note this does not opt you out of
                                being served ads. You will continue to receive generic ads.</p>

                            <h2>Analytics &amp; Web-beacons</h2>
                            <p>Acutelearn uses third party software for analytics. All metrics information collected
                                from your usage of the Service(s) shall be transmitted to our analytics provider. This
                                information is then used to evaluate how users use our platform, and to compile
                                statistical reports on user activity for us. We further use the same statistical
                                analytics tool to track or to collect your Personally Identifiable Information (PII). We
                                will use this information in order to maintain, enhance, or add to the functionality of
                                the Service(s) and to personalize the experience for you. Aggregated information and
                                analysis may be made public where desired by Acutelearn.</p>
                            <p>We and our third party advertising partners use technologies such as web beacons in
                                analyzing trends, administering the website, tracking users’ movements around the site,
                                and gathering demographic information about our user base as a whole. We may receive
                                reports based on the use of these technologies by these companies on an individual and
                                aggregated basis.</p>

                            <h2>Do Not Track.</h2>
                            <p>Currently, various browsers — including Internet Explorer, Firefox, and Safari — offer a
                                “do not track” or “DNT” option that relies on a technology known as a DNT header, which
                                sends a signal to Web sites’ visited by the user about the user's browser DNT preference
                                setting. Acutelearn does not currently commit to responding to browsers' DNT signals
                                with respect to the Company's Web sites, in part, because no common industry standard
                                for DNT has been adopted by industry groups, technology companies or regulators,
                                including no consistent standard of interpreting user intent. Acutelearn takes privacy
                                and meaningful choice seriously and will make efforts to continue to monitor
                                developments around DNT browser technology and the implementation of a standard.</p>

                            <h2>Links to Third Party Sites</h2>
                            <p>Our Websites contain links to other websites that are not owned or controlled by
                                Acutelearn. Please be aware that we are not responsible for the privacy practices of
                                such other websites or third parties. We encourage you to be aware when you leave our
                                Websites and to read the privacy policies of each and every website that collects
                                personal information.
                            </p>

                            <h2 id="eu-public-forums">Public Forums</h2>
                            <p>Our Websites offer publicly accessible blogs or community forums. You should be aware
                                that any information you provide in these areas may be read, collected, and used by
                                others who access them. To request removal of your personal information from our blog or
                                community forum, <a ng-click="scrollTo('eu-reaching-out')" href="">reach out to us</a>.
                                In some cases, we may not be able to remove your personal information, in which case we
                                will let you know that we are unable to do so and why.</p>
                            <p>Acutelearn may provide bulletin boards, blogs, or community threads on the Company’s Web
                                sites. Any personal information you choose to submit in such a forum may be read,
                                collected, or used by others who visit these forums, and may be used to send you
                                unsolicited messages. Acutelearn is not responsible for the personal information you
                                choose to submit in these forums.</p>
                            <p>Remember that when you share information publicly, it may be indexable by search engines.
                            </p>

                            <h2>Single Sign-On &amp; Other social Media Features</h2>
                            <p>Our Websites include social media features, such as the Facebook “Like” button, the
                                “Share This” button or interactive mini-programs. Where we have your consent, these
                                features may collect your IP address, which page you are visiting on our Websites, and
                                may set a cookie to enable the feature to function properly. Social media features and
                                widgets are either hosted by a third party or hosted directly on our Websites. Your
                                interactions with these features are governed by the privacy policy of the company
                                providing them.</p>
                            <p>You can log in to our Websites using sign-in services by LinkedIn. The services will
                                authenticate your identity and provide you the option to share certain personal
                                information with us such as your name and email address.
                            </p>
                            <h2 id="eu-communication">Communications from the Websites</h2>
                            <p>We may use your email address to send our newsletters and/or marketing communications. If
                                you no longer wish to receive these communications, you can opt out by following the
                                instructions contained in the emails you receive or by contacting us at <a href="" class="noselect">unsubscribe@Acutelearn.com</a> </p>

                            <p>We will send you announcements related to the Service(s) on occasions when it is
                                necessary to do so. For instance, if our Services) is temporarily suspended for
                                maintenance, we might send you an email. Generally, you may not opt-out of
                                communications which are not promotional in nature. If you do not wish to receive them,
                                you may delete your Account.</p>

                            <h2>Information Security</h2>
                            <p>Our Websites and Service(s) have industry standard security measures in place to protect
                                against the loss, misuse, and alteration of the information under our control. When you
                                provide us with sensitive information (such as credit card information or login
                                credentials), we will encrypt that information via Secure Socket Layer (SSL).</p>
                            <p>Although we will do our best to protect your personal data, we cannot guarantee the
                                security of your data transmitted to our Websites or via the Service(s) and any
                                transmission is at your own risk. Once we receive your personal information, we will use
                                strict procedures and security features to try to prevent unauthorised access.</p>
                            <p>We adopt appropriate data collection, storage and processing practices and security
                                measures to protect against unauthorized access, alteration, disclosure or destruction
                                of your personal information, username, password, transaction information and data
                                stored on our Site.</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>Our website is scanned on a regular basis for
                                    security holes and known vulnerabilities in order to make your visit to our site as
                                    safe as possible.</li>
                                <li><span class="flyout_disc"></span>We use regular Malware Scanning.</li>
                                <li><span class="flyout_disc"></span>Your personal information is contained behind
                                    secured networks and is only accessible by a limited number of persons who have
                                    special access rights to such systems, and are required to keep the information
                                    confidential. In addition, all sensitive/credit information you supply is encrypted
                                    via Secure Socket Layer (SSL) technology.</li>
                                <li><span class="flyout_disc"></span>All payment transactions are processed through
                                    secure gateway providers and are not stored or processed on our servers</li>
                            </ul>

                            <h2>Testimonials</h2>
                            <p>We post customer testimonials/comments/reviews on our Websites which may contain personal
                                information. Prior to posting the testimonial, we obtain the customer's consent to post
                                their name along with their testimonial. If you want your testimonial removed, please <a  ng-click="scrollTo('eu-reaching-out')" href="">reach out to us</a></p>

                            <h2>For TOGAF 9.1 course:</h2>
                            <p>We agree to keep confidential any and all information that comes into its possession
                                regarding the Program’s examinations. If Acutelearn is found to have disclosed the
                                content of any of the Open Group’s examination scenarios, questions, or answers to any
                                third party other than in the normal course of Acutelearn attendees sitting the
                                examinations, the Agreement and all Schedules attached to it will be immediately
                                terminated and the Open Group shall remove all of the Acutelearn’s ATCs and Affiliates
                                from the Accreditation Register.</p>

                            <h2>Children's Personal Information</h2>
                            <p>Acutelearn does not knowingly collect any personal information from children under the
                                age of 16. If you are under the age of 16, please do not submit any personal information
                                through our Websites or Service(s). We encourage parents and legal guardians to monitor
                                their children’s Internet usage and to help enforce this Policy by instructing their
                                children never to provide personal information through our Service(s) or Websites
                                without their permission. If you have reason to believe that a child under the age of 16
                                has provided personal information to us through our Websites or Service(s), please
                                contact us and we will endeavor to delete that information and terminate the child's
                                account from our databases.</p>

                            <h2>Amendments</h2>
                            <p>Acutelearn has the discretion to update this privacy policy at any time. When we do, we
                                will post a notification on the main page of our Site, revise the updated date at the
                                bottom of this page and send you an email. We encourage Users to frequently check this
                                page for any changes to stay informed about how we are helping to protect the personal
                                information we collect. You acknowledge and agree that it is your responsibility to
                                review this privacy policy periodically and become aware of modifications. Your
                                continued use of this Websites or the Service(s) following the posting of any amendment,
                                modification, or change to this Policy shall constitute your acceptance of the
                                amendments to this Policy. You can choose to discontinue use of the Websites or
                                Service(s), if you do not accept the terms of this Policy, or any modified version of
                                this Policy.</p>


                            <h2 id="eu-reaching-out">Contacting Acutelearn</h2>
                            <p>If you have a complaint regarding data privacy, you may contact our Grievance Officer at
                                the details provided below, who is responsible for redressing your grievances: </p>
                            <ul class="address-wrap">
                                <li><b>Name :</b> Ramana Reddy ,</li>
                                <li><b>Designation:</b> Data Privacy Officer</li>
                                <li><b>Email ID:</b><b class="email-blue"> training@ugetit.in</b></li>
                            </ul>
                            <p>You can also reach out to us through postal mail at :</p>

                            <!-- <address>
                                <b>United States of America</b><br>
                                Acutelearn Americas Inc. <br>
                                201 Spear Street,<br>
                                Suite 1100, San Francisco, CA 94105<br>
                                United States
                            </address>
                            <br> 
                    
                            <address>
                                <b>India</b> <br>
                               Acutelearn Technologies,<br>
                                Plot no 80 &81, 4th Floor, Sai Enclave,<br>
                                Arunodaya colony,<br>
                                Madhapur, Hyderabad -500081.<br>
                            </address>
                            <br>
                            <!-- <address>
                                <b>Singapore</b> <br>
                                Acutelearn Singapore Pte. Ltd. <br>
                                #14-302, The Plaza,<br>
                                7500A Beach Rd, <br>
                                Singapore 199591.<br>
                            </address> -->


                    <!-- </li>


                        </ul> -->

                </ngx-tab>
                <!-- <ngx-tab tabTitle="Terms of Use" [active]="currentTabId === 1">
                    <div>
                        <h1>Terms of use</h1>
                    <ul >
                        <li>
                            <h2>Please read the following carefully </h2>
                            <p>These terms and conditions ("Terms and Conditions") control your use of this website
                                Acutelearn.com ("Website"). In these Terms and Conditions, "Acutelearn" is referred to
                                as the "Company", "us," or "we."</p>
                            <p>'You' refers to a user or a paying customer. If you are a company or another person who
                                gives access to company products, you agree to take responsibility in full in case of
                                damages or indemnification that could properly lie against the customer.</p>
                            <p>The Acutelearn website (the 'Site'), the educational services made available through the
                                site and the content (the 'Products') are owned, operated and maintained, as applicable,
                                by Acutelearn ('we', 'our', 'us', or the 'Company'). The Site, Products and Content
                                are, collectively, the 'Company Products'.</p>
                            <p>By (a) using or accessing the Company Products, including, but not limited to downloading
                                or accessing, (b) offering a Course through the Site or through Software; you agree to
                                the terms and conditions set forth in these Terms of Use (the "Terms")</p>
                            <p>By using this website or its Products and Services, You Agree and Warrant that you have
                                read, understood, and agreed to be Bound by these terms. Company's privacy policy can be
                                found at Privacy Policy. If you do not accept these terms, you must not use - and are
                                not authorized to use - all or any portion of the company's website and its products or
                                services (as defined below).</p>

                            <h2>Please read them carefully before you use the services of this site.</h2>
                   
                                <ul >
                                    <li><span ></span>You should not use this site in an unlawful manner;
                                        you must respect the website terms and conditions and follow the Privacy Policy.
                                    </li>
                                    <li><span ></span>Under no situations or circumstances, will the
                                        company be liable for any change in the content which it provides on the website
                                        through its products and services, including but not limited to any errors,
                                        omissions, loss, or damage experienced in connection with the use of exposure, any
                                        content made available via our products, services, or various resources such as
                                        email, blog etc.</li>
                                    <li><span ></span>Our services are free to any user with access to
                                        the Internet. However, we are not responsible for the charges incurred for the usage
                                        of hardware, software, or internet services provider fee. Also, the user is fully
                                        responsible for the proper functioning of computer hardware and internet access.
                                    </li>
                                    <li><span ></span>You will be required to use login credentials for
                                        some of the sections on the site and the company reserves the right to block access
                                        to our services for any user who does not follow these conditions</li>
                                    <li><span ></span>We make sure that users get uninterrupted access to
                                        our service, but there is no obligation to do so.</li>
                                    <li><span ></span>Acutelearn is not responsible and is not obligated
                                        for issues in your network or server beyond certain limits.</li>
                                </ul>
    
                     
                         
                            <h2>Website usage guidelines</h2>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>Do not insult, abuse, harass, stalk, threaten, or
                                    otherwise infringe the rights of others;</li>
                                <li><span class="flyout_disc"></span>Do not publish, post, distribute or disseminate any
                                    defamatory, infringing, indecent, offensive or unlawful material or information.
                                </li>
                                <li><span class="flyout_disc"></span>Do not upload, install, transfer files which are
                                    protected by Intellectual Property Laws or software which affect other computers.
                                </li>
                                <li><span class="flyout_disc"></span>It's prohibited to edit HTML source code, reverse
                                    engineer or attempt to hack.</li>
                                <li><span class="flyout_disc"></span>Do not run Spam services/scripts or anything which
                                    could affect the infrastructure, and in turn, the users.</li>
                                <li><span class="flyout_disc"></span>Do not communicate spam, advertise, or sell
                                    services such as digital downloads, eBooks, or phishing links.</li>
                                <li><span class="flyout_disc"></span>You may not copy, distribute, and indulge in
                                    plagiarism with website content or user submitted content.</li>
                                <li>Do not use any content which violates any legal, regulatory, Governmental or network
                                    operator conditions or codes of practice.</li>
                            </ul>

                            <h2>Links and Hyperlinks Terms</h2>
                            <p>This website may have links to other websites. We do not undertake any control on the
                                content of these websites; nor are we responsible for their website content. The sole
                                purpose of the links included are to provide users information. Hence, Acutelearn will
                                not be held responsible.</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>You may not mirror or frame the homepage or any
                                    other pages of this Site on any other website or webpage.</li>
                                <li><span class="flyout_disc"></span>Do not link to Acutelearn pages and subpages with
                                    spam links/anchor text, which could provide a false impression. This may create
                                    misunderstanding for the users.</li>
                                <li><span class="flyout_disc"></span>Do not use or include copyrighted or registered
                                    trademarks, or Intellectual property images, design or content as a link to
                                    Acutelearn website.</li>
                                <li><span class="flyout_disc"></span>Do not link to pages which support racism,
                                    terrorism.</li>
                                <li><span class="flyout_disc"></span>Do not link to pages which provide pornographic
                                    content and violate human and animal rights.</li>
                                <li><span class="flyout_disc"></span>Do not link pages to content which infringes the
                                    intellectual property of any third party, person or entity.</li>
                                <li><span class="flyout_disc"></span>Do not link pages to content which violates any
                                    legal, regulatory, Governmental or network operator conditions or codes of practice.
                                </li>
                            </ul>

                            <h2>Copyright and Intellectual Property</h2>
                            <p>We value and respect others intellectual property and expect our users to do the same.
                            </p>
                            <p>The entire contents of the Site are protected by copyright and trademark laws. The owner
                                of the copyrights and trademarks are Acutelearn.com, its affiliates, or other third
                                party licensors. The material on the site, including text, graphics, code and/or
                                software is copyrighted and belongs to Acutelearn, therefore you may not duplicate,
                                modify, publish, or reproduce the content in any manner.</p>
                            <p>Acutelearn does not take any responsibility for the content on other sites (except our
                                partners and affiliates), that you may find when searching or accessing Acutelearn
                                products or services. The Privacy Policy and Terms of Use of the sites that you visit
                                will administer that material.</p>
                            <p>Acutelearn has all the rights to disable or prohibit access to the users who do not
                                respect and involve in the infringement of Acutelearn intellectual property.</p>
                            <p>You are not allowed to use any of the digital images or logos from the website. In case
                                of copyright issues, there has to be a written consent from the trademark owner.</p>

                            <h2>Claims of Intellectual Property Violations</h2>
                            <p>If you believe that your work has been used without your permission in a way which
                                prompts for copyright infringement. Please provide us with the information given below
                                and we will act on it.</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>The authorized person who will act on behalf of the
                                    owner of the copyright, should send a digital or physical signature.</li>
                                <li><span class="flyout_disc"></span>A description of the copyrighted work that you
                                    claim to be infringing your IP.</li>
                                <li><span class="flyout_disc"></span>A description of where and how the material that
                                    you claim is infringing is located on the Acutelearn website, with enough detail
                                    that we may find it on the website.</li>
                                <li><span class="flyout_disc"></span>Contact Details - Address, telephone number, and
                                    email address.</li>
                                <li><span class="flyout_disc"></span>A statement by you, that the information which you
                                    provided is accurate and your claim of the copyright or intellectual property is on
                                    your owner's behalf</li>
                                <li><span class="flyout_disc"></span>You can reach Acutelearn to notify your claims of
                                    copyright by writing to <a href="https://Acutelearn.secure.force.com/support/" target="_blank">Help and Support Team</a>.</li>
                            </ul>

                            <h2>Transaction Terms</h2>
                            <p>If you believe that your work has been used without your permission in a way which
                                prompts for copyright infringement. Please provide us with the information given below
                                and we will act on it.</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>To make a transaction on Acutelearn website, you
                                    are bound to pay for that transaction.</li>
                                <li><span class="flyout_disc"></span>Please pay close attention to your payment details
                                    such as total bill, taxes, shipping costs, and discounts.</li>
                                <li><span class="flyout_disc"></span>There are certain products which require additional
                                    Terms and Conditions which you have to agree to before you make the purchase.</li>
                                <li><span class="flyout_disc"></span>Invoice of the order placed can be viewed at <a href="http://lms.Acutelearn.com/user/invoice" target="_blank">http://lms.Acutelearn.com/user/invoice</a>
                                </li>
                                <li><span class="flyout_disc"></span>If a delegate has completed the course and is
                                    eligible for a certificate, then same shall be delivered to the address provided by
                                    the delegate within 30 working days.</li>
                            </ul>

                            <p>WE MAKE NO WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED, WITH RESPECT TO ANY PRODUCTS OR
                                SERVICES SOLD ON OR THROUGH Acutelearn.</p>
                            <p>No additional or different terms contained in any purchase order, document, transmission
                                or other communication shall be binding upon Acutelearn unless agreed to by Acutelearn
                                in writing.</p>
                            <p>Acutelearn reserves the right to modify, change without prior notice and in its sole
                                discretion, to limit the order quantity on any item and to refuse service to anyone.</p>

                            <h2>Subscription Services</h2>
                            <p>You will be allowed to subscribe to a particular course or collection of courses
                                ("Subscription Services") and will be charged automatically, depending on payment
                                frequency chosen by you, such as monthly/quarterly/yearly, as may be applicable.</p>
                            <p>You will be permitted to freeze or schedule the freeze of the Subscription Services at
                                anytime and Acutelearn shall not charge you for any such opted Subscription Services
                                according to the payment cycle. Further, the Subscription Services, access will be
                                revoked if the user opts to freeze the subscription. You will have the option to
                                re-subscribe to the particular Subscription Services again and the payment cycle will
                                start accordingly.</p>
                            <p>Please note that Subscription Services discounts and any Subscription Services are only a
                                limited time special subscription promotions in effect apply only to eligible items
                                displaying the offer message on the Subscription Services item information pages, and
                                then only if you select the Subscription Services. Subscription Services are will last
                                only till a particular period of time, as may be specified on the relevant page. Special
                                limited time subscription promotions only apply during their effective dates.</p>
                            <p>Acutelearn retains the rights to modify the Subscription Service from time to time,
                                including by adding or deleting features and functions, in an effort to improve your
                                experience. But we will not make changes to the Subscription Service that materially
                                reduces the functionality of the Subscription Service provided to you during the
                                Subscription Term. We might provide some or all elements of the Subscription Service
                                through third party service providers. </p>
                            <p>If you register for a free trial of the Subscription Service, we will make the
                                Subscription Service available to you on a trial basis, free of charge until the earlier
                                of (a) the end of the free trial period or (b) the start date of your subscription. If
                                we include additional terms and conditions on the trial registration web page, those
                                will apply as well. During the free trial period, (i) the Subscription Service is
                                provided “as is” and without warranty of any kind, (ii) we may suspend, limit, or
                                terminate the Subscription Service for any reason at any time without notice, and (iii)
                                we will not be liable to you for damages of any kind related to your use of the
                                Subscription Service. Unless you subscribe to the Subscription Service before the end of
                                the free trial, all of your data on the Subscription Service will be permanently deleted
                                at the end of the trial, and we will not recover it. Acutelearn shall not refund or in
                                any ways discount, set off any amount once the trial period has ended. In case where You
                                wish discontinue to paid Services, please login to your account. Go to Profile&gt;Manage
                                Subscription, click on Subscription and Freeze the subscription to stop any further
                                payments being made.</p>
                            <p>Valid credit cards are the only payment method accepted for Subscription Services
                                purchases. The customer shall at all time use a credit card for the use of Subscription
                                Services and upon the completion of the free trial period, you will be charged
                                automatically without the requirement of any further approval or consent, such a period
                                of the trial period shall be mentioned on the at the time of registration to the
                                Subscription Services, Acutelearn absolves any liability with regard to information of
                                period of the free trial period.It is clarified that, once the Subscription Services are
                                accepted the participants authorize Acutelearn and any of its affiliates to debit the
                                accounts at regular intervals as may be notified to the participants </p>
                            <p>Further, you will have the option of cancelling the Subscription Services during the free
                                trial period, without incurring any charges for the same. To initiate the free trial,
                                Acutelearn shall charge you a token amount to ensure the validity of Credit Card. The
                                token amount will be refunded automatically within 5 days. Further You may cancel your
                                account with Acutelearn by reaching out to <a href="https://Acutelearn.secure.force.com/support/" target="_blank">Help and Support Team</a></p>
                            <p>Your Subscription Services will remain in effect until it is cancelled. If you cancel
                                your Subscription Services and then reactivate it, the discount applied to any
                                Subscription Services item may not be the same discount in effect at the time of
                                cancellation. If you reactivate a Subscription Services, it will be created with the
                                same products with which you previously subscribed. If the Subscription Services
                                discount percentage for such Subscription Services item changes, the new discount will
                                be applied to your products of that item. </p>
                            <p>All fees are exclusive of taxes, which we will charge as applicable. You agree to pay any
                                taxes applicable to your use of the Subscription Service. All fees are exclusive of any
                                VAT, Service tax, GST or any other applicable law and tax deductions. If you are
                                required to deduct or withhold any tax, you must pay the amount deducted or withheld as
                                required by law and pay us an additional amount so that we receive payment in full as if
                                there were no deduction or withholding. </p>

                            <h2>LIMIT OF LIABILITY</h2>
                            <p>You expressly understand and agree that the Company shall not be liable for any direct,
                                indirect, incidental, special, consequential or exemplary damages, including, damages
                                for loss of profits, goodwill, use, data or other intangible losses (even if the Company
                                has been advised of the possibility of such damages), resulting from: (i) the use or the
                                inability to use the service; (ii) the cost of procurement of substitute goods and
                                services resulting from any goods, or services purchases or obtained or messages
                                received or transactions entered into through or from the Website or otherwise; (iii)
                                unauthorized access to or alteration of your transmissions or data or confidential
                                information; (iv) statements or conduct of any third party on the Products; (v) or (v)
                                any other matter relating to the Products.</p>

                            <h2>INDEMNITY</h2>
                            <p>You agree to indemnify and hold the Company and our subsidiaries, affiliates, officers,
                                directors, agents, and employees, harmless from any claim or demand (including legal
                                expenses and the expenses of other professionals) made by a third party due to or
                                arising out of your breach of this Terms of Use or the documents it incorporates by
                                reference, or your violation of any law or the rights of a third party.</p>

                            <h2>Pricing Disclaimer</h2>
                            <p>All prices, products, and offers of Acutelearn website are subject to change without
                                notice.</p>
                            <p>While we make sure to provide most accurate and up-to-date information, in some cases one
                                or more items on our website may be priced incorrectly. This might happen due to human
                                errors, digital images, technical errors, or a mismatch in pricing information received
                                from our suppliers.</p>
                            <p>Acutelearn reserves the right to change prices for all our products, offers, or deals.
                                These changes are done due to market conditions, course termination, providers, price
                                changes, errors in advertisements, and other mitigating circumstances. However, the
                                price you paid at the time of purchase still holds for you.</p>

                            <h2>Proprietary Use of Acutelearn Study Material</h2>
                            <p>Acutelearn reserves the right to change prices for all our products, offers, or deals.
                                These changes are done due to market conditions, course termination, providers, price
                                changes, errors in advertisements and other extenuating circumstances. However, the
                                price you paid at the time of purchase still holds for you.</p>

                                                            <h2>Job Guarantee Program(s)</h2>
                                <p>In case You have subscribed to Acutelearn’s Job Guarantee Program(s), please read the
                                <a href="" ng-click="tnc.setCurrentCategory(9, 'job-guarantee-program-active')">Job Guarantee Program(s) Terms and Conditions</a>  
                                carefully prior to Your enrollment.</p>
                            


                        </li>
                    </ul>

                    </div>
                    <div style="display: none;">
                        <h2> Terms and conditions</h2> 
                        <h5 style="margin-top: 40px;">Acceptance of this Agreement</h5>
                        <p>By clicking on the 'SIGNUP' option, the participant ("You" or "Your") agrees to the Terms and Conditions, obligations, representations, warranties, and agreements contained herein (the "Agreement"). In the event, You are not willing to accept the Agreement, You shall not be authorized or allowed to proceed further to view or use in any manner any content, information, courseware, products and services ("Services") published,available or provided on www.Acutelearn.com (the "Website"), which is owned, maintained and monitored by Acutelearn Solutions Private Limited ("Us", "We" or "Our").</p>

                        <h5>User ID and Password</h5>
                        <p>By entering into this Agreement, You acknowledge and agree that Your user ID and password ("Participant Account") is for Your exclusive use only. Use or sharing of Your Participant Account with another user is not permitted and is cause for immediate blocking of Your access to the Website, the Services and the Content, the Courseware, and termination of this Agreement.</p>

                        <p>You agree that You are solely responsible for maintaining the confidentiality of Your Participant Account and for all activities that occur under it. You agree to immediately notify our Grievance Officer if You become aware of or have reason to believe that there is any unauthorized use of Your Participant Account. You also agree to take all reasonable steps to stop such unauthorized use and to cooperate with Us in any investigation of such unauthorized uses. We shall not under any circumstances be held liable for any claims related to the use or misuse of Your Participant Account due to the activities of any third party outside of our control or due to Your failure to maintain the confidentiality and security of Your Participant Account.</p>

                        <h5>Content and Courseware</h5>
                        <p>As a part of our Services offered through our Website, We shall grant you access to our content, courseware, practice tests, and other information, documents, and data which may be in audio, video, written, graphic, recorded, photographic, or any machine-readable format in relation to the specific certification training course You have registered for ("Content and Courseware").</p>

                        <p>We reserve the right to amend, revise or update the Content and Courseware offered to You. In the event such an amendment, revision or updation occurs, We may require you pay an additional fee to access such amended, revised, or updated Content and Courseware.</p>

                        <h5>Free Access</h5>
                        <p>The selected courses of free access (hereinafter the “Courses”) are brought to you by Acutelearn Solutions Pvt. Ltd. (“Acutelearn”). Your access to the said Courses is limited to the extent of self-learning videos and course resources only. Nothing herein shall at any time be construed to mean unhindered or unconditional access to all the features of the said Courses, as may be available upon purchase of the respective Courses.</p>

                        <p>Your free access to these courses shall be limited to such respective number of days, as may be notified against each Course on the date of your enrollment, and shall be revoked at the instance of expiry of the respective number of days. However, Acutelearn at its sole option reserves the right to revoke or extend your free access to all contents made available to you at any early instance under the free access without any notice or liability.</p>

                        <p>Upon your enrollment to these free courses, no license is deemed to be granted to you for further sale or to utilize the materials for any reuse, reproduction, re-publication for commercial/non-commercial purposes.</p>

                        <p>All materials provided to you under the free access are copyright products of Acutelearn or its partners. Any violation of laws herein, or otherwise, shall make you liable to the maximum extent available under law.</p>

                        <p>All regular features of the Courses, including exam vouchers and certifications, but not limited to them, shall be available upon a full value complete purchase of the respective Courses only. Upon such full value complete purchase of any of these Courses, your balance of the free access days shall be added onto the number of days that may be generally applicable to the respective Courses.</p>

                        <p>The above terms shall be read along with Acutelearn’s Terms of Use and in the event of any conflicts, the ones stated here shall prevail, but to the extent of the said free access Courses only.</p>

                        <h5>Please also read our Privacy Policy carefully.</h5>
                        <p>Acutelearn reserves all other rights, ‘ingress’ & ‘egress’ with respect to the free access, and may extend or forfeit the tenure of this offer at any time.</p>


                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Refund Policy" [active]="currentTabId === 2">
                   <div>
                    <h1>Cancellation And Refund policy</h1>
                    <ul class="desp_faqs_cont">
                        <li>
                            <p>Thank you for buying our courses. We ensure that our users have a rewarding experience
                                while they discover, assess, and purchase our courses, whether it is an instructor-led
                                or self-paced training.</p>
                            <p>As with any online purchase experience, there are terms and conditions that govern the
                                Refund Policy. When you buy a training course on Acutelearn, you agree to our Privacy
                                Policy, Terms of Use and refund policy.<br><br>Our refund policy is as follows:</p>

                            <h2>Cancellation &amp; Refunds: Online Training</h2>
                            <p>
                            </p><h2>For Self Placed Learning:</h2>Raise refund request within 7 days of purchase of course.
                            Money back guarantee is void if the participant has accessed more than 25% content or
                            downloaded the E-Book. Any refund request beyond 7 days of purchasing the course will not be
                            accepted and no refund will be provided.<p></p>

                            <h2>For Instructor Led Training:</h2>
                            <p>Raise refund request within 7 days of purchase of course. Money back guarantee is void if
                                the participant has accessed more than 25% content of any e-learning course or has
                                attended Online Classrooms/received recordings for more than 1 day.<br>
                                Also, In case a user downloads the E-Book for the course the money back guarantee will
                                be void. Any refund request beyond 7 days of purchasing the course will not be accepted
                                and no refund will be provided. </p>

                            <h2>For Post Graduate Programs: </h2>
                            <p>Raise refund requests within 7 days of purchase of the postgraduate program. The
                                Money-back guarantee is void if the participant raises any refund request beyond 7 days
                                of purchasing the course. Refund requests after the 7 day period will not be accepted
                                and no refund will be provided. Notwithstanding the above, the booking fee paid by the
                                participant towards securing his/her seat in a program shall be non-refundable under all
                                circumstances.</p>

                            <h2>Cancellation &amp; Refunds: For CSM, CSPO, PSM, PSPO programs</h2>
                            <p>Acutelearn Solutions, reserves the right to postpone/cancel an event, or change the
                                location of an event because of insufficient enrollments, instructor illness or force
                                majeure events (like floods, earthquakes, political instability, etc)</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>In case Acutelearn Solutions cancels an event,
                                    100% of course fees will be refunded to the delegate if the refund raise request is
                                    within 10 days of purchase of course. However travel, logistics or any personal
                                    expense incurred by learners/participants will not be refunded.</li>
                                <li><span class="flyout_disc"></span>In case Acutelearn Solutions cancels/postpones an
                                    event, the participants who are ahead of 10 days of purchase, of course, will be
                                    rescheduled to any upcoming batch without any extra charges.</li>
                                <li><span class="flyout_disc"></span>If a cancellation is done by a delegate 10 business
                                    days (or more) prior to the event, 10% of the total paid fee will be deducted and
                                    the remaining amount will be refunded to the delegate.</li>
                                <li><span class="flyout_disc"></span>If a cancellation is done by a delegate within 10
                                    business days (or less) of the event, no refunds will be made.</li>
                                <li><span class="flyout_disc"></span>No refunds or credits will be available for
                                    participants who fail to attend both days of the course.</li>
                            </ul>

                            <h2>Refund request can be initiated in two ways</h2>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>From my orders section, by clicking on “Initiate
                                    Refund” against specific item of an order. This will work when item quantity is one.
                                </li>
                                <li><span class="flyout_disc"></span>In case item quantity is more than one, please
                                    reach out to our support team through our Help &amp; Support section on the website.
                                </li>
                            </ul>


                            <h2>Refunds: Duplicate payment</h2>
                            <p>Refund of the duplicate payment made by the delegate will be processed via the same
                                source (original method of payment) in 10 working days post intimation by the customer.
                            </p>

                            <h2>Acutelearn reserves the right to revise the terms &amp; conditions of this policy
                                without any prior notice.</h2>

                            <p><i><strong>Note: </strong> All refunds will be processed within 10 working days after the
                                    refund request is approved by Acutelearn.</i></p>
                        </li>
                    </ul>
                   </div>
                </ngx-tab>
                <ngx-tab tabTitle="Reschedule Policy" [active]="currentTabId === 3">
                  <div>
                    <h1>Rescheduling policy</h1>
                    <ul class="desp_faqs_cont">
                        <li>
                            <p>In case Acutelearn reschedules the training event, the options available to the
                                delegates are:</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>100% refund, if the rescheduled dates do not fit
                                    into the delegate's schedule.</li>
                                <li><span class="flyout_disc"></span>The delegate would have the privilege of
                                    rescheduling and attending a class in future at his/her convenience, at any
                                    location, on any date of a scheduled training.</li>
                                <li><span class="flyout_disc"></span>The delegates, if they so wish, can send a
                                    replacement. However, this should be intimated to Acutelearn Solutions at least 3
                                    days prior to the event.</li>
                            </ul>

                            <p>Whereas, if for some unforeseen reasons, a delegate wishes to reschedule his/her
                                registration to a future date, a rescheduling fee is charged as mentioned below:</p>
                            <ul class="disc_methods">
                                <li><span class="flyout_disc"></span>If the rescheduling request is received 10 business
                                    days prior (or more), 10% of registration fee will be charged.</li>
                                <li><span class="flyout_disc"></span>Please note that rescheduling will be subject to
                                    availability of seats.</li>
                                <li><span class="flyout_disc"></span>If the request of rescheduling is received within
                                    10 business days or less, no rescheduling will be allowed. However, the delegate can
                                    send a replacement instead.</li>
                                <li><span class="flyout_disc"></span>Notwithstanding the foregoing, the course must be
                                    attended within 6 months of the participant's original sign-up date, and no refunds
                                    or credits will be available for participants who fail to attend a course within
                                    this six-month period.</li>
                            </ul>

                        </li>
                    </ul>
                  </div>
                </ngx-tab> -->
            </ngx-tabset>
        </div>
    </div>
</div>

<!-- <app-become-instructor-partner></app-become-instructor-partner> -->