<app-header-style-two></app-header-style-two>

<app-hometwo-main-banner></app-hometwo-main-banner>

<!-- <app-hometwo-about></app-hometwo-about> -->

<div class="courses-categories-area bg-image pt-100 pb-70" *ngIf="showPlans">
    <div class="container">
        <div class="section-title text-start">
            <!-- <span class="sub-title">Courses Categories</span> -->
            <!-- <h2>Browse Trending Categories</h2> -->
            <!-- <div class="cart-table table-responsive" *ngIf="loginDisplay">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">Claim</th> 
                            <th scope="col">Value</th>
                      
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of dataSource">
                            <td class="product-name">
                                <a >{{item.claim}}</a>
                            </td>
                            <td class="product-price">
                                <span class="unit-amount">₹{{item.value}}</span>
                            </td>
                         
                        </tr>
                     
                    </tbody>
                </table>
            </div> -->
            <h2>Trending Course Plans</h2>
            <!-- <a routerLink="/courses-3-columns-style-2" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a> -->
            <a routerLink="/ViewAllCoursePlan" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">View All</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>
        <app-categories-style-one></app-categories-style-one>
    </div>
</div>

<!-- <div class="funfacts-area pt-100">
    <div class="container">
        <div class="funfacts-inner">
            <app-funfacts></app-funfacts>
        </div>
    </div>
</div> -->

<app-hometwo-courses></app-hometwo-courses>

<app-our-mission></app-our-mission>

<!-- <div class="partner-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Our Company & Partners</h2>
        </div>
        <app-partner-style-one></app-partner-style-one>
    </div>
</div> -->

<!-- <div class="instructor-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Make Connections</span>
            <h2>Team of Instructors</h2>
            <p>lLorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut nisi ut aliquip ex ea.</p>
        </div>
        <app-instructors-style-two></app-instructors-style-two>
    </div>
</div> -->

<!-- <app-students-feedback-form></app-students-feedback-form> -->

<!-- <div class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Explore News</span>
            <h2>Our Latest Insights</h2>
            <a routerLink="/blog-style-1" class="default-btn"><i class='bx bx-book-reader icon-arrow before'></i><span class="label">Read All</span><i class="bx bx-book-reader icon-arrow after"></i></a>
        </div>
        <app-blog></app-blog>
    </div>
</div> -->

<!-- <app-webinar-countdown></app-webinar-countdown> -->