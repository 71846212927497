<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="/">Home</a></li>
                <li>Instructor Details</li>
            </ul>
            <h2>Instructor Details</h2>
        </div>
    </div>
</div>

<div class="instructor-details-area pt-100 pb-70">
    <div class="container">
        <div class="instructor-details-desc">
            <div class="row">
                <div class="col-lg-4 col-md-4">
                    <div class="instructor-details-sidebar">
                        <img src={{InstructorImage}} alt="image">
                        <!-- <div class="instructor-details-meta">
                            <ul>
                                <li>
                                    <i class='bx bxs-group'></i>
                                    <span>Follower</span>
                                    <a routerLink="/">102</a>
                                </li>
                                <li>
                                    <i class='bx bx-group'></i>
                                    <span>Following</span>
                                    <a routerLink="/">81</a>
                                </li>
                            </ul>
                        </div> -->
                        <!-- <a routerLink="/contact" class="default-btn"><i class='bx bx-plus-circle icon-arrow before'></i><span class="label">Follow</span><i class="bx bx-plus-circle icon-arrow after"></i></a> -->
                        <!-- <ul class="social-link">
                            <li><a href="#" class="d-block facebook" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block twitter" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block instagram" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block linkedin" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" class="d-block pinterest" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        </ul> -->
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="instructor-details">
                        <h3>{{InstrcuctorName}}</h3>
                        <span class="sub-title">{{InstrcuctorShortDesc}}</span>
                        <p>{{InstrcuctorLongDesc}}</p>
                        <div class="instructor-details-info">
                            <div class="row">
                                <!-- <div class="col-lg-6 col-md-12">
                                    <h3>My Education</h3>
                                    <ul>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>Harvard University</span>
                                            Bachelor in Mathematics
                                        </li>
                                        <li>
                                            <i class='bx bxs-graduation'></i>
                                            <span>University of Toronto</span>
                                            Bachelor in English
                                        </li>
                                    </ul>
                                </div> -->
                                <div class="col-lg-6 col-md-12">
                                    <h3>Experience</h3>
                                    <ul>
                                        <li>
                                            <!-- <i class='bx bx-briefcase'></i> -->
                                            <p>{{InstructorExperience}} Years</p>
                                        </li>
                                        <!-- <li>
                                            <i class='bx bx-briefcase'></i>
                                            <span>EnvyTheme</span>
                                            2010-2020
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section-title text-start">
            <h2>Courses by {{InstrcuctorName}}</h2>
        </div>
        <!-- <div class="row row-cols-1 row-cols-md-3 g-4">

            <div class=" col-lg-3 col-md-3 " *ngFor="let item of courses" (click)="GoToCourses(item.courseId,item.courseHeader,item.courseName)">
                
                    <div class="card h-100 ">
                        <div class="courses-image ">
                            <a class="d-block "><img src={{item.iconUrl}} alt="image "></a>
                            <div class="courses-tag ">
                                <a routerLink="/" class="d-block ">{{item.technologyName}}</a>
                            </div>
                        </div>
                        <div class="card-body ">
                            <div class="card-title">
                              
                                <img src={{item.img}}  class="rounded-circle mr-2" alt="image">

                                <span>{{item.facultyName}}</span>
                            </div>
                            
                            <ng-container class="title">
                                <span [title]="courseName.innerText" >
                              
                            <h3 ><a id="coursename" #courseName  routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">
                                
                                     {{item.courseName }}
                                    </a></h3>
                                </span>
                            </ng-container>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.average)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-total">
                                    {{item.average}} ({{item.noOfRatings}} rating)
                                </div>
                            </div>
                        </div>
                        
                        <div class="courses-box-footer">

                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i>{{item.startingNumber+item.buyCount}} students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> {{item.count}} lessons
                                </li>
                                <li class="courses-price">
                                  <span *ngIf="rup"> ₹</span> 
                                  <span *ngIf="Dol"> $</span> 
                                    
                                    
                                    {{item.price}}
                                </li>
                            </ul>
                        </div>
                    </div>
                
            </div>
           
        </div>  -->



        <div class="row row-cols-1 row-cols-md-3 g-4">

            <div class=" col-lg-3 col-md-3 " *ngFor="let item of courses" (click)="GoToCourses(item.courseId,item.courseHeader,item.courseName)">
                
                    <div class="card h-100 ">
                        <div class="courses-image ">
                            <a class="d-block "><img src={{item.iconUrl}} alt="image "></a>
                            <div class="courses-tag ">
                                <a routerLink="/" class="d-block ">{{item.technologyName}}</a>
                            </div>
                        </div>
                        <div class="card-body ">
                            <!-- <div class="card-title">
                                <!-- <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image"> 
                                <img src={{InstructorImage}} class="rounded-circle mr-2" alt="image">
                                <span>{{InstrcuctorName}}</span>
                            </div> -->
                            <!-- <h3><a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">{{item.courseName  }}</a></h3> -->
                            <ng-container class="title">
                                <span [title]="courseName.innerText" >
                              
                            <h3 ><a id="coursename" #courseName  routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">
                                
                                     {{item.courseName }}
                                    </a></h3>
                                </span>
                            </ng-container>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.average)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-total">
                                    {{item.average}} ({{item.noOfRatings}} rating)
                                </div>
                            </div>
                        </div>
                        
                        <div class="courses-box-footer">

                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i>{{item.startingNumber+item.buyCount}} students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> {{item.count}} lessons
                                </li>
                                <li class="courses-price">
                                  <span *ngIf="rup"> ₹</span> 
                                  <span *ngIf="Dol"> $</span> 
                                    
                                    
                                    {{item.price}}
                                </li>
                            </ul>
                        </div>
                    </div>
                
            </div>
           
        </div> 





      <!--  <div class="row  row-cols-1 row-cols-md-3 g-4">
            <div class="col-lg-3 col-md-6" *ngFor="let item of courses" (click)="GoToCourses(item.courseId,item.courseHeader,item.courseName)">
               
               
               
                <div class="single-courses-box mb-30 ">
                    
                    <div class="courses-image">
                        <a class="d-block"><img src={{item.iconUrl}} alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}" class="d-block">{{item.technologyName}}</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                           
                            <img src={{InstructorImage}} class="rounded-circle mr-2" alt="image">
                            <span>{{InstrcuctorName}}</span>
                        </div>
                        <h3><a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}" class="d-inline-block">{{item.courseName}}</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                    <li [ngClass]="{'selected': (star <= item.average)}">
                                        <i class="bx bxs-star"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="rating-total">
                                {{item.average}} ({{item.noOfRatings}} rating)
                            </div>
                        </div>
                    </div>

                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <!-- {{item.startingNumber+item.buyCount}} 
                                <i class='bx bx-user'></i> 1 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> {{item.count}} lessons
                            </li>
                            <li class="courses-price">
                               
                                ₹{{item.price}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Design</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                            <div class="rating-total">
                                4.5 (2 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 15 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-price">
                                $250
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Development</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Anderson</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 5 lessons
                            </li>
                            <li class="courses-price">
                                $150
                            </li>
                        </ul>
                    </div>
                </div>
            </div> comment        </div>-->
    </div>
</div>