import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-courses-grid-style-one-columns-three-page',
  templateUrl: './courses-grid-style-one-columns-three-page.component.html',
  styleUrls: ['./courses-grid-style-one-columns-three-page.component.scss']
})
export class CoursesGridStyleOneColumnsThreePageComponent implements OnInit {
  courses: any;
  stars: number[] = [1, 2, 3, 4, 5];
  constructor(private _authService:AuthService,private title:Title,private router: Router) { }

  ngOnInit(): void {
    this.GetAllCourses();
  }
  GoToCourse(courseid:any,courseheader:any,coursename:any){
    debugger
    this.title.setTitle(coursename)
    this.router.navigate(['/course/'+courseheader+'/'+courseid]);

 }
  GetAllCourses(){
    debugger
    this._authService.GetCoursesForMenu().subscribe((finalresult: any) => {
      debugger
       var finalresult = JSON.parse(finalresult);
      if (finalresult.status == "200") {
        debugger
        console.log(finalresult.result)
        this.courses = finalresult.result;
        for (let i = 0; i < this.courses.length; i++) {
          // this.courses[i].price = this.courses[i].price ;
          // this.offerprice = finalresult.result.offerPrice
          if (this.courses[i].offerPrice != 0) {
            this.courses[i].price = this.courses[i].offerPrice;
          }
          else{
            this.courses[i].price = this.courses[i].price;

          }
          if (this.courses[i].iconUrl != "null") {
            this.courses[i].iconUrl = finalresult.result[i].iconUrl;            
            // this.noimage=true;;
          }
          else {
            this.courses[i].iconUrl = "assets/img/courses/courses8.jpg";          

          }

          if (finalresult.result[i].imageURL != null) {
            this.courses[i].imageURL =  finalresult.result[i].imageURL;

            // this.noimage=true;;

          }
          else {
            this.courses[i].imageURL = "assets/img/courses/courses1.jpg";

          }
          if (finalresult.result[i].img != null) {
            this.courses[i].img =  finalresult.result[i].img;

            // this.noimage=true;;

          }
          else {
            this.courses[i].img = "assets/img/courses/courses2.jpg";

          }
        }
        // if (finalresult.result.imageURL != null) {
        //   this.ImageURL = baseurl + finalresult.result.imageURL;
        //   // this.noimage=true;;

        // }
        // else {
        //   // this.ImageURL = baseurl + "/courseFiles/dummy identityproof.png";

        // }
        setTimeout(() => {
        }, 1000);
      }
      else {
      }
      (error) => {

      }
    });
  }
}
