import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
// import { FuseAlertType } from '@fuse/components/alert';


@Component({
    selector: 'app-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit {
   
    address: any;
    phoneNo:any;
    email:any;
    emails: any;
    name: any;
    number: any;
    subject: any;
    message: any;
    showAlert: boolean;
    alert: { type: string; message: any; };
    googleMapUrl: any;
    GoogleMapUrl: any;
    policies: any;
    // alert: { type: FuseAlertType; message: string } = {
    //     type: 'success',
    //     message: ''
    //   };
    constructor( private _authService:AuthService) { }

    ngOnInit(): void {
        this.GetCompanydetails();
        this.Getgoogleurl();
    }

    submit(form){
        debugger
        // var name = form.name;
        // console.log(name);
        
        // var email = form.email;
        // console.log(email);

        // var number = form.number;
        // console.log(number);
        
        // var subject = form.subject;
        // console.log(subject);
        
        // var message = form.message;
        // console.log(message);
        var data = {
            Name: form.name,
            MobileNumber:form.number,
            Email:form.email,
            Subject:form.subject,
            Message:form.message,
        
           CreatedBy: localStorage.getItem("AzureUserId")
      }
        this._authService.contactus(data).subscribe((result: any) => {
            debugger
              var result = JSON.parse(result);
              if (result.status == "200") {
                  //debugger
                   // Set the alert
                   this.alert = {
                    type   : 'success',
                    message: result.message
                };
                // Show the alert
                this.showAlert = true;

                  setTimeout(() => {
                    
                    this.showAlert = false;
                    window.location.reload();
                    // this._router.navigate(['/masters/companydetails']);
                    
                  }, 2000); 
                  // setTimeout(() => {
                    // this._router.navigate(['/masters/companydetails']);
                  // }, 2000); 
                  
              }
              else {
               // Set the alert
               this.alert = {
                type   : 'error',
                message: result.message
            };
            // Show the alert
            this.showAlert = true;
            setTimeout(() => {
              this.showAlert = false;
            }, 2000);
              }
              (error) => {
     
             }
          });
    }
    clear(form){
form.name="";
form.number="";
form.email="";
form.subject="";
form.message="";
    }
    GetCompanydetails()
    {
        debugger
        this._authService.GetCompanydetails().subscribe((result: any) => {
             var result = JSON.parse(result);
              if (result.status == "200") {
                  
                  console.log('details',result.result)
                  this.address= result.result[0].address;
                   this.phoneNo= result.result[0].phoneNo;
                  // this.email= result.result[0].email;
                   this.emails= result.result[0].email;
                  
                  setTimeout(() => {
                  }, 1000);
              }
              else {
              }
              (error) => {
     
             }
          });
    }
    // Save()
    // {
    //     debugger
                   
       
  
      
    //   this._authService.contactus(data).subscribe((result: any) => {
    //         debugger
    //         //  var result = JSON.parse(result);
    //           if (result.status == "200") {
    //               //debugger
    //                // Set the alert
    //                this.alert = {
    //                 type   : 'success',
    //                 message: result.message
    //             };
    //             // Show the alert
    //             this.showAlert = true;
    //               setTimeout(() => {
                    
    //                 this.showAlert = false;
    //                 // this._router.navigate(['/masters/companydetails']);
                    
    //               }, 2000); 
    //               // setTimeout(() => {
    //                 // this._router.navigate(['/masters/companydetails']);
    //               // }, 2000); 
                  
    //           }
    //           else {
    //            // Set the alert
    //            this.alert = {
    //             type   : 'error',
    //             message: result.message
    //         };
    //         // Show the alert
    //         this.showAlert = true;
    //         setTimeout(() => {
    //           this.showAlert = false;
    //         }, 2000);
    //           }
    //           (error) => {
     
    //          }
    //       });
    // }


    Getgoogleurl()
    {
        debugger
        this._authService.Getgoogleurl().subscribe((result: any) => {
             var result = JSON.parse(result);
              if (result.status == "200") {
                debugger
               //this.policies=result.result;
                  console.log('policies',result.result)
                  this.GoogleMapUrl= result.result.googleMapUrl;
                  var re = /;/gi; 
                  var str = this.GoogleMapUrl;
                  this.GoogleMapUrl = str.replace(re, "&"); 
                  console.log(this.GoogleMapUrl)
                 
                  
                  
                  setTimeout(() => {
                  }, 1000); 
              }
              else {
              }
              (error) => {
     
             }
          });
    }
}