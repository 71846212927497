<section class="courses-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <span class="sub-title">Discover Courses</span>
            <h2>Our Popular Online Courses</h2>
            <a routerLink="/courses-2-columns-style-2" class="default-btn"><i
                    class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i
                    class="bx bx-show-alt icon-arrow after"></i></a>
        </div>

        <ngx-tabset>
            <ngx-tab *ngFor="let item of courses" tabTitle="{{item.technology.technologyName}}"
                [active]="item.technology.technologyId==0?true:false">
                <div class="row">
                    <div class="col-lg-4 col-md-6" *ngFor="let course of item.course"
                        (click)="GoToCourse(course.courseId,course.courseHeader,course.courseName)">
                        <div class="single-courses-box mb-30">
                            <div class="courses-image">
                                <a class="d-block"><img src={{course.iconUrl}} alt="image"></a>
                                <div class="courses-tag">
                                    <a routerLink="/" class="d-block">{{course.technologyName}}</a>
                                </div>
                            </div>
                            <div class="courses-content">
                                <div class="course-author d-flex align-items-center">
                                    <img src={{course.img}} class="rounded-circle mr-2" alt="image">
                                    <span>{{course.facultyName}}</span>
                                </div>
                                <h3><a id="coursename" #courseName
                                        routerLink="/course/{{course.courseHeader}}/{{course.courseId}}"
                                        class="d-inline-block"> {{course.courseName }}</a></h3>
                                <div class="courses-rating">
                                    <div class="review-stars-rated">
                                        <ul class="list-inline rating-list" *ngFor="let star of stars"
                                            style="display:inline-block;">
                                            <li [ngClass]="{'selected': (star <= course.average)}">
                                                <i class="bx bxs-star"></i>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="rating-total">
                                        {{course.average}} ({{course.noOfRatings}} rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer">
                                <ul>
                                    <li class="students-number">
                                        <i class='bx bx-user'></i> {{course.startingNumber+course.buyCount}} students
                                    </li>
                                    <li class="courses-lesson">
                                        <i class='bx bx-book-open'></i>{{course.count}} lessons
                                    </li>
                                    <li class="courses-price">
                                        <span *ngIf="rup"> ₹</span>
                                        <span *ngIf="Dol"> $</span>
                                        {{course.price}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>

        </ngx-tabset>
    </div>
</section>