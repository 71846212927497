import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TabsetComponent } from 'ngx-tabset';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {
  @ViewChild('tabSet') tabset: TabsetComponent;
  currentTabId: number=0;
  tabName: any;
  description: any;
  detail: any;
  policies: any;
  value: any;
  
  constructor(private route:Router,private activatedroute:ActivatedRoute,
    private _authService:AuthService) { 

  }

  ngOnInit(): void {
    debugger
    // this.activatedroute.queryParams.subscribe(params => {
       this.value=this.activatedroute.snapshot.params['value']
      // let tab = ["Privacy Policy", "Terms of Use", "cancellation and refund policy","Reschedule Policy"].indexOf(value);
      // this.currentTabId = tab
      // this.tabset.tabs[tab].active = true;
    // });
    this.GetTermdetails();
  }

  GetTermdetails()
    {
        debugger
        this._authService.GetTermsPolicies().subscribe((result: any) => {
             var result = JSON.parse(result);
              if (result.status == "200") {
                debugger
                  this.policies=result.result;
                  let index = this.policies.findIndex(x => x.tabName === this.value);
                     this.currentTabId = index
                    this.tabset.tabs[index].active = true;
                                    // this.tabName= result.result[0].tabName;
                  //  this.description= result.result[0].description;

                  setTimeout(() => {
                  }, 1000);
              }
              else {
              }
              (error) => {
     
             }
          });
    }

}
