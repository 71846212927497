<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Checkout</li>
            </ul>
            <h2>Checkout</h2>
        </div>
    </div>
</div>
<div class="checkout-area ptb-100">
    <div class="container">
        <div class="user-actions">
            <!-- <i class='bx bx-log-in'></i> -->
            <!-- <span>Returning customer? <a routerLink="/login">Click here to login</a></span> -->
            <!-- <span>Returning customer? <a href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_studentPortalSignUpAndSignIn&client_id=ca12fdfb-881f-4dd7-8853-a4016b4006be&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fugetitmain.fadelsoft.com%2F&scope=openid&response_type=id_token&prompt=login">Click here to login</a></span> -->
        </div>

        <form ngNoForm action="https://test.payu.in/_payment" name="payuform" method="POST">
            <!-- <form ngNoForm name="payuform" method="POST" target="payment_popup"  onsubmit="window.open('about:blank','payment_popup','width=900,height=500');"> -->
            <!-- <form [formGroup]="payuform"(ngSubmit)="onSubmit()" > -->

            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Billing Details</h3>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>
                                    <!-- <select formControlName="website" class="form-control">  
                                        <option disabled>Select Website</option>  
                                        <option>Choose Website</option>  
                                        <option *ngFor="let web of courses">{{web.countryName}}</option>  
                                    </select>  -->

                                     <div class="select-box">
                                        <select class="form-select"   [(ngModel)]="country" [ngModelOptions]="{standalone: true}">
                                             
                                                
                                                <option>Choose country</option>  
                                                <option *ngFor="let web of courses">{{web.countryName}}</option>  
                                            </select> 

                                    </div> 

                                </div>
                            </div>
                            <!-- <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <label>First Name <span class="required">*</span></label>
                                        <input type="text" class="form-control" [(ngModel)]="payuform.firstname" >
                                    </div>
                                </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="firstname">First Name</label>
                                    <input type="text" class="form-control" id="firstname" name="firstname" [(ngModel)]="payuform.firstname">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="lastname" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="companyname" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="address" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="city" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="state" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pincode" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6" style="display:none">
                                <div class="form-group">
                                    <label for="productInfo">Product Name:</label>
                                    <input type="text" hidden class="form-control" id="productInfo" name="productinfo" [(ngModel)]="payuform.productinfo">
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="email">Email Address <span class="required">*</span></label>
                                    <input type="email" id="email" name="email" class="form-control" [(ngModel)]="payuform.email">
                                </div>
                            </div>
                            <!-- <div class="form-group">
                                <label for="email">Email:</label>
                                <input type="email" class="form-control" id="email" name="email" [(ngModel)]="payuform.email">
                            </div> -->
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label for="email">Mobile Number <span class="required">*</span></label>
                                    <input type="number" class="form-control" id="phone" name="phone" [(ngModel)]="payuform.phone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6" style="display:none">
                                <div class="form-group">
                                    <label for="amount">Amount <span class="required">*</span></label>
                                    <input type="number" class="form-control" id="amount" name="amount" [(ngModel)]="payuform.amount">
                                </div>
                            </div>
                            <textarea name="surl" id="surl" ng-model="surl" rows="2" cols="2" hidden [(ngModel)]="payuform.surl"></textarea>
                            <textarea name="furl" id="furl" ng-model="furl" rows="2" cols="2" hidden [(ngModel)]="payuform.furl"></textarea>
                            <textarea name="key" id="key" ng-model="key" rows="2" cols="2" hidden [(ngModel)]="payuform.key"></textarea>
                            <textarea name="hash" id="hash" ng-model="hash" rows="2" cols="2" hidden [(ngModel)]="payuform.hash"></textarea>
                            <textarea name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2" hidden [(ngModel)]="payuform.txnid"></textarea>
                            <textarea name="service_provider" id="service_provider" rows="2" cols="2" hidden></textarea>
                            <!-- <button *ngIf="disablePaymentButton" type="button"  class="btn btn-default" (click)="confirmPayment()">Confirm</button>
                            <button *ngIf="!disablePaymentButton" type="submit" class="btn btn-danger">Submit</button> -->
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account" >
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div> -->
                            <!-- <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>
                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of carttable">
                                        <td class="product-name">
                                            <a (click)="GoToCourse(item.courseId,item.courseHeader,item.courseName)">{{item.coursename}}</a>
                                            
                                            <!-- <p>{{item.coursename}}</p> -->
                                        </td>

                                        <td class="product-total">
                                            <span class="subtotal-amount">₹{{item.TotalPrice}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="Tax">
                                            <span>Tax</span>
                                        </td>
                                        <td class="Tax">
                                            <span class="Tax">{{Tax}}</span>


                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-subtotal">
                                            <span>Cart Subtotal</span>
                                        </td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">{{Total}}</span>
                                        </td>
                                    </tr>


                                    <!-- <tr>
                                            <td class="order-shipping">
                                                <span>Shipping</span>
                                            </td>
                                            <td class="shipping-price">
                                                <span>$30.00</span>
                                            </td>
                                        </tr> -->
                                    <tr>
                                        <td class="total-price">
                                            <span>Order Total</span>
                                        </td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount">{{Total}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                    <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                    <!-- <label for="direct-bank-transfer">Direct Bank Transfer</label> -->

                                    <!-- Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account. -->
                                </p>
                                <p>
                                    <!-- <button *ngIf="disablePaymentButton" type="button" (click)="confirmPayment()" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Confirm Payment</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                                        <button *ngIf="!disablePaymentButton" type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Pay Now</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                           -->
                                    <button *ngIf="disablePaymentButton" type="button" (click)="confirmPayment()" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Confirm Payment</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                                    <button *ngIf="!disablePaymentButton" type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Pay Now</span><i class="bx bx-paper-plane icon-arrow after"></i></button>

                                    <!-- <input type="radio" id="paypal" name="radio-group">
                                        <label for="paypal">PayPal</label> -->
                                </p>
                                <!-- <p>
                                        <input type="radio" id="cash-on-delivery" name="radio-group">
                                        <label for="cash-on-delivery">Cash on Delivery</label>
                                    </p> -->
                            </div>
                            <!-- <a routerLink="/checkout" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Place Order</span><i class="bx bx-paper-plane icon-arrow after"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </form>

    </div>
</div>

<!-- <form action='https://test.payu.in/_payment' method='post'>
    <input type="hidden" name="key" value="oZ7oo9" />
    <input type="hidden" name="txnid" value="TXN-10001" />
    <input type="hidden" name="productinfo" value="vmvsphere" />
    <input type="hidden" name="amount" value="10" />
    <input type="hidden" name="email" value="gmdadnan@gmail.com" />
    <input type="hidden" name="firstname" value="adnan" />
    <input type="hidden" name="lastname" value="mohammad" />
    <input type="hidden" name="surl" value="https://testugetitapi.fadelsoft.com/api/Payment/Index" />
    <input type="hidden" name="furl" value="https://testugetitapi.fadelsoft.com/api/Payment/Index" />
    <input type="hidden" name="phone" value="8919238572" />
    <input type="hidden" name="hash" value="0ed86daa6b5ec62537e2027739eaab193b740652ab54a621a105310dc4cad368e6652cd040132efccf793e8e4f0cf2b47d6a2efcff8c00d9efb07597d6e0c005" />
    <input type="submit" value="submit"> </form> -->

<div class="checkout-area ptb-100" style="display:none;">
    <div class="container">
        <div class="user-actions">
            <i class='bx bx-log-in'></i>
            <!-- <span>Returning customer? <a routerLink="/login">Click here to login</a></span> -->
            <span>Returning customer? <a href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_studentPortalSignUpAndSignIn&client_id=ca12fdfb-881f-4dd7-8853-a4016b4006be&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fugetitmain.fadelsoft.com%2F&scope=openid&response_type=id_token&prompt=login">Click here to login</a></span>
        </div>
        <form ngNoForm action="https://test.payu.in/_payment" name="payuform" method="POST" target="payment_popup" onsubmit="window.open('about:blank','payment_popup','width=900,height=500');">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="billing-details">
                        <h3 class="title">Billing Details</h3>
                        <div class="row">
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Country <span class="required">*</span></label>
                                    <div class="select-box">
                                        <select class="form-select" [(ngModel)]="country" [ngModelOptions]="{standalone: true}">
                                            <option>United Arab Emirates</option>
                                            <option>China</option>
                                            <option>United Kingdom</option>
                                            <option>Germany</option>
                                            <option>France</option>
                                            <option>Japan</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>First Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="payuform.firstname">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Last Name <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="lastname" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Company Name</label>
                                    <input type="text" class="form-control" [(ngModel)]="companyname" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Address <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="address" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <label>Town / City <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="city" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>State / County <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="state" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Postcode / Zip <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pincode" [ngModelOptions]="{standalone: true}">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email Address <span class="required">*</span></label>
                                    <input type="email" class="form-control" [(ngModel)]="payuform.email">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone <span class="required">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="payuform.phone">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <label>Amount<span class="required">*</span></label>
                                <input type="number" class="form-control" id="amount" name="amount" [(ngModel)]="payuform.amount">
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <label>Product Name:</label>
                                <input type="text" class="form-control" id="productInfo" name="productinfo" [(ngModel)]="payuform.productinfo">
                            </div>
                            <textarea name="surl" id="surl" ng-model="surl" rows="2" cols="2" hidden [(ngModel)]="payuform.surl"></textarea>
                            <textarea name="furl" id="furl" ng-model="furl" rows="2" cols="2" hidden [(ngModel)]="payuform.furl"></textarea>
                            <textarea name="key" id="key" ng-model="key" rows="2" cols="2" hidden [(ngModel)]="payuform.key"></textarea>
                            <textarea name="hash" id="hash" ng-model="hash" rows="2" cols="2" hidden [(ngModel)]="payuform.hash"></textarea>
                            <textarea name="txnid" id="txnid" ng-model="txnid" rows="2" cols="2" hidden [(ngModel)]="payuform.txnid"></textarea>
                            <textarea name="service_provider" id="service_provider" rows="2" cols="2" hidden></textarea>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="create-an-account">
                                    <label class="form-check-label" for="create-an-account">Create an account?</label>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="ship-different-address">
                                    <label class="form-check-label" for="ship-different-address">Ship to a different address?</label>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="notes" id="notes" cols="30" rows="5" placeholder="Order Notes" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="order-details">
                        <h3 class="title">Your Order</h3>
                        <div class="order-table table-responsive">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th scope="col">Product Name</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of carttable">
                                        <td class="product-name">
                                            <a routerLink="/single-products">{{item.coursename}}</a>
                                        </td>
                                        <td class="product-total">
                                            <span class="subtotal-amount">{{item.TotalPrice}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="order-subtotal">
                                            <span>Cart Subtotal</span>
                                        </td>
                                        <td class="order-subtotal-price">
                                            <span class="order-subtotal-amount">{{Total}}</span>
                                        </td>
                                    </tr>
                                    <!-- <tr>
                                        <td class="Tax">
                                            <span>Tax</span>
                                        </td>
                                        <td class="Tax">
                                            <span class="Tax">{{Tax}}</span>
                                        </td>
                                    </tr> -->
                                    <!-- <tr>
                                        <td class="order-shipping">
                                            <span>Shipping</span>
                                        </td>
                                        <td class="shipping-price">
                                            <span>$30.00</span>
                                        </td>
                                    </tr> -->
                                    <tr>
                                        <td class="total-price">
                                            <span>Order Total</span>
                                        </td>
                                        <td class="product-subtotal">
                                            <span class="subtotal-amount">{{Total}}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="payment-box">
                            <div class="payment-method">
                                <p>
                                    <input type="radio" id="direct-bank-transfer" name="radio-group" checked>
                                    <!-- <label for="direct-bank-transfer">Direct Bank Transfer</label> -->

                                    <!-- Make your payment directly into our bank account. Please use your Order ID as the payment reference. Your order will not be shipped until the funds have cleared in our account. -->
                                </p>
                                <p>
                                    <button type="button" (click)="confirmPayment()" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Confirm Payment</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                                    <button type="submit" class="default-btn">Pay Now</button>

                                    <!-- <input type="radio" id="paypal" name="radio-group">
                                    <label for="paypal">PayPal</label> -->
                                </p>
                                <!-- <p>
                                    <input type="radio" id="cash-on-delivery" name="radio-group">
                                    <label for="cash-on-delivery">Cash on Delivery</label>
                                </p> -->
                            </div>
                            <!-- <a routerLink="/checkout" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Place Order</span><i class="bx bx-paper-plane icon-arrow after"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>