import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { protectedResources } from 'src/auth-config';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  getIpAddress() {
    return this._httpClient.get('https://api.ipify.org/?format=json')
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      console.error('An error occurred:', error.error.message);
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(
      'Something bad happened; please try again later.');
  }

  getGEOLocation(ip) {

    let url = "https://api.ipgeolocation.io/ipgeo?apiKey=b81cc74e124247a0b99438bc3c22f79c&ip=" + ip + "&fields=geo";
    return this._httpClient
      .get(url)
      .pipe(
        catchError(this.handleError)
      );
  }
  //url = protectedResources.todoListApi.endpoint;
  // updateDescription(desc: string) {
  //   //
  //   this.meta.updateTag({ name: 'description', content: desc })
  // }
  //   updateMetaInfo(content, author, category) {
  //     this.meta.updateTag({ name: 'description', content: content });
  //     this.meta.updateTag({ name: 'author', content: author });
  //     this.meta.updateTag({ name: 'keywords', content: category });
  // }

  // updateTitle(title?: string) {
  //     if (!title) {
  //         this.router.events
  //             .pipe(
  //                 filter((event) => event instanceof NavigationEnd),
  //                 map(() => this.activatedRoute),
  //                 map((route) => {
  //                     while (route.firstChild) { route = route.firstChild; }
  //                     return route;
  //                 }),
  //                 filter((route) => route.outlet === 'primary'),
  //                 mergeMap((route) => route.data)).subscribe((event) => {
  //                     this.titleService.setTitle(event['title'] + ' | Site name');
  //                 });
  //     } else {
  //         this.titleService.setTitle(title + ' | Site name');
  //     }
  // }
  baseUrl: any
  httpHeader = {
    headers: new HttpHeaders({
      'NoAuth': 'True',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Access-Control-Allow-Headers': 'X-Requested-With,content-type',
      'Access-Control-Allow-Credentials': 'true'
    })
  };
  constructor(private titleService: Title,
    private meta: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _httpClient: HttpClient) {
    this.baseUrl = environment.AppApiURL;
    // this.baseUrl = 'https://localhost:44328/';     
    //this.baseUrl = 'https://ugetithome.mbascloud.com/';
    //this.baseUrl = 'https://testugetitapi.fadelsoft.com/';
    //this.baseUrl = 'http://testugetitapi.fadelsoft.com/';
  }

  //  updateTitle(title: string) {
  //   this.title.setTitle(title);
  // }

  // updateOgUrl(url: string) {
  //   this.meta.updateTag({ name: 'og:url', content: url })
  // }

  // updateDescription(desc: string) {
  //   this.meta.updateTag({ name: 'description', content: desc })
  // }


  public contactsave(data) {
    //
    return this._httpClient.post(this.baseUrl + "api/UIMain/contactsave", data, { responseType: 'text' });
  }
  public Getgoogleurl() {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/Getgoogleurl", { responseType: 'text' });
  }
  public contactus(data) {
    //
    return this._httpClient.post(this.baseUrl + "api/UIMain/ContactUs", data, { responseType: 'text' });
  }

  public SendMsg(data) {
    //
    return this._httpClient.post(this.baseUrl + "api/UIMain/StudentMessage", data, { responseType: 'text' });
  }
  public GetCopyRights() {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetRightsReserved", { responseType: 'text' });
  }

  public GetCompanydetails() {
    return this._httpClient.get(this.baseUrl + "api/UIMain/Getcompanymaster", { responseType: 'text' });
  }
  public GetTermsPolicies() {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetTermsPolicies", { responseType: 'text' });
  }

  public GetCourses(): Observable<any> {

    return this._httpClient.get(this.baseUrl + "api/UIMain/GetAllCourses");
  }
  public GetCoursesForMenu() {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetAllCourses", { responseType: 'text' });
  }
  // public GetCoursesForMenu() {
  //   return this._httpClient.get(this.baseUrl + "api/UIMain/GetCourses", {responseType: 'text'});
  // }
  public Gettechnologies() {

    return this._httpClient.get(this.baseUrl + "api/UIMain/GetAllTechnologies", { responseType: 'text' });
  }
  public GetcourseById(id) {
    ////
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCourseById", { params: { id } });
  }
  public getchapters(id) {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/Getcoursecontentbycourseid", { params: { id } });
  }
  public AddCartItem(data: any) {
    return this._httpClient.post(this.baseUrl + "api/UIMain/AddCartItem", data, { responseType: 'text' });

  }
  public GetCartItems(id: any): Observable<any> {
    //
    // return this._httpClient.get(this.url + "GetCartItemsByUserId?id="+id);

    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCartItemsByUserId?id=" + id);
  }

  public DeleteCartItem(data: any) {
    //
    return this._httpClient.post(this.baseUrl + "api/UIMain/DeleteCartItem", data);

  }

  public PayNow(data: any) {
    return this._httpClient.post(this.baseUrl + "api/UIMain/PaymentGateway", data, { responseType: 'text' });

  }

  public PayUGateway(data: any): Observable<any> {
    //
    return this._httpClient.post(this.baseUrl + "api/UIMain/Demo1", data);

  }



  public GetModuleByCourseID(ID: any) {

    return this._httpClient.get(this.baseUrl + "api/UIMain/GetModuleByCourseID", { params: { ID } });
  }

  GetQuestions(Id: any) {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetFaqsByCourseId", { params: { Id } });

  }

  public GetSubscribedCoursesByCustomerId(id) {
    ////
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetSubscribedCoursesByCustomerId", { params: { id } });
  }

  public GetSubscribedCourses(id: any): Observable<any> {
    ////
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetSubscribedCourses?Id=" + id);
  }

  public GetRelatedCourses(id: any): Observable<any> {
    ////
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetRelatedcourses?Id=" + id);
  }
  GetCoursePlans() {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCoursePlans");

  }

  GetAllCoursePlans() {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCoursePlans");

  }
  // GetAllCoursePlans(){
  //   return this._httpClient.get(this.baseUrl + "api/UIMain/GetAllCoursePlans");

  // }


  GetCoursesPlanId(id: any): Observable<any> {
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCoursesPlanId?Id=" + id);

  }

  AddFaculty(formdata): Observable<any> {
    return this._httpClient.post(this.baseUrl + "api/Admin/AddFaculty", formdata);
  }

  GetCoursePlanById(id: any): Observable<any> {

    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCoursePlanById?Id=" + id);

  }

  public GetInstructorDetailsbasedOncourse(id) {

    return this._httpClient.get(this.baseUrl + "api/UIMain/GetInstructorDetailsbasedOncourse?Id=" + id);
  }

  public GetFacultyById(id): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetFacultyById?Id=" + id);
  }

  public GetCoursesByFaculty(id): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetCoursesByFaculty?Id=" + id);
  }

  public GetMyOrders(id): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetMyOrders?Id=" + id);
  }

  public GetBannerContent(): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetBannerContent");
  }

  public GetReceiptData(id, invoiceno): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetReceiptData?Id=" + id + "&InvoiceNo=" + invoiceno);
  }
  public GetInvoiceNo(): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GenerateInvoiceNo");
  }
  public Getcountry(): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/Getcountry");
  }
  public GetRatingsByCourseId(Id: any): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetRatings?courseId=" + Id);
  }

  public GetAllRatingsAndComments(): Observable<any> {
    //
    return this._httpClient.get(this.baseUrl + "api/UIMain/GetAllRatings");
  }


  //   updateMetaInfo(content, author, category) {
  //     this.meta.updateTag({ name: 'description', content: content });
  //     this.meta.updateTag({ name: 'author', content: author });
  //     this.meta.updateTag({ name: 'keywords', content: category });
  // }

  // updateTitle(title?: string) {
  //     if (!title) {
  //         this.router.events
  //             .pipe(
  //                 filter((event) => event instanceof NavigationEnd),
  //                 map(() => this.activatedRoute),
  //                 map((route) => {
  //                     while (route.firstChild) { route = route.firstChild; }
  //                     return route;
  //                 }),
  //                 filter((route) => route.outlet === 'primary'),
  //                 mergeMap((route) => route.data)).subscribe((event) => {
  //                     this.titleService.setTitle(event['title'] + ' ');
  //                 });
  //     } else {
  //         this.titleService.setTitle(title + '');
  //     }
  // }

  // updateTitle(title: string) {
  //   if (title) {
  //     this.title.setTitle(title);
  //   }
  // }

  // updateDescription(description: string) {
  //   if (description) {
  //     this.meta.updateTag({ name: 'description', content: description });
  //   }
  // }
}
