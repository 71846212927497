import { Content } from '@angular/compiler/src/render3/r3_ast';
import { Compiler, Component, ElementRef, HostListener, NgModule, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { AuthService } from 'src/app/components/auth.service';
import { mergeMap } from 'rxjs/operators';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { style } from '@angular/animations';

// import 'rxjs/add/operator/filter';
// import 'rxjs/add/operator/map';
// import 'rxjs/add/operator/mergeMap';
// import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-hometwo-courses',
  templateUrl: './hometwo-courses.component.html',
  styleUrls: ['./hometwo-courses.component.scss']
})
export class HometwoCoursesComponent implements OnInit {
  ipaddress: string = '';
  latitude: string = '';
  longitude: string = '';
  currency: string = '';
  currencysymbol: string = '';
  isp: string = '';
  city: string = '';
  country: string = '';
  province: string = '';


  InstructorImage: any;
  ImageURL: any;
  ellips: any;
  courses: any;
  techs: any;
  price: any;
  InstrcuctorName: string;
  ratings: any;
  selectedValue: number;
  charset: boolean;
  public Lat;
  public Lng;
  //filter:any;
  // activatedRoute:any;
  stars: number[] = [1, 2, 3, 4, 5];
  rup: boolean;
  Dol: boolean;
  PositionError: any;
  Position: any;
  constructor(private approute: ActivatedRoute, private _authService: AuthService, private titleService: Title, private compiler: Compiler,
    private router: Router, private meta: Meta, private title: Title, private metaTagService: Meta, private route: ActivatedRoute
    ,
    private activatedRoute: ActivatedRoute,

    private metaService: Meta) { }




  ngOnInit(): void {

    this._authService.getIpAddress().subscribe((res) => {
      this.ipaddress = res['ip'];
      this._authService.getGEOLocation(this.ipaddress).subscribe((res) => {
        console.log(res);
        this.latitude = res['latitude'];
        this.longitude = res['longitude'];
        this.city = res['city'];
        this.country = res['country_code2'];
        if (this.country = 'IN') {

          this.rup = true;
        }
        if (this.country != 'IN') {

          this.Dol = true;
        }
        this.isp = res['isp'];
        this.province = res['state_prov'];
      });
    })
    // debugger


    this.ellips = true;
    //this.ellips == '1' ? 'visible' : 'hidden';

    this.Getcourses()
    this.Gettechnologies();
    this.getLocation();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        var rt = this.getChild(this.activatedRoute)

        rt.data.subscribe(data => {
          console.log(data);
          // this.titleService.setTitle(data.title)

          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }

          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }

          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }

          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }

          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
        })

      })

    // this.meta.updateTag(
    //   {name: 'twitter:title', content:'Front-end Web Development'},
    //   `name='twitter:title'`
    // );
    // this.router.events
    // .filter((event) => event instanceof NavigationEnd)
    // .map(() => this.route)
    // .map((route) => {
    //     while (route.firstChild) route = route.firstChild;
    //     return route;
    // })
    // .filter((route) => route.outlet === 'primary')
    // .mergeMap((route) => route.data)
    // .subscribe((event) => {
    //     this.updateDescription(event['description'], event['keywords'], event['title']);
    // });

  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {

        this.Lat = position.coords.latitude;
        this.Lng = position.coords.longitude;
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }
  // updateDescription(desc: string, keywords: string, title: string) {
  //   this.titleService.setTitle(title);
  //   this.meta.updateTag({ name: 'description', content: desc })
  //   this.meta.updateTag({ name: 'keywords', content: keywords })
  //   this.meta.updateTag({ name: 'og:title', content: title })
  //   this.meta.updateTag({ name: 'og:description', content: desc })
  //}
  // description(){
  //   debugger

  // this.router.events.pipe(
  //   filter((event) => event instanceof NavigationEnd),
  //  // map(() => this.description),
  //   map(() => this.activatedRoute),
  //   map((route) => {
  //     while (route.firstChild) route = route.firstChild;
  //     return route;
  //   }),
  //   filter((route) => route.outlet === 'primary'),
  //   mergeMap((route) => route.data)
  //  )
  //  .subscribe((event) => {

  //    this._authService.updateDescription(event['title'] + event['description'])
  //  });
  // }

  GoToCourse(courseid: any, courseheader: any, coursename: any) {
    // debugger
    this.title.setTitle(coursename)

    //   this.metaTagService.addTag({name:'description', content:'coursepage'},
    //  );

    this.router.navigate(['/course/' + courseheader + '/' + courseid]);
    //this.description();
  }
  // goto() {
  //  // const template = '<span>generated on the fly</span>';
  //   //const tmpCmp = Component({template: template})(class {});

  //   const routes = [{path: '', component: tmpCmp}];
  //  const tmpModule = NgModule({
  //     imports: [RouterModule.forChild(routes)],
  //     declarations: [tmpCmp]})(class {});

  //   this.compiler.compileModuleAsync(tmpModule).then((module) => {

  //     const appRoutes = [...this.router.config];

  //     const route = {
  //       path: 'CourseHeader',
  //       loadChildren() { return module }
  //     };

  //     appRoutes.push(route);

  //     this.router.resetConfig(appRoutes);
  //     this.router.navigateByUrl('/CourseHeader');
  //   });
  // }

  Gettechnologies() {
    // var baseurl = this._authService.baseUrl;
    // if (baseurl == "https://localhost:44358/") {
    //   baseurl = "https://localhost:44358"
    // }
    // if (baseurl == "http://testugetitapi.fadelsoft.com/") {
    //   baseurl = "http://testugetitapi.fadelsoft.com"
    // }
    // debugger
    this._authService.Gettechnologies().subscribe((finalresult: any) => {


      var finalresult = JSON.parse(finalresult);
      if (finalresult.status == "200") {

        console.log(finalresult.result)
        this.techs = finalresult.result;
        // for(let i = 0;i<this.courses.length;i++){
        //   if (finalresult.result[i].imageURL != null) {
        //     this.courses[i].ImageURL = baseurl + finalresult.result.imageURL;
        //     // this.noimage=true;;

        //   }
        //   else {
        //     // this.ImageURL = baseurl + "/courseFiles/dummy identityproof.png";

        //   }

        // }
        setTimeout(() => {
        }, 1000);
      }
      else {
      }
      (error) => {

      }
    });
  }

  @HostListener('mouseover') clickme() {
    this.ellips = false;

  }
  @HostListener('mouseout') clickme1() {
    this.ellips = true;

  }

  Getcourses() {
    var baseurl = this._authService.baseUrl;

    debugger
    this._authService.GetCourses().subscribe((finalresult: any) => {
      //  debugger
      // var finalresult = JSON.parse(finalresult);
      // this.titleService.setTitle('details | ${this.item.courseName} | course') ;
      if (finalresult.status == "200") {
        // debugger
        console.log(finalresult.result)
        this.courses = finalresult.result;
        for (let i = 0; i < this.courses.length; i++) {
          // this.courses[i].price = this.courses[i].price ;
          // this.offerprice = finalresult.result.offerPrice
          if (this.courses[i].offerPrice != 0) {
            this.courses[i].price = this.courses[i].offerPrice;
          }
          else {
            this.courses[i].price = this.courses[i].price;

          }
          if (this.courses[i].iconUrl != "null") {
            this.courses[i].iconUrl = finalresult.result[i].iconUrl;
            // this.noimage=true;;
          }
          else {
            this.courses[i].iconUrl = "assets/img/courses/courses8.jpg";

          }

          if (this.courses[i].img != "null") {

            this.courses[i].img = finalresult.result[i].img;
            // this.noimage=true;;
          }
          else {
            this.courses[i].img = "assets/img/instructor/instructor6.jpg";


          }
        }
        // if (finalresult.result.imageURL != null) {
        //   this.ImageURL = baseurl + finalresult.result.imageURL;
        //   // this.noimage=true;;

        // }
        // else {
        //   // this.ImageURL = baseurl + "/courseFiles/dummy identityproof.png";

        // }
        setTimeout(() => {
        }, 1000);
      }
      else {
      }
      (error) => {

      }
    });

    this._authService.GetAllRatingsAndComments().subscribe((finalresult: any) => {
      // debugger
      this.ratings = finalresult.result
    })
  }


  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
