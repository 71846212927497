import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { style } from '@angular/animations';

@Component({
  selector: 'app-instructors-details-page',
  templateUrl: './instructors-details-page.component.html',
  styleUrls: ['./instructors-details-page.component.scss']
})
export class InstructorsDetailsPageComponent implements OnInit {

  ipaddress: string = '';
  latitude: string = '';
  longitude: string = '';
  currency: string = '';
  currencysymbol: string = '';
  isp: string = '';
  city: string = '';
  country: string = '';
  province: string = '';

  InstrcuctorName: any;
  InstructorImage: any;
  InstrcuctorShortDesc: any;
  InstrcuctorLongDesc: any;
  InstructorExperience: any;
  courses: any;
  ellips: any;
  price: any;
  offerprice: any;
  stars: number[] = [1, 2, 3, 4, 5];
  rup: boolean;
  Dol: boolean;
  PositionError:any;
  Position:any;
  constructor(private approute: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private router: Router,
    private title:Title) { }

  ngOnInit(): void {

    this._authService.getIpAddress().subscribe((res) => {
      this.ipaddress = res['ip'];
     this._authService.getGEOLocation(this.ipaddress).subscribe((res) => {
       console.log(res);
       this.latitude = res['latitude'];
       this.longitude = res['longitude'];
       this.city = res['city'];
       this.country = res['country_code2'];
       if( this.country='IN'){

         this.rup=true;
        }
        if( this.country !='IN'){
        
         this.Dol=true;
        }
       this.isp = res['isp'];
       this.province = res['state_prov'];
     });
   })
    this.ellips=true;
    var id = this.approute.snapshot.params['id'];
    this.GetInstructorDetails(id)
    this.GetCoursesByFaculty(id)
  }
  @HostListener('mouseover') clickme(){
    this.ellips=false;
  
    }
   @HostListener('mouseout') clickme1(){
    this.ellips=true;
  
    }
  
  GoToCourses(courseid:any,courseheader,coursename:any){
    this.title.setTitle(coursename)
    this.router.navigate(['/course/'+courseheader+'/'+courseid]);
    

 }

  GetInstructorDetails(id){
    debugger
    this._authService.GetFacultyById(id).subscribe((finalresult: any) => {
      if (finalresult.status == "200") {
        console.log('chapters', finalresult.result)
        
        this.InstrcuctorName = finalresult.result.displayName;
        this.InstructorImage = finalresult.result.imageUrl;
        this.InstrcuctorShortDesc = finalresult.result.shortDescription.replace(/<[^>]*>/g, '');
        this.InstrcuctorLongDesc = finalresult.result.longDescription.replace(/<[^>]*>/g, '');
        this.InstructorExperience=finalresult.result.experience;
      }
    })
  }

  GoTOPage(id){
    this._router.navigate(['/course/:dynamic/',id]);
        setTimeout(() => {
            window.location.reload();

        }, 1000);
  }

  GetCoursesByFaculty(id){
    this._authService.GetCoursesByFaculty(id).subscribe((finalresult: any) => {
    debugger

      if (finalresult.status == "200") {
        console.log('chapters', finalresult.result)
        
        this.courses=finalresult.result
        for (let i = 0; i < this.courses.length; i++) {
       // this.price = finalresult.result.price;
        // this.offerprice = finalresult.result[0].offerPrice

        // if (finalresult.result[0].offerPrice != 0 && finalresult.result[0].offerPrice != undefined && finalresult.result[0].offerPrice != null) {
        //   this.price = finalresult.result[0].offerPrice
        // }
        // else{
        //   this.price = finalresult.result[0].price
        // }

        if (this.courses[i].offerPrice != 0) {
          this.courses[i].price = this.courses[i].offerPrice;
        }
        else{
          this.courses[i].price = this.courses[i].price;

        }
        if (this.courses[i].iconUrl != "null") {
          this.courses[i].iconUrl = finalresult.result[i].iconUrl;            
          // this.noimage=true;;
        }
        else {
          this.courses[i].iconUrl = "assets/img/courses/courses8.jpg";          

        }
      }
        // if (this.courses.offerPrice != 0) {
        //   this.courses.price = this.courses.offerPrice;
        // }
        // else{
        //   this.courses.price = this.courses.price;

        // }
      }
    })
    
  }

}
