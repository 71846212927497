import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { AuthService } from '../../auth.service';
import { Title } from '@angular/platform-browser';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
export class tableData {
    technologyName: any;
    technologyId: any;
    model: courses[];
}
export class courses {
    courseId: any;
    courseName: any
    technologyName: any;
    technologyId: any;

}
@Component({
    selector: 'app-header-style-three',
    templateUrl: './header-style-three.component.html',
    styleUrls: ['./header-style-three.component.scss']
})
export class HeaderStyleThreeComponent implements OnInit {
    isIframe = false;
    loginDisplay = false;
    private readonly _destroying$ = new Subject<void>();

    id: string = "";
    loggedIn: boolean = false
    cartlength: string;
    technologies: any;
    phoneNo: any;
    email: any;
    ListData: Array<courses> = [];
    finaltabledata: Array<tableData> = [];
    courseplans: any;
    mycourseslink: string;
    companylogo: any;
    showPlans: boolean;
    companyName: any;

    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }

    constructor(private route: ActivatedRoute,
        private _authService: AuthService,
        private _router: Router,
        private title: Title,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService) {
        this.GetCompanydetails();
    }

    ngOnInit(): void {
        this.isIframe = window !== window.parent && !window.opener;
        var baseUrl = this._authService.baseUrl;
        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });
        this.mycourseslink = '/mycourses'

        if (window.location.hash != "" && sessionStorage.getItem("hashLogin") == null) {
            this.id = window.location.hash
            sessionStorage.setItem("hashLogin", window.location.hash)
            this.loggedIn = true
        } else {
            sessionStorage.removeItem("hashLogin")
            this.loggedIn = false;
        }

        this.GetTechnologies();
        this.GetAllCoursePlans();
        this.GetCartItemsLength();

    }
    GetCompanydetails() {
        this._authService.GetCompanydetails().subscribe((result: any) => {
            var result = JSON.parse(result);
            if (result.status == "200") {
                this.phoneNo = result.result[0].phoneNo;
                this.email = result.result[0].email;
                this.companylogo = result.result[0].companylogo;
                this.companyName = result.result[0].companyName;

            }
            else {
            }
            (error) => {

            }
        });
    }

    GoToCourses(companyName: any) {
        this.title.setTitle(companyName);
        this._router.navigate(['/']);
    }

    GetCartItemsLength() {
        var userid = localStorage.getItem('AzureUserId')
        this._authService.GetCartItems(userid).subscribe((finalresult: any) => {
            this.cartlength = finalresult.result.length
        });
    }

    setLoginDisplay() {
        this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
    }

    OnClick(id: any) {
        this._router.navigate(['/course/:dynamic/' + id]);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }


    login(userFlowRequest?: RedirectRequest | PopupRequest) {

        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            } else {
                this.authService.loginPopup(userFlowRequest)
                    .subscribe((response: AuthenticationResult) => {
                        this.authService.instance.setActiveAccount(response.account);
                    });
            }
        } else {
            if (this.msalGuardConfig.authRequest) {
                this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
            } else {
                this.authService.loginRedirect(userFlowRequest);
            }
        }
    }



    logout() {
        localStorage.setItem('AzureUserId', '');
        this.authService.logout();
    }

    GetTechnologies() {
        this._authService.GetCoursesForMenu().subscribe((finalresult: any) => {
            debugger;
            var values = JSON.parse(finalresult)
            this.technologies = values.result[0].course;

            var technologyids = this.technologies.map(item => item.technologyId)
                .filter((value, index, self) => self.indexOf(value) === index);

            for (let i = 0; i < technologyids.length; i++) {
                const tabledata = new tableData();

                for (let j = 0; j < this.technologies.length; j++) {

                    const excel = new courses();
                    if (this.technologies[j].technologyId == technologyids[i]) {
                        excel.courseId = this.technologies[j].courseId;

                        excel.courseName = this.technologies[j].courseName;
                        excel.technologyId = this.technologies[j].technologyId;
                        excel.technologyName = this.technologies[j].technologyName;
                        this.ListData.push(excel);
                    }

                }
                tabledata.technologyName = this.ListData[0].technologyName;
                tabledata.technologyId = this.ListData[0].technologyId;

                tabledata.model = this.ListData;

                this.finaltabledata.push(tabledata)
                this.ListData = [];
            }
        })
    }


    GetAllCoursePlans() {
        this._authService.GetCoursePlans().subscribe((finalresult: any) => {
            this.courseplans = finalresult.result
            if (finalresult.result.length > 0) {
                this.showPlans = true
            }
            else {
                this.showPlans = false
            }
        })
    }

    GoToPage(id: any) {
        this._router.navigate(['/courseplan/', id]);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    // ngOnDestroy(): void {
    //     this._destroying$.next(undefined);
    //     this._destroying$.complete();
    // }
    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    classApplied2 = false;
    toggleClass2() {
        this.classApplied2 = !this.classApplied2;
    }

    classApplied3 = false;
    toggleClass3() {
        this.classApplied3 = !this.classApplied3;
    }

}