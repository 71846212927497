// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  clientId: '{YOUR_CLIENT_ID}',
  authority: 'https://login.microsoftonline.com/{YOUR_TENANT_ID}/',
  //redirectUrl: 'http://localhost:4200',

  redirectUrl: 'https://ugetitstudent.fadelsoft.com/',
  AppApiURL: 'https://ugetitapi.fadelsoft.com/',



  //AppApiURL: 'https://localhost:44328/',
  courdsboardurl: 'https://ugetitcoursedashboard.fadelsoft.com/#/app/',
  // testApiurl: 'https://ugetithome.mbascloud.com/',
  // testcoursedsboardurl: 'https://ugetitdashboard.mbascloud.com/#/app/'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
