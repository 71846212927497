<section class="courses-area pt-70 pb-70">
    <div class="container">
        <div class="section-title text-start">
            <!-- <span class="sub-title">Discover Courses</span> -->
            <h2>Our Popular Courses</h2>
            <!-- <h1>
                Please open it in full screen mode <br>
                your current location is:- <br>
                lat:-{{Lat}}<br>
                lng-:{{Lng}}
              </h1>
              <h4>IP Address Info</h4>
<p>latitude : {{ latitude }}</p>
<p>longitude : {{ longitude }}</p>
<p>city : {{ city }}</p>
<p>country : {{ country }}</p>
<p>province : {{ province }}</p> -->

            <a routerLink="/viewAllcourses" class="default-btn"><i class='bx bx-show-alt icon-arrow before'></i><span class="label">All Courses</span><i class="bx bx-show-alt icon-arrow after"></i></a>
        </div>

        <div class="row">
            <!-- <div class="col-lg-3 col-md-3" *ngFor="let item of courses" (click)="GoToCourse(item.courseId,item.courseHeader,item.courseName)">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a class="d-block"><img src={{item.iconUrl}} alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}"  class="d-block">{{item.technologyName}}</a>
                        </div>
                    </div>
                    <div class="courses-content" >

                        <div class="course-author d-flex align-items-center">
                            <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image">
                            <span>{{item.facultyName}}</span>
                        </div>
                        <h3 ><a  class="d-inline-block">{{item.courseName}}</a></h3>

                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                    <li [ngClass]="{'selected': (star <= item.average)}">
                                        <i class="bx bxs-star"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="rating-total">
                                {{item.average}} ({{item.noOfRatings}} rating)
                            </div>
                        </div>
                        <!-- <div class="course-author d-flex align-items-center">
                            <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image"> 
                            <span>{{item.facultyName}}</span>
                        </div> 
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> {{item.buyCount}} students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> {{item.count}} lessons
                            </li>
                            <li class="courses-price">
                                ₹{{item.price}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->


            <!-- <div class="col-lg-3 col-md-3" *ngFor="let item of courses" (click)="GoToCourse(item.courseId,item.courseHeader,item.courseName)">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a class="d-block"><img src={{item.iconUrl}} alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">{{item.technologyName}}</a>
                        </div>
                    </div>

                 
                    <div class="card-body" >
                        <div class="courses-content">

                            <div class="course-author d-flex align-items-center">
                                <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image">
                                <span>{{item.facultyName}}</span>
                            </div>

                                 <h3 ><a id="card"   routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">
                                 
                                    
                                     <span (click)="clickme()" (click)="clickme1()"> {{item.courseName }}</span>     
                                </a></h3>
                            
                            <!-- <div class="course-author d-flex align-items-center">
                                <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image"> 
                                <span>{{item.facultyName}}</span>
                            </div> 
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.average)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-total">
                                    {{item.average}} ({{item.noOfRatings}} rating)
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="courses-box-footer">

                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> {{item.buyCount}} students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> {{item.count}} lessons
                            </li>
                            <li class="courses-price">
                                ₹{{item.price}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/" class="d-block">Development</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Anderson</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 5 lessons
                            </li>
                            <li class="courses-price">
                                $150
                            </li>
                        </ul>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="row row-cols-1 row-cols-md-3 g-4">

            <div class=" col-lg-3 col-md-3 " *ngFor="let item of courses" (click)="GoToCourse(item.courseId,item.courseHeader,item.courseName)">
                
                    <div class="card h-100 ">
                        <div class="courses-image ">
                            <a class="d-block "><img src={{item.iconUrl}} alt="image "></a>
                            <div class="courses-tag ">
                                <a routerLink="/" class="d-block ">{{item.technologyName}}</a>
                            </div>
                        </div>
                        <div class="card-body ">
                            <div class="card-title">
                                <!-- <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image"> -->
                                <img src={{item.img}}  class="rounded-circle mr-2" alt="image">

                                <span>{{item.facultyName}}</span>
                            </div>
                            <!-- <h3><a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">{{item.courseName  }}</a></h3> -->
                            <ng-container class="title">
                                <span [title]="courseName.innerText" >
                              
                            <h3 ><a id="coursename" #courseName  routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">
                                
                                     {{item.courseName }}
                                    </a></h3>
                                </span>
                            </ng-container>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.average)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-total">
                                    {{item.average}} ({{item.noOfRatings}} rating)
                                </div>
                            </div>
                        </div>
                        
                        <div class="courses-box-footer">

                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i>{{item.startingNumber+item.buyCount}} students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> {{item.count}} lessons
                                </li>
                                <li class="courses-price">
                                  <span *ngIf="rup"> ₹</span> 
                                  <span *ngIf="Dol"> $</span> 
                                    
                                    
                                    {{item.price}}
                                </li>
                            </ul>
                        </div>
                    </div>
                
            </div>
           
        </div> 
        <!-- <ngx-tabset>
            <ngx-tab tabTitle="Business (02) ">
               
            </ngx-tab>
            <ngx-tab tabTitle="All (06) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses1.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Business</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user1.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses2.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Design</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user2.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses3.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Development</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user3.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses4.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Language</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user4.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses5.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Management</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user5.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses6.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user6.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
            <ngx-tab tabTitle="Business (02) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses1.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Business</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user1.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses3.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Development</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user3.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
            <ngx-tab tabTitle="Design (05) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses1.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Business</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user1.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses2.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Design</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user2.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses4.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Language</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user4.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses5.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Management</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user5.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses6.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user6.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
            <ngx-tab tabTitle="Development (04) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses2.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Design</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user2.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses3.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Development</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user3.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses4.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Language</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user4.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses6.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user6.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
            <ngx-tab tabTitle="Language (02) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses1.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Business</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user1.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Steven Smith</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Ednuv Professional IT Expert Certificate Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        Free
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses4.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Language</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user4.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
            <ngx-tab tabTitle="Management (03) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses2.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Design</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user2.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses3.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Development</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user3.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>James Anderson</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Crash Course for Photographer</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bx-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 5 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 5 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $150
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses5.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Management</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user5.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
            <ngx-tab tabTitle="Photography (04) ">
                <div class="row ">
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses2.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Design</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user2.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Sarah Taylor</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Certified Graphic Design with Free Project Course</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star-half'></i>
                                    </div>
                                    <div class="rating-total ">
                                        4.5 (2 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 15 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 10 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $250
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses4.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Language</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user4.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Liam King</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Web Design for Developing Technology with Joy</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        <span>$200</span>
                                        $195
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses5.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Management</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user5.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>Lina D'Souza</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Information About UI/UX Design Degree</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $178
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 ">
                        <div class="single-courses-box mb-30 ">
                            <div class="courses-image ">
                                <a routerLink="/single-courses " class="d-block "><img src="assets/img/courses/courses6.jpg " alt="image "></a>
                                <div class="courses-tag ">
                                    <a routerLink="/ " class="d-block ">Photography</a>
                                </div>
                            </div>
                            <div class="courses-content ">
                                <div class="course-author d-flex align-items-center ">
                                    <img src="assets/img/user6.jpg " class="rounded-circle mr-2 " alt="image ">
                                    <span>David Warner</span>
                                </div>
                                <h3><a routerLink="/single-courses " class="d-inline-block ">Photography Photo modify and Beautiful</a></h3>
                                <div class="courses-rating ">
                                    <div class="review-stars-rated ">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                    </div>
                                    <div class="rating-total ">
                                        5.0 (1 rating)
                                    </div>
                                </div>
                            </div>
                            <div class="courses-box-footer ">
                                <ul>
                                    <li class="students-number ">
                                        <i class='bx bx-user'></i> 10 students
                                    </li>
                                    <li class="courses-lesson ">
                                        <i class='bx bx-book-open'></i> 6 lessons
                                    </li>
                                    <li class="courses-price ">
                                        $500
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ngx-tab>
        </ngx-tabset> -->
    </div>
</section>