import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
//import { NotificationsService, NotificationType } from 'angular2-notifications';

@Component({
  selector: 'app-become-a-instructor',
  templateUrl: './become-a-instructor.component.html',
  styleUrls: ['./become-a-instructor.component.scss']
})
export class BecomeAInstructorComponent implements OnInit {
  active: boolean;
  FacultyForm: FormGroup;
  // alert: { type: FuseAlertType; message: string } = {
  //   type: 'success',
  //   message: ''
  // };
  showAlert: boolean = false;
  files: Array<any> = new Array<any>();
  files1: Array<any> = new Array<any>();
  fileToUpload: File = null;
  fileToUpload1: File = null;
  name: string;
  name1: string;
  imageSrc: any;
  resumeSrc: any;
  isIframe = false;
  loginDisplay = false;
  private readonly _destroying$ = new Subject<void>();

  id: string = "";
  loggedIn: boolean = false
  cartlength: string;
  technologies: any;
  phoneNo: any;
  email: any;

  courseplans: any;
  mycourseslink: string;
  companylogo: any;
  showPlans: boolean;
  companyName: any;
  instructorsubmitted = false;
  isSticky: boolean = false;
  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= 50) {
      this.isSticky = true;
    } else {
      this.isSticky = false;
    }
  }

  constructor(private route: ActivatedRoute,
    private _authService: AuthService,
    private _router: Router,
    private title: Title,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService, private _formBuilder: FormBuilder) {
    this.GetCompanydetails();
    this.FacultyForm = this._formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      mobilenumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      file: ['', [Validators.required]],
      file1: ['', [Validators.required]]
    });
  }

  get r() { return this.FacultyForm.controls; }
  ngOnInit(): void {

    this.isIframe = window !== window.parent && !window.opener;
    var baseUrl = this._authService.baseUrl;
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
      });
    this.mycourseslink = '/mycourses'

    if (window.location.hash != "" && sessionStorage.getItem("hashLogin") == null) {
      this.id = window.location.hash
      sessionStorage.setItem("hashLogin", window.location.hash)
      this.loggedIn = true
    } else {
      sessionStorage.removeItem("hashLogin")
      this.loggedIn = false;
    }



  }

  AddFaculty() {
    this.showAlert = false
    this.instructorsubmitted = true;
    if (this.FacultyForm.invalid) {
      alert("Please fill the required fields");
      return;
    }
    const faculty = this.FacultyForm.getRawValue();

    if (this.active == undefined) {
      this.active = true;
    }
    const formData: FormData = new FormData();
    formData.append("FirstName", faculty.firstName)
    formData.append("LastName", faculty.lastName)
    formData.append("Email", faculty.email)
    formData.append("ShortDescription", "")
    formData.append("LongDescription", "")
    formData.append("MobileNumber", faculty.mobilenumber)
    formData.append("CreatedBy", "1");
    formData.append("UserType", "1");


    if (this.files.length == 1) {
      formData.append("fileupload", this.fileToUpload, this.name);
    }
    if (this.files1.length == 1) {
      formData.append("fileupload1", this.fileToUpload1, this.name1);
    }
    this._authService.AddFaculty(formData).subscribe((result: any) => {
      //
      // var result = JSON.parse(result);
      if (result.status == "200") {
        //
        // Set the alert
        // this.alert = {
        //   type: 'success',
        //   message: result.message
        // };

        // Show the alert
        this.showAlert = true;
        alert("Registered Successfully");
        setTimeout(() => {
          this._router.navigate(['/']);
        }, 1000);
      }
      else {
        // Set the alert
        // this.alert = {
        //   type: 'error',
        //   message: result.message
        // };

        // Show the alert
        this.showAlert = true;
      }
      (error) => {

      }
    });
  }

  onSelectFile(files: FileList) {
    //
    if (files.length === 0)
      return;
    if (files.length > 0) {
      this.files = [];
      for (var i = 0; i < files.length; i++) {
        this.fileToUpload = files.item(i);
        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(this.fileToUpload);
        this.name = this.fileToUpload.name.split(' ').join('-').replace(/[()]/g, "")
        this.files.push({ data: this.fileToUpload, fileName: this.name });
        const reader = new FileReader();
        if (files && files[0]) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = e => this.imageSrc = reader.result;
          reader.readAsDataURL(file);
        }
      }
    }
  }

  onSelectFile1(files: FileList) {
    //
    if (files.length === 0)
      return;
    if (files.length > 0) {
      this.files1 = [];
      for (var i = 0; i < files.length; i++) {
        this.fileToUpload1 = files.item(i);
        const fileReader: FileReader = new FileReader();
        fileReader.readAsDataURL(this.fileToUpload1);
        this.name1 = this.fileToUpload1.name.split(' ').join('-').replace(/[()]/g, "")
        this.files1.push({ data: this.fileToUpload1, fileName: this.name1 });
        const reader = new FileReader();
        if (files && files[0]) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = e => this.resumeSrc = reader.result;
          reader.readAsDataURL(file);
        }
      }
    }
  }

  GetCompanydetails() {
    this._authService.GetCompanydetails().subscribe((result: any) => {
      var result = JSON.parse(result);
      if (result.status == "200") {
        this.phoneNo = result.result[0].phoneNo;
        this.email = result.result[0].email;
        this.companylogo = result.result[0].companylogo;
        this.companyName = result.result[0].companyName;

      }
      else {
      }
      (error) => {

      }
    });
  }




  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  OnClick(id: any) {
    this._router.navigate(['/course/:dynamic/' + id]);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }


  login(userFlowRequest?: RedirectRequest | PopupRequest) {

    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }



  logout() {
    localStorage.setItem('AzureUserId', '');
    this.authService.logout();
  }





  GoToPage(id: any) {
    this._router.navigate(['/courseplan/', id]);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  // ngOnDestroy(): void {
  //     this._destroying$.next(undefined);
  //     this._destroying$.complete();
  // }
  classApplied = false;
  toggleClass() {
    this.classApplied = !this.classApplied;
  }

  classApplied2 = false;
  toggleClass2() {
    this.classApplied2 = !this.classApplied2;
  }

  classApplied3 = false;
  toggleClass3() {
    this.classApplied3 = !this.classApplied3;
  }



}
