<div>
    <div class="container-fluid" style="background-color: black;padding:40px">
        <h1 style="color: white;">Receipt</h1>
    </div>
    <div class="container">
        <h4 class="mt-4 mb-4">Receipt For Cart-{{date|date:'mediumDate'}}</h4>

        <div class="row mt-4">
            <div class="col-md-6">

                <!-- <h2 class="mb-5">UgetIt</h2> -->
                <h3 class="mb-5">{{companyName}}</h3>
                <p>
                    Acutelearn Technologies, 4th Floor, Sai Enclave, Plot no 80 &81,Arunodaya colony, Madhapur, Hyderabad -500081
                </p>
                <!-- <a href="ugetitmain.fadelsoft.com">ugetitmain.fadelsoft.com</a> -->
                <p>{{email}}</p>
            </div>
            <div class="col-md-3">

            </div>
            <div class="col-md-3">
                <p>Date:{{date|date:'dd-MM-yyyy'}}</p>
                <p>Order#:{{InvoiceId}}</p>
            </div>
        </div>
        <div class="myDashboard-content mt-4">
            <div class="orders-table table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Ordered</th>
                            <!-- <th>Coupon Codes</th> -->
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Amount</th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of receiptdata">
                        <tr>
                            <td>{{item.courseName}}</td>
                            <td>{{item.invoiceDate|date:'dd-MM-yyyy'}}</td>
                            <!-- <td>{{item.paymentStatus}}</td> -->
                            <td>1</td>
                            <td>₹{{item.totalAmount}}</td>
                            <td>₹{{item.totalAmount}}</td>

                            <!-- <td><a routerLink="/single-courses" class="view-button">View</a></td> -->
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <!-- <td>{{item.paymentStatus}}</td> -->
                            <td>SubTotal</td>
                            <td>₹{{item.totalAmount}}</td>
                            <td></td>

                            <!-- <td><a routerLink="/single-courses" class="view-button">View</a></td> -->
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <!-- <td>{{item.paymentStatus}}</td> -->
                            <td>Tax</td>
                            <td>₹{{item.totalTax}}</td>
                            <td></td>

                            <!-- <td><a routerLink="/single-courses" class="view-button">View</a></td> -->
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <!-- <td>{{item.paymentStatus}}</td> -->
                            <td>Total Paid</td>
                            <td>₹{{item.totalAmount}}</td>
                            <td></td>

                            <!-- <td><a routerLink="/single-courses" class="view-button">View</a></td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>