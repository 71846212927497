<header class="">
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <ul class="top-header-contact-info">
                        <li>
                            <i class='bx bx-phone-call'></i>
                            <!-- <span>Contact support</span> -->
                            <a href="tel:502464674">{{phoneNo}}</a>
                        </li>
                        <!-- <li>
                            <i class='bx bx-map'></i>
                            <span>Our location</span>
                            <a href="#">New York, USA</a>
                        </li> -->
                        <li>
                            <i class='bx bx-envelope'></i>
                            <!-- <span>Contact email</span> -->
                            <a href="mailto:hello@ednuv.com">{{email}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="navbar-area " ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
        <div class="">
            <nav class="navbar navbar-expand-lg navbar-light  bg-light" [class.active]="classApplied">
                <a class="navbar-brand" (click)="GoToCourses(companyName)"><img style="width: 200px;" [src]="companylogo" alt="logo"></a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Portal</a></li>
                                <li class="nav-item"><a routerLink="/course-portal-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Course Portal Online</a></li>
                                <li class="nav-item"><a routerLink="/distant-learning" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Distant Learning</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Popular Home Demos <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/modern-teaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Modern Teaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-schooling" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Schooling</a></li>
                                        <li class="nav-item"><a routerLink="/learning-school-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Learning School Online</a></li>
                                        <li class="nav-item"><a routerLink="/remote-training-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Remote Training Online</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">New Home Demos <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/business-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Business Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/health-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Health Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/language-school" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Language School</a></li>
                                        <li class="nav-item"><a routerLink="/gym-coaching" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gym Coaching</a></li>
                                        <li class="nav-item"><a routerLink="/online-motivation-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Motivation Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-education-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Education Course</a></li>
                                        <li class="nav-item"><a routerLink="/online-training-course" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Online Training Course</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Pages <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a href="#" class="nav-link">About <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/about-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/about-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Style 2</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="#" class="nav-link">Instructor <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/instructor-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 1</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 2</a></li>
                                        <li class="nav-item"><a routerLink="/instructor-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Style 3</a></li>
                                        <li class="nav-item"><a routerLink="/single-instructor" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Instructor Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>
                                <li class="nav-item"><a href="#" class="nav-link">Events <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events</a></li>
                                        <li class="nav-item"><a routerLink="/single-events" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Events Details</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>
                                <li class="nav-item"><a routerLink="/feedback" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Feedback</a></li>
                                <li class="nav-item"><a routerLink="/partner" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Partner</a></li>
                                <li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>
                                <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
                                <li class="nav-item"><a routerLink="/faqs" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ's</a></li>
                                <li class="nav-item"><a routerLink="/error-404" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error Page</a></li>
                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a></li>

                        <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let item of finaltabledata"><a href="javascript:void(0)" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">{{item.technologyName}} <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">

                                        <li *ngFor="let value of item.model">
                                            <!-- <ul> -->
                                            <a style="cursor: pointer;" (click)="OnClick(value.courseId)">{{value.courseName}}</a>
                                            <!-- </ul> -->
                                        </li>

                                    </ul>
                                </li>


                                <!-- <li class="nav-item"><a routerLink="/courses-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 1 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 2 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 3 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/single-courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                <li class="nav-item"><a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li> -->
                            </ul>
                        </li>

                        <li class="nav-item" *ngIf="showPlans"><a href="javascript:void(0)" class="nav-link"> Course Plans <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngFor="let item of courseplans">
                                    <!-- <a style="cursor: pointer;" routerLink="/courses-2-columns-style-1/{{item.planId}}" >{{item.planName}}</a> -->
                                    <a style="cursor: pointer;" (click)="GoToPage(item.planId)">{{item.planName}}</a>
                                    <!-- <ul class="dropdown-menu">

                                        <li >
                                                <a style="cursor: pointer;" (click)="GoToPage(item.planId)">{{item.planName}}</a>
                                        </li>
                                        
                                    </ul> -->
                                </li>
                                <!-- <li class="nav-item"><a routerLink="/courses-list" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses List</a></li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 1 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 2 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Courses Grid Style 3 <i class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/courses-2-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">2 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-3-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">3 Columns</a></li>
                                        <li class="nav-item"><a routerLink="/courses-4-columns-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">4 Columns Full Width</a></li>
                                    </ul>
                                </li>
                                <li class="nav-item"><a routerLink="/single-courses" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Courses Details</a></li>
                                <li class="nav-item"><a routerLink="/my-dashboard" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Dashboard</a></li> -->
                            </ul>
                        </li>
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Blog <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-style-1" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid</a></li>
                                <li class="nav-item"><a routerLink="/blog-style-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Right Sidebar</a></li>
                                <li class="nav-item"><a routerLink="/blog-style-3" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Grid Full Width</a></li>
                                <li class="nav-item"><a routerLink="/single-blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Shop <i class='bx bx-chevron-down'></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/shop-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Grid</a></li>
                                <li class="nav-item"><a routerLink="/shop-grid-fullwidth" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shop Grid FullWidth</a></li>
                                <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Products Details</a></li>
                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Cart</a></li>
                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Checkout</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a routerLink="/{{mycourseslink}}" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Courses</a></li> -->
                        <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Contact</a></li>
                    </ul>
                </div>

                <div class="others-option">
                    <div *ngIf="loginDisplay" class="dropdown language-switcher d-inline-block pr-4" [class.active]="classApplied2">
                        <button class="dropdown-toggle" type="button" (click)="toggleClass2()">
                            <img src="assets/img/default/profile.jpg" class="shadow" alt="image">
                            <!-- <span>Eng <i class='bx bx-chevron-down'></i></span> -->
                        </button>
                        <div class="dropdown-menu">
                            <a routerLink="/orders" class="dropdown-item  align-items-center">
                                <!-- <img src="assets/img/germany-flag.jpg" class="shadow-sm" alt="flag"> -->
                                <span>My Purchases</span>
                            </a>
                            <a routerLink="/mycourses" class="dropdown-item d-flex align-items-center">
                                <!-- <img src="assets/img/france-flag.jpg" class="shadow-sm" alt="flag"> -->
                                <span>My Courses</span>
                            </a>
                            <a href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_ChangePassword&client_id=38587c69-e970-487f-bf43-f32c71fd7706&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fugetitmain.fadelsoft.com%2F&scope=openid&response_type=id_token"
                                class="dropdown-item  align-items-center">
                                <!-- <img src="assets/img/spain-flag.jpg" class="shadow-sm" alt="flag"> -->
                                <span>Change Password</span>
                            </a>
                            <a class="dropdown-item  align-items-center" style="cursor: pointer;" (click)="logout()">
                                <!-- <img src="assets/img/italy-flag.jpg" class="shadow-sm" alt="flag"> -->
                                <span>Sign out</span>

                            </a>
                        </div>
                    </div>
                    <a routerLink="/cart" class="cart-wrapper-btn d-inline-block">
                        <i class='bx bx-cart-alt'></i>
                        <span>{{cartlength}}</span>
                    </a>
                    <div class="search-box d-inline-block">
                        <i class='bx bx-search' (click)="toggleClass3()"></i>
                    </div>
                    <div class="d-inline-block ">
                        <div class="top-header-btn">
                            <a *ngIf="!loginDisplay" (click)="login()" class="default-btn" style="cursor: pointer;"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Log In</span><i class="bx bx-log-in icon-arrow after"></i></a>
                            <!-- <a  *ngIf="loginDisplay" (click)="logout()" class="default-btn" style="cursor: pointer;">Sign Out</a> -->

                            <!-- <a *ngIf="!loggedIn" href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_studentPortalSignUpAndSignIn&client_id=ca12fdfb-881f-4dd7-8853-a4016b4006be&nonce=defaultNonce&redirect_uri=https%3A%2F%2Fugetitmain.fadelsoft.com%2F&scope=openid&response_type=id_token&prompt=login" class="default-btn"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Log In</span><i class="bx bx-log-in icon-arrow after"></i></a> -->
                            <!-- <a *ngIf="!loggedIn" href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_studentPortalSignUpAndSignIn&client_id=ca12fdfb-881f-4dd7-8853-a4016b4006be&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost:4100/blog-style-2%2F&scope=openid&response_type=id_token&prompt=login" class="default-btn"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Log In</span><i class="bx bx-log-in icon-arrow after"></i></a> -->
                            <!-- <a *ngIf="!loggedIn" href="https://fadelStudentsPortal.b2clogin.com/fadelStudentsPortal.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_studentPortalSignUpAndSignIn&client_id=ca12fdfb-881f-4dd7-8853-a4016b4006be&nonce=defaultNonce&redirect_uri=http%3A%2F%2Flocalhost:4100/#/blog-style-2%2F&scope=openid&response_type=id_token&prompt=login" class="default-btn"><i class='bx bx-log-in icon-arrow before'></i><span class="label">Log In</span><i class="bx bx-log-in icon-arrow after"></i></a> -->
                            <!-- <a *ngIf="loggedIn" (click)="logOut()" class="default-btn" style="cursor: pointer;">Sign Out</a> -->
                            <!-- <button *ngIf="!loggedIn" type="submit">Subscribe</button> -->
                        </div>
                    </div>

                </div>
            </nav>
        </div>
    </div>
</header>

<div class="search-overlay" [class.active]="classApplied3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-layer"></div>
            <div class="search-overlay-close" (click)="toggleClass3()">
                <span class="search-overlay-close-line"></span>
                <span class="search-overlay-close-line"></span>
            </div>

            <div class="search-overlay-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class='bx bx-search-alt'></i></button>
                </form>
            </div>

        </div>
    </div>
</div>