<app-header-style-two></app-header-style-two>

<div class="page-title-area item-bg3">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Products Details</li>
            </ul>
            <h2>Products Details</h2>
        </div>
    </div>
</div>

<div class="product-details-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="products-details-image">
                    <owl-carousel-o [options]="detailsImageSlides">
                        <ng-template carouselSlide>
                            <img src="{{ImageURL}}" alt="image">
                        </ng-template>
                        <!-- <ng-template carouselSlide>
                            <img src="assets/img/shop/shop2.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/img/shop/shop3.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/img/shop/shop4.jpg" alt="image">
                        </ng-template>
                        <ng-template carouselSlide>
                            <img src="assets/img/shop/shop5.jpg" alt="image">
                        </ng-template> -->
                    </owl-carousel-o>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="product-details-desc">
                    <h3>{{courseName}}</h3>
                    <div class="price" *ngIf="!offerprice">
                        <span class="new-price">Rs.{{price}}</span>
                        <!-- <span class="new-price">{{offerprice}}</span> -->
                        <!-- <span class="old-price">{{price}}</span> -->
                    </div>
                    <div class="price" *ngIf="offerprice">
                        <span class="new-price">{{offerprice}}</span>
                        <span class="old-price">{{price}}</span>
                    </div>
                    <div class="product-review">
                        <div class="rating">
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star'></i>
                            <i class='bx bxs-star-half'></i>
                        </div>
                        <a routerLink="/" class="rating-count">3 reviews</a>
                    </div>
                    <p>{{description}}.</p>
                    <div class="product-meta">
                        <span>SKU: <span class="sku">10</span></span>
                        <span>Availability: <span class="in-stock">In Stock</span></span>
                        <span>Category: <a routerLink="/">{{technologyName}}</a></span>
                        <span>Tag: <a routerLink="/">Book</a></span>
                    </div>
                    <div class="product-add-to-cart">
                        <!-- <div class="input-counter">
                            <input type="number" min="1" value="1">
                        </div> -->
                        <button type="submit" (click)="AddToCart(courseId)" class="default-btn"><i class='bx bx-plus icon-arrow before'></i><span class="label">Add to Cart</span><i class="bx bx-plus icon-arrow after"></i></button>
                    </div>
                    <div class="custom-payment-options">
                        <span>Guaranteed safe checkout:</span>
                        <div class="payment-methods">
                            <a href="#"><img src="assets/img/payment/1.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/2.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/3.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/4.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/5.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/6.svg" alt="image"></a>
                            <a href="#"><img src="assets/img/payment/7.svg" alt="image"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="products-details-tab">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Description">
                            <div class="products-details-tab-content">
                                <p [innerHTML]="fullDescription">.</p>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Additional information">
                            <div class="products-details-tab-content">
                                <ul class="additional-information">
                                    <li><span>Brand:</span> ThemeForest</li>
                                    <li><span>Color:</span> Brown</li>
                                    <li><span>Size:</span> Large, Medium</li>
                                    <li><span>Weight:</span> 27 kg</li>
                                    <li><span>Dimensions:</span> 16 x 22 x 123 cm</li>
                                </ul>
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Reviews">
                            <div class="products-details-tab-content">
                                <div class="product-review-form">
                                    <h3>Customer Reviews</h3>
                                    <div class="review-title">
                                        <div class="rating">
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bxs-star'></i>
                                            <i class='bx bx-star'></i>
                                        </div>
                                        <p>Based on 3 reviews</p>
                                        <a routerLink="/" class="default-btn"><i class='bx bx-plus icon-arrow before'></i><span class="label">Write a Review</span><i class="bx bx-plus icon-arrow after"></i></a>
                                    </div>
                                    <div class="review-comments">
                                        <div class="review-item">
                                            <div class="rating">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2019</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                        </div>
                                        <div class="review-item">
                                            <div class="rating">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2019</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                        </div>
                                        <div class="review-item">
                                            <div class="rating">
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bxs-star'></i>
                                                <i class='bx bx-star'></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2019</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
                                        </div>
                                    </div>
                                    <div class="review-form">
                                        <h3>Write a Review</h3>
                                        <form>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" id="name" name="name" placeholder="Enter your name" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="email" id="email" name="email" placeholder="Enter your email" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <input type="text" id="review-title" name="review-title" placeholder="Enter your review a title" class="form-control">
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea name="review-body" id="review-body" cols="30" rows="6" placeholder="Write your comments here" class="form-control"></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" class="default-btn"><i class='bx bx-paper-plane icon-arrow before'></i><span class="label">Submit Review</span><i class="bx bx-paper-plane icon-arrow after"></i></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>
                    </ngx-tabset>
                </div>
            </div>
        </div>
    </div>
    <div class="related-products">
        <div class="container">
            <div class="section-title text-start">
                <span class="sub-title">Our Shop</span>
                <h2>Related Products</h2>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box mb-30">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop/shop1.jpg" alt="image">
                                <img src="assets/img/shop/shop1-1.jpg" alt="image">
                            </a>
                            <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Novel Bunch</a></h3>
                            <div class="price">
                                <span class="new">$455.50</span>
                            </div>
                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box mb-30">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop/shop2.jpg" alt="image">
                                <img src="assets/img/shop/shop2-1.jpg" alt="image">
                            </a>
                            <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                            <div class="sale-btn">Sale!</div>
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Book Chicks</a></h3>
                            <div class="price">
                                <span class="old">$652.50</span>
                                <span class="new">$541.50</span>
                            </div>
                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box mb-30">
                        <div class="product-image">
                            <a routerLink="/single-products">
                                <img src="assets/img/shop/shop3.jpg" alt="image">
                                <img src="assets/img/shop/shop3-1.jpg" alt="image">
                            </a>
                            <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i class='bx bx-cart'></i></a>
                        </div>
                        <div class="product-content">
                            <h3><a routerLink="/single-products">Book Divas</a></h3>
                            <div class="price">
                                <span class="new">$140.50</span>
                            </div>
                            <div class="rating">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>