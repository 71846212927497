<!-- <app-header-style-three></app-header-style-three> -->
<app-header-style-two></app-header-style-two>

<div class="page-title-area page-title-style-two item-bg1">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a href="index.html">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses </h2>
        </div>
    </div>
</div>

<div class="courses-area ptb-100">
    <div class="container">
        <div class="courses-topbar">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-4">
                    <div class="topbar-result-count">
                        <p>Showing 1 – 6 of 54</p>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8">
                    <div class="topbar-ordering-and-search">
                        <div class="row align-items-center">
                            <div class="col-lg-3 col-md-5 offset-lg-4 offset-md-1 col-sm-6">
                                <div class="topbar-ordering">
                                    <select class="form-select">
                                        <option>Sort by popularity</option>
                                        <option>Sort by latest</option>
                                        <option>Default sorting</option>
                                        <option>Sort by rating</option>
                                        <option>Sort by new</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-5 col-md-6 col-sm-6">
                                <div class="topbar-search">
                                    <form>
                                        <label><i class="bx bx-search"></i></label>
                                        <input type="text" class="input-search" placeholder="Search here...">
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row row-cols-1 row-cols-md-3 g-4">

            <div class=" col-lg-3 col-md-3 " *ngFor="let item of courses" (click)="GoToCourse(item.courseId,item.courseHeader,item.courseName)">
                
                    <div class="card h-100 ">
                        <div class="courses-image ">
                            <a class="d-block "><img src={{item.iconUrl}} alt="image "></a>
                            <div class="courses-tag ">
                                <a routerLink="/" class="d-block ">{{item.technologyName}}</a>
                            </div>
                        </div>
                        <div class="card-body ">
                            <div class="card-title">
                                <!-- <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image"> -->
                                <img src={{item.img}}  class="rounded-circle mr-2" alt="image">

                                <span>{{item.facultyName}}</span>
                            </div>
                            <!-- <h3><a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">{{item.courseName  }}</a></h3> -->
                            <ng-container class="title">
                                <span [title]="courseName.innerText" >
                              
                            <h3 ><a id="coursename" #courseName  routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">
                                
                                     {{item.courseName }}
                                    </a></h3>
                                </span>
                            </ng-container>
                            <div class="courses-rating">
                                <div class="review-stars-rated">
                                    <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                        <li [ngClass]="{'selected': (star <= item.average)}">
                                            <i class="bx bxs-star"></i>
                                        </li>
                                    </ul>
                                </div>
                                <div class="rating-total">
                                    {{item.average}} ({{item.noOfRatings}} rating)
                                </div>
                            </div>
                        </div>
                        
                        <div class="courses-box-footer">

                            <ul>
                                <li class="students-number">
                                    <i class='bx bx-user'></i>{{item.startingNumber+item.buyCount}} students
                                </li>
                                <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> {{item.count}} lessons
                                </li>
                                <li class="courses-price">
                                  <!-- <span *ngIf="rup"> ₹</span> 
                                  <span *ngIf="Dol"> $</span>  -->
                                    
                                    
                                  ₹{{item.price}}
                                </li>
                            </ul>
                        </div>
                    </div>
                
            </div>
           
        </div> 














        <!-- <div class="row">
            <div class="col-lg-3 col-md-3  g-4" *ngFor="let item of courses" routerLink="/course/{{item.courseHeader}}/{{item.courseId}}" (click)="GoToCourse(item.courseId,item.courseHeader,item.courseName)">
                <div class="single-courses-box mb-30">
                    
                    <div class="courses-image">
                        <a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}" class="d-block"><img src={{item.iconUrl}} alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}" class="d-block">{{item.technologyName}}</a>
                        </div>
                    </div>
                    <div class="courses-content">

                        <h3><a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}" class="d-inline-block">{{item.courseName}}</a></h3>

                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                    <li [ngClass]="{'selected': (star <= item.average)}">
                                        <i class="bx bxs-star"></i>
                                    </li>
                                </ul>
                            </div>
                            <div class="rating-total">
                                {{item.average}} ({{item.noOfRatings}} rating)
                            </div>
                        </div>
                        <div class="course-author d-flex align-items-center">
                            <!-- <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image">
                            <img src={{item.img}} class="rounded-circle mr-2" alt="image">
                            <span>{{item.facultyName}}</span>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> {{item.count}} lessons
                            </li>
                            <li class="courses-price">
                                ₹{{item.price}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses2.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Design</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user2.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Certified Graphic Design with Free Project Course</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star-half'></i>
                            </div>
                            <div class="rating-total">
                                4.5 (2 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 15 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 10 lessons
                            </li>
                            <li class="courses-price">
                                $250
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses3.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Development</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user3.jpg" class="rounded-circle mr-2" alt="image">
                            <span>James Anderson</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Crash Course for Photographer</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bx-star'></i>
                            </div>
                            <div class="rating-total">
                                4.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 5 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 5 lessons
                            </li>
                            <li class="courses-price">
                                $150
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses4.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Language</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user4.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Liam King</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Web Design for Developing Technology with Joy</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                <span>$200</span>
                                $195
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses5.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Management</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user5.jpg" class="rounded-circle mr-2" alt="image">
                            <span>Lina D'Souza</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Information About UI/UX Design Degree</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                $178
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-courses-box mb-30">
                    <div class="courses-image">
                        <a routerLink="/single-courses" class="d-block"><img src="assets/img/courses/courses6.jpg" alt="image"></a>
                        <div class="courses-tag">
                            <a routerLink="/courses-3-columns-style-1" class="d-block">Photography</a>
                        </div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user6.jpg" class="rounded-circle mr-2" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3><a routerLink="/single-courses" class="d-inline-block">Photography Photo modify and Beautiful</a></h3>
                        <div class="courses-rating">
                            <div class="review-stars-rated">
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                                <i class='bx bxs-star'></i>
                            </div>
                            <div class="rating-total">
                                5.0 (1 rating)
                            </div>
                        </div>
                    </div>
                    <div class="courses-box-footer">
                        <ul>
                            <li class="students-number">
                                <i class='bx bx-user'></i> 10 students
                            </li>
                            <li class="courses-lesson">
                                <i class='bx bx-book-open'></i> 6 lessons
                            </li>
                            <li class="courses-price">
                                $500
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="pagination-area text-center">
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">2</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">3</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">4</a>
                    <a routerLink="/courses-3-columns-style-1" class="page-numbers">5</a>
                    <a routerLink="/courses-3-columns-style-1" class="next page-numbers"><i class='bx bx-chevron-right'></i></a>
                </div>
            </div> comment
        </div> -->
    </div>
</div>