import { Compiler, Component, HostListener, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/app/components/auth.service';

@Component({
  selector: 'app-homethree-courses',
  templateUrl: './homethree-courses.component.html',
  styleUrls: ['./homethree-courses.component.scss']
})
export class HomethreeCoursesComponent implements OnInit {
  ipaddress: string = '';
  latitude: string = '';
  longitude: string = '';
  currency: string = '';
  currencysymbol: string = '';
  isp: string = '';
  city: string = '';
  country: string = '';
  province: string = '';
  InstructorImage: any;
  ImageURL: any;
  ellips: any;
  courses: any;
  techs: any;
  price: any;
  InstrcuctorName: string;
  ratings: any;
  selectedValue: number;
  charset: boolean;
  public Lat;
  public Lng;
  stars: number[] = [1, 2, 3, 4, 5];
  rup: boolean;
  Dol: boolean;
  PositionError: any;
  Position: any;
  currentTab: string = '0';
  constructor(private approute: ActivatedRoute, private _authService: AuthService, private titleService: Title, private compiler: Compiler,
    private router: Router, private meta: Meta, private title: Title, private metaTagService: Meta, private route: ActivatedRoute
    ,
    private activatedRoute: ActivatedRoute,

    private metaService: Meta) { }


  ngOnInit(): void {

    this._authService.getIpAddress().subscribe((res) => {
      this.ipaddress = res['ip'];
      this._authService.getGEOLocation(this.ipaddress).subscribe((res) => {
        debugger;
        this.latitude = res['latitude'];
        this.longitude = res['longitude'];
        this.city = res['city'];
        this.country = res['country_code2'];
        if (this.country = 'IN') {
          this.rup = true;
        }
        if (this.country != 'IN') {
          this.Dol = true;
        }
        this.isp = res['isp'];
        this.province = res['state_prov'];
      });
    })

    this.ellips = true;

    this.Getcourses()
    this.Gettechnologies();
    this.getLocation();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
      .subscribe(() => {

        var rt = this.getChild(this.activatedRoute)

        rt.data.subscribe(data => {
          console.log(data);
          // this.titleService.setTitle(data.title)

          if (data.descrption) {
            this.metaService.updateTag({ name: 'description', content: data.descrption })
          } else {
            this.metaService.removeTag("name='description'")
          }

          if (data.robots) {
            this.metaService.updateTag({ name: 'robots', content: data.robots })
          } else {
            this.metaService.updateTag({ name: 'robots', content: "follow,index" })
          }

          if (data.ogUrl) {
            this.metaService.updateTag({ property: 'og:url', content: data.ogUrl })
          } else {
            this.metaService.updateTag({ property: 'og:url', content: this.router.url })
          }

          if (data.ogTitle) {
            this.metaService.updateTag({ property: 'og:title', content: data.ogTitle })
          } else {
            this.metaService.removeTag("property='og:title'")
          }

          if (data.ogDescription) {
            this.metaService.updateTag({ property: 'og:description', content: data.ogDescription })
          } else {
            this.metaService.removeTag("property='og:description'")
          }
        })

      })
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {

        this.Lat = position.coords.latitude;
        this.Lng = position.coords.longitude;
      });
    }
    else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  GoToCourse(courseid: any, courseheader: any, coursename: any) {
    this.title.setTitle(coursename)
    this.router.navigate(['/course/' + courseheader + '/' + courseid]);
  }

  Gettechnologies() {
    this._authService.Gettechnologies().subscribe((finalresult: any) => {
      var finalresult = JSON.parse(finalresult);
      if (finalresult.status == "200") {
        this.techs = finalresult.result;
        setTimeout(() => {
        }, 1000);
      }
      else {
      }
      (error) => {

      }
    });
  }

  @HostListener('mouseover') clickme() {
    this.ellips = false;

  }
  @HostListener('mouseout') clickme1() {
    this.ellips = true;

  }

  Getcourses() {
    this._authService.GetCourses().subscribe((finalresult: any) => {
      if (finalresult.status == "200") {
        debugger;
        this.courses = finalresult.result;
        for (let j = 0; j < this.courses.length; j++) {
          for (let i = 0; i < this.courses[j].course.length; i++) {
            if (this.courses[j].course[i].offerPrice != 0) {
              this.courses[j].course[i].price = this.courses[j].course[i].offerPrice;
            }
            else {
              this.courses[j].course[i].price = this.courses[j].course[i].price;
            }
            if (this.courses[j].course[i].iconUrl != "null") {
              this.courses[j].course[i].iconUrl = this.courses[j].course[i].iconUrl;
            }
            else {
              this.courses[j].course[i].iconUrl = "assets/img/courses/courses8.jpg";
            }

            if (this.courses[j].course[i].img != "null") {
              this.courses[j].course[i].img = this.courses[j].course[i].img;
            }
            else {
              this.courses[j].course[i].img = "assets/img/instructor/instructor6.jpg";
            }
          }
        }
      }
      else {
      }
      (error) => {

      }
    });

    this._authService.GetAllRatingsAndComments().subscribe((finalresult: any) => {
      this.ratings = finalresult.result
    })
  }


  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }
}
