<!-- <app-header-style-three></app-header-style-three> -->
<app-header-style-two></app-header-style-two>

<!-- <div class="page-title-area page-title-style-two item-bg4"> -->
<div class="page-title-area page-title-style-two item-bg4">
    <div class="container">
        <div class="page-title-content">
            <!-- <ul>
                <li><a href="index.html">Home</a></li>
                <li>Blog</li>
            </ul>
            <h2>Blog Right Sidebar</h2> -->
        </div>
    </div>
</div>
<div *ngIf="!hidemycourse" class="container cart-area ptb-100"  style="text-align: center;">

    <h1 style="font-weight: 600;">You Have To subscribe courses</h1>
    <div>    <a class="btn btn-outline-success" href="/">Go To Home</a></div>


</div>
<!-- <div class="blog-area ptb-100">
    <div class="container">
        <div class="row row-cols-1 row-cols-md-4 g-4">

            <div class="col-lg-3 col-md-6" *ngFor="let item of values"
                (click)="GoToCourse(item.courseId,item.courseHeader)">
                <div class="card h-100">
                    <div class="post-image">
                     
                        <a (click)="GoToPage(item.courseId)" class="d-block">
                            <img src={{item.imageURL}} alt="image">
                           

                        </a>
                        <div class="tag">
                            <a routerLink="/blog-style-2">{{item.technologyName}}</a>
                        </div>

                    </div>
                    <div class="card-body">
                        <div class="post-content">
                            <ul class="post-meta">
                                <li class="post-author">
                                    <img src={{item.iconUrl}} class="d-inline-block rounded-circle mr-2" alt="image">
                                    By: <a routerLink="/blog-style-2" class="d-inline-block">{{item.facultyName}}</a>
                                </li>
                                <li><a routerLink="/blog-style-2">{{item.effectiveFrom |date:"dd-MM-yyyy"}}</a>
                                </li>
                            </ul>
                            <h3><a routerLink="/blog-style-2" class="d-inline-block">{{item.courseName}}</a>
                            </h3>

                        </div>

                    </div>
                    <div class="card-footer">
                        <a (click)="OnClick(item.courseId)" class="read-more-btn">Read More <i
                                class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div  *ngIf="hidemycourse">
<section class="courses-area pt-70 pb-70">
    <div class="container">
<div class="row row-cols-1 row-cols-md-3 g-4">

    <div class=" col-lg-3 col-md-3 " *ngFor="let item of values"
    (click)="GoToCourse(item.courseId,item.courseHeader)">
        
            <div class="card h-100 ">
                <div class="courses-image ">
                    <a class="d-block " (click)="GoToPage(item.courseId)"><img src={{item.iconUrl}} alt="image "></a>
                    <div class="courses-tag ">
                        <a routerLink="/" class="d-block ">{{item.technologyName}}</a>
                    </div>
                </div>
                <div class="card-body ">
                    <div class="card-title">
                        <!-- <img src={{item.iconUrl}} class="rounded-circle mr-2" alt="image"> -->
                        <img src={{item.img}}  class="rounded-circle mr-2" alt="image">

                        <span>{{item.facultyName}}</span>
                    </div>
                    <!-- <h3><a routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">{{item.courseName  }}</a></h3> -->
                    <ng-container class="title">
                        <span [title]="courseName.innerText" >
                      
                    <h3 ><a id="coursename" #courseName  routerLink="/course/{{item.courseHeader}}/{{item.courseId}}">
                        
                             {{item.courseName }}
                            </a></h3>
                        </span>
                    </ng-container>
                    <div class="courses-rating">
                        <div class="review-stars-rated">
                            <ul class="list-inline rating-list" *ngFor="let star of stars" style="display:inline-block;">
                                <li [ngClass]="{'selected': (star <= item.average)}">
                                    <i class="bx bxs-star"></i>
                                </li>
                            </ul>
                        </div>
                        <div class="rating-total">
                            {{item.average}} ({{item.noOfRatings}} rating)
                        </div>
                    </div>
                </div>
                
                <div class="courses-box-footer">

                    <a (click)="OnClick(item.courseId)" class="read-more-btn">View Course <i
                        class='bx bx-right-arrow-alt'></i></a>

                </div>
            </div>
        
    </div>
   
</div> 
</div>
</section>
</div>
<!-- <div class="blog-area ptb-100">
    <div class="container">

        <div class="col-lg-12 col-md-12 g-4">
            <div class="row g-4">
                <div class="col-lg-3 col-md-6" *ngFor="let item of values"
                    (click)="GoToCourse(item.courseId,item.courseHeader)">


                    <div class="card single-blog-post mb-30 h-100">

                        <div class="card">
                            <div class="post-image">
                               
                                <a class="d-block" (click)="GoToPage(item.courseId)">
                                    <img src={{item.iconUrl}} alt="image">
                                 

                                </a>
                                <div class="tag">
                                    <a routerLink="/mycourses">{{item.technologyName}}</a>
                                </div>

                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src={{item.img}} class="d-inline-block rounded-circle mr-2"
                                            alt="image"> By: <a routerLink="/mycourses"
                                            class="d-inline-block">{{item.facultyName}}</a>
                                    </li>
                                    <li><a routerLink="/mycourses">{{item.effectiveFrom |date:"dd-MM-yyyy"}}</a>
                                    </li>
                                </ul>
                                <h3><a routerLink="/mycourses" class="d-inline-block">{{item.courseName}}</a>
                                </h3>

                            </div>


                        </div>
                        <div class="card-footer">
                            <a (click)="OnClick(item.courseId)" class="read-more-btn">Read More <i
                                    class='bx bx-right-arrow-alt'></i></a>
                        </div>
                    </div>



                </div>

            </div>
        </div>
    </div>
</div> -->

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <!-- <div class="col-lg-3 col-md-6" *ngFor="let item of values"
                        (click)="GoToCourse(item.courseId,item.courseHeader)">
                        <div class="single-blog-post mb-30 h-100">
                            <div class="post-image">
                                <!-- <a routerLink="/single-blog" class="d-block"> 
                                <a (click)="GoToPage(item.courseId)" class="d-block">
                                    <img src={{item.imageURL}} alt="image">
                                    <!-- <img src="assets/img/default/Image-1.jpg" alt="image">

                                </a>
                                <div class="tag">
                                    <a routerLink="/blog-style-2">{{item.technologyName}}</a>
                                </div>
                            </div>
                            <div class="post-content">
                                <ul class="post-meta">
                                    <li class="post-author">
                                        <img src={{item.iconUrl}} class="d-inline-block rounded-circle mr-2"
                                            alt="image"> By: <a routerLink="/blog-style-2"
                                            class="d-inline-block">{{item.facultyName}}</a>
                                    </li>
                                    <li><a routerLink="/blog-style-2">{{item.effectiveFrom |date:"dd-MM-yyyy"}}</a></li>
                                </ul>
                                <h3><a routerLink="/blog-style-2" class="d-inline-block">{{item.courseName}}</a></h3>
                                <a (click)="OnClick(item.courseId)" class="read-more-btn">Read More <i
                                        class='bx bx-right-arrow-alt'></i></a>
                            </div>

                        </div>
                    </div> -->


                    
                 
                </div>
            </div>
          
        </div>
    </div>
</div>