<div class="courses-categories-slides">
    <owl-carousel-o [options]="categoriesSlides">
        <ng-template carouselSlide *ngFor="let item of finaltabledata">
            <div class="single-categories-courses-item">
            <div class="single-courses-box mb-30">
                <div class="courses-image">
                    <a (click)="GoToPage(item.planId)" class="d-block"><img src="https://ugetit.blob.core.windows.net/courseimages/1997d693-1f4d-404b-a3fc-19a5fade0397-Image-2.jpg" alt="image"></a>
                    <div class="courses-tag">
                        <a class="d-block">{{item.count}}-Courses</a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="courses-content" (click)="GoToPage(item.planId)">

                        <!-- <h3><a routerLink="/courses-2-columns-style-1/{{item.planId}}" class="d-inline-block">{{item.planName}}</a></h3> -->
                        <ng-container class="title">
                            <span [title]="courseName.innerText" >
                          
                        <h3 ><a id="coursename" #courseName   routerLink="/courseplan/{{item.planId}}" class="d-inline-block">
                            
                            {{item.planName}}
                                </a></h3>
                            </span>
                        </ng-container>
                    </div>

                </div>
                <div class="courses-box-footer" (click)="GoToPage(item.planId)">
                    <ul>
                        <li class="students-number " (click)="AddToCart(item.planId)">
                            <!-- <i class='bx bx-plus icon-arrow before'></i>Add To Cart 
                            <i class="bx bx-cart-alt"></i>-->
                        </li>

                        <!-- <li class="courses-lesson">
                                    <i class='bx bx-book-open'></i> {{item.length}} lessons
                                </li> -->
                        <li class="courses-price">
                            ₹{{item.Price}}
                        </li>
                    </ul>
                </div>
            </div>
        </div> 
                
        <!-- <div class="row row-cols-1 row-cols-md-3 g-4">
                   
            <div class="col">
              <div class="card h-100">
                <img src="..." class="card-img-top" alt="...">
                <div class="card-body">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">This is a wider card with supporting text below as a natural lead-in to additional content. This card has even longer content than the first to show that equal height action.</p>
                </div>
                <div class="card-footer">
                  <small class="text-muted">Last updated 3 mins ago</small>
                </div>
              </div>
            </div>
          </div> -->
        </ng-template>
      
        <!-- <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-camera'></i>
                </div>
                <h3>Photography </h3>
                <span>21 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-layer'></i>
                </div>
                <h3>Graphics Design</h3>
                <span>58 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bxs-flag-checkered'></i>
                </div>
                <h3>Web Language</h3>
                <span>99 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-health'></i>
                </div>
                <h3>Health & Fitness</h3>
                <span>21 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template>
        <ng-template carouselSlide>
            <div class="single-categories-courses-box mb-30">
                <div class="icon">
                    <i class='bx bx-line-chart'></i>
                </div>
                <h3>Business Studies</h3>
                <span>49 Courses</span>
                <a routerLink="/" class="link-btn"></a>
            </div>
        </ng-template> -->
    </owl-carousel-o>
</div>